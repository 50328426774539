import { debounce } from '@/utils'

export default {
  data() {
    return {
      $_sidebarElm: null
    }
  },
  mounted() {
    this.__resizeHandler = debounce(() => {
      const { minWidth, $_sidebarElm, width } = this
      const clientWidth = $_sidebarElm.clientWidth
      const widthMax = 1600
      // 窗口宽度小于默认宽度时，将弹框看度设置为50%,可修改
      if (clientWidth < widthMax) {
        this.activeWidth = `${minWidth}px`
      } else {
        // 窗口宽度大于默认宽度1200时，将弹框设置为400宽度,可修改
        this.activeWidth = width
      }
      // console.log(this.activeWidth)
    }, 10)
    window.addEventListener('resize', this.__resizeHandler)

    this.$_sidebarElm = document.body
    this.$_sidebarElm && this.$_sidebarElm.addEventListener('transitionend', this.$_sidebarResizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.__resizeHandler)

    this.$_sidebarElm && this.$_sidebarElm.removeEventListener('transitionend', this.$_sidebarResizeHandler)
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.__resizeHandler()
      }
    }
  }
}
