<template>
  <div class="search-content">
    <el-select
      v-model="searchParams.Year"
      size="small"
      filterable
      class="search-select"
      placeholder="请选择服务年度"
      @change="getPlanItemEvent"
    >
      <el-option
        v-for="(item, index) in Years"
        :key="index"
        :value="item.Value"
        :label="item.Name"
      />
    </el-select>
    <el-select
      v-model="searchParams.ItemName"
      filterable
      size="small"
      class="search-select"
      placeholder="请选择工作项"
    >
      <el-option
        v-for="(item, index) in PlansLists"
        :key="index"
        :value="item.Value"
        :label="item.Name"
      />
    </el-select>
    <el-select
      v-if="hasStatus"
      v-model="searchParams.Status"
      filterable
      size="small"
      class="search-select"
      placeholder="请选择服务状态"
    >
      <el-option
        v-for="(item, index) in planStatus"
        :key="index"
        :value="item.Id"
        :label="item.Name"
      />
    </el-select>
  </div>
</template>

<script>
import { PLAN_STATUS } from '@/utils/constants'
import { getYears, getPlanItems } from '@/api/success'
export default {
  name: 'Search',
  components: {},
  props: {
    searchParams: { // 搜索参数
      type: Object,
      default: () => {
        return {
          Year: '',
          Status: -1,
          ItemName: ''
        }
      }
    },
    hasStatus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const deleteStatus = [0, 3]
    return {
      planStatus: PLAN_STATUS.filter(val => !deleteStatus.includes(val.Id)),
      Years: [],
      PlansLists: [{ Name: '全部', Value: '' }],
      searchInfo: {
        Year: '',
        Status: -1,
        ItemName: ''
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getUrlParams()
    this.getYearsEvent()
  },
  mounted() {
  },
  methods: {
    // 获取年度
    getYearsEvent() {
      getYears({ Page: 1, PageSize: 100 }).then(({ items }) => {
        const result = items || []
        result.forEach(val => {
          this.Years = [
            ...this.Years, {
               Name: `${val}年度`,
               Value: val
            }
          ]
        })
        if (!this.searchInfo.Year) this.searchInfo.Year = this.Years[0]?.Value
        this.getPlanItemEvent()
      })
    },

    // 获取工作项
    getPlanItemEvent() {
      this.PlansLists = this.$options.data().PlansLists
      getPlanItems(this.searchInfo).then((res) => {
        const result = res || []
        result.forEach(val => {
          this.PlansLists = [
            ...this.PlansLists,
            {
              Name: val,
              Value: val
            }
          ]
        })
        if (!this.searchInfo.ItemName) this.searchInfo.ItemName = this.PlansLists[0]?.Value
        this.$emit('changeParams', this.searchInfo)
      })
    },

    // 获取路由中的参数
    getUrlParams() {
      const { query } = this.$route
      if (Object.keys(query || {}).length > 0) {
        this.searchInfo.ItemName = query.ItemName
        this.searchInfo.Year = query.Year
      }
    }
  }
}
</script>

<style scoped lang='scss'>
  .search-content {
    height: 52px;
    box-shadow: inset 0 -1px 0 0 rgba(233,235,238,1);
    line-height: 52px;
    padding-left: 20px;
    .search-select {
      width: 212px;
      margin-right: 16px;
      &:last-of-type {
        margin-right: 0;
      }
      ::v-deep .el-input__inner {
        border-color: #dddddd;
      }
    }
  }
</style>
