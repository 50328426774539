<template>
  <el-dialog
    v-if="dialogViewVisible"
    v-model="dialogViewVisible"
    title="处理方案"
    width="526px"
    :close-on-click-modal="false"
    :show-close="false"
    class="common-dialog add-dialog"
    :before-close="handleClose"
  >
    <div class="table-expand">
      <div class="expand-content">
        <div class="expand-title">
          <span class="iconfont icon-wodeyiban" />
          <span class="top1Name">专家建议解决方案</span>
        </div>
        <!-- <div v-if="expandData " :class="['content-list ',isDesc?'border':'']"> -->
        <div v-if="expandData " :class="['content-list border']">
          <div v-for="(sub,key) in expandData" :key="key" class="list">
            <!-- <div v-if="key===0" class="list-one">
              <span class="subject-one">
                {{ sub }}
              </span>
            </div> -->
            <div class="list-one"> <span class="subject-one">{{ sub }}</span></div>
          </div>
        </div>
        <div v-else class="content-list no-data">请联系明源云客户成功团队处理</div>
      </div>
    <!-- <div v-if="!expandData && (dimension==='系统应用质量' || dimension==='故障率'||dimension==='CXO业务触达率')" class="content-list no-data">请联系明源云客户成功团队处理</div> -->

    </div>
    <div class="form-btn">
      <el-button
        type="primary"
        size="small"
        @click="handleClose"
      >确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
// import { reactive } from 'vue'
export default {
  props: {
    expandData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    // dimension: {
    //   type: String,
    //   default: function() {
    //     return ''
    //   }
    // },
    dialogViewVisible: {
      type: Boolean,
      default: function() {
        return false
      }
    }
  },
  emits: ['handleCloseView'],
  setup(props, context) {
    // const data = reactive({
    //   // ind: 0,
    //   isDesc: false
    // })
    function handleClose() {
      context.emit('handleCloseView', false)
    }
    return {
      // data,
      handleClose
    }
  },
  // data() {
  //   return {
  //     ind: 0,
  //     isDesc: false
  //   }
  // },

  mounted() {},
  methods: {
    // expandToggle() {
    //   this.isDesc = !this.isDesc
    // }
    // handleClose() {
    //   this.$emit('handleCloseView', false)
    // }
  }
}
</script>
<style scoped lang="scss">
.form-btn{
    text-align: right;
  }
.table-expand{
    background: #FEFEFE;
    margin: -20px 0 20px;
    .expand-title{
        display: none;
        font-size: 14px;
        line-height: 20px;
        border-bottom: 1px solid #EDEDED;
        padding: 12px 20px;
        svg,.iconfont{
         display: inline-block;
         margin-right: 10px;
         font-size: 14px;
         color: #0F6DFF;
        }
    }
    .content-list{
        padding: 18px 20px 8px;
        &.border{
        //   border-bottom: 1px solid #EDEDED;
        }
        &.no-data{
          // color: #999;
          font-size: 12px;
          position: relative;
          top: -4px;
        }
    }

   .close-collapse,.open-collapse{
        cursor: pointer;
        font-size: 12px;
        color: #0075FF;
        line-height: 16px;
        margin-left: 10px;
    }
    .list{
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      border:1px solid transparent;
    }
    .top2Name{
      display: inline-block;
      margin: 0 10px;
    }
    .content-desc{
      color: #999;
      padding: 12px 60px 0;
      font-size: 12px;
      line-height:20px;
      &:last-child{
        padding: 4px 60px 12px;
      }
      span{
        float: left;
        width:70px;
        &:last-child{
          width: calc(100% - 70px);
        }
      }
    }

    .desc-title{
      color:#333;
      &.affect{
        color: #33D994 ;
      }
    }
    .list-one{
      height: 20px;
    }
    .subject-one{
      display: inline-block;
      height: 20px;
      color: rgba(51, 51, 51, 0.45);
    }
}
</style>
