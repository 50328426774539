export function success(Layout) {
  return [
    {
      path: '/success',
      component: Layout,
      redirect: '/success/detail',
      name: 'success',
      meta: { title: '客户成功计划', icon: 'icon-biaodandingzhimoban', noFun: true },
      children: [
        {
          path: 'detail',
          name: 'detail',
          component: () => import('@/views/success/detail'),
          meta: {
            title: '计划详情',
            roles: ['admin'],
            noFun: true,
            noPadding: true
          }
        },
        {
          path: 'achievements',
          name: 'achievement',
          component: () => import('@/views/success/achievements'),
          meta: {
            title: '交付成果',
            roles: ['admin'],
            noFun: true,
            noPadding: true
          }
        }
      ]
    }
  ]
}
