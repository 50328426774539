import request from '../utils/request'

// 消息读取（点击显示已读状态）
export function readMessage(appCode) {
  return request({
    url: `/frontend/message/set-msg-read.svc?appCode=${appCode}`,
    method: 'PUT'
  })
}

// 是否有未读消息
export function getHasNotRead() {
  return request({
    url: `/frontend/message/get-all-unread.svc`,
    method: 'GET'
  })
}
// 获取导航信息
export function getHeaderPermission() {
  return request({
    url: `/workbench/get-app-navigate.svc`,
    method: 'GET'
  })
}
