<template>
  <div class="score user">
    <!-- clickDate没有诊断时间 -->
    <div v-if="clickDate && !flagCatch" class="score-section clearfix">
      <div class="score-section__lists fl">
        <div class="score-title">系统活跃度
          <el-tooltip content="通过对用户的系统使用频率，用于判断系统的应用广度和深度。" placement="right-start" effect="light">
            <span class="iconfont icon-tishi-wenhao" />
          </el-tooltip></div>
        <div class="score-title-sub">
          用户活跃情况
        </div>
        <div
          id="activeChart"
          ref="refActive"
          v-loading="chartLoading"
          class="flex-left"
          style="width: 100%;height:160px;float:left;"
          element-loading-text="数据加载中..."
        />
      </div>

      <div class="score-section__chart fl">
        <ManuSummary
          v-for="(item, ind) in manuSummaryData"
          :key="ind"
          :style="{'padding-left': item.padding, 'margin-bottom': item.margin}"
          class="score-section__lines fl"
          :class="{'hasBorder': item.hasBorder}"
          :active-num="item.activeNum"
          :title="item.title"
          :icon="item.icon"
          :icon-bg="item.iconBg"
          :color="item.color"
          :width="item.width"
          :auxi="ind===0"
          :industry-avg-value="ind===0?item.industryAvgValue:''"
          :content-slot="item.contentSlot"
        />
      </div>
    </div>
    <div v-if="flagCatch || !clickDate" class="score-section clearfix">
      <div class="score-section__lists fl">
        <div class="score-title">系统活跃度
          <el-tooltip content="通过对用户的系统使用频率，用于判断系统的应用广度和深度。" placement="right-start" effect="light">
            <span class="iconfont icon-tishi-wenhao" />
          </el-tooltip></div>
      </div>
      <Empty description="暂无数据" />
    </div>
  </div>
</template>
<script>
import resize from '@/views/components/charts/mixins/resize'
import ManuSummary from '@/views/components/scoreItems/index.vue'
import { activeChartOption, utilsDestroyedChart } from '@/views/components/charts/options'
import { utilsGoToApp } from '@/utils/gotoWeb.js'
import { markRaw } from 'vue'
import { Empty } from '@/components'
export default {
  components: { ManuSummary, Empty },
  mixins: [resize],
  props: {
    manuSummaryData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    xData: {
      type: [Number, String],
      default: () => {
        return null
      }
    },
    clickDate: { // 点击跳转日期
      type: String,
      default: ''
    },
    flagCatch: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    chartLoading: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    // xData() {
    //   debugger
    //   this.chartSet()
    // },
    manuSummaryData: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          if (this.$refs.refActive) this.chartSet()
        })
      }

    }
  },
  mounted() {
    if (this.clickDate && !flagCatch) {
      this.$nextTick(() => {
        if (this.$refs.refActive) this.chartSet()
      })
    }
  },
  beforeUnmount() {
    utilsDestroyedChart(this, 'chart')
  },
  methods: {
    chartSet() {
      const vm = this
      this.clearChart()
      const { xData } = this
      // this.chart = markRaw(this.$echarts.init(document.getElementById('activeChart')))
      this.chart = markRaw(this.$echarts.init(this.$refs.refActive))
      this.chart.setOption(activeChartOption({ xData }))
      this.chart.off('click')
      this.chart.getZr().off('click')
      this.chart.getZr().on('click', () => utilsGoToApp(vm, '系统活跃度', vm.clickDate))
    },
    clearChart() {
      this.chart = null
    }

  }
}
</script>
<style scoped lang="scss">
.score {
      background-color: #fff;
      margin-bottom: 8px;
      height: 186px;
      #activeChart ::v-deep div{
        cursor:pointer !important;
      }
      &-title {
        font-size: 14px;
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        .icon-tishi-wenhao{
          display: inline-block;
          margin-left: 4px;
          position: relative;
          font-size: 14px;
          top: 1px;
          cursor: pointer;
          font-weight: normal;
        }
      }
      &-title-sub{
        font-size: 14px;
        font-weight: 400;
        color:#333;
        margin-bottom: 10px;

      }
      .flex-left{
        // margin-left: 16px;
      }
      &-section {
        &__chart{
          width: calc(72%);
          position: relative;
          background:#fff;
          padding: 110px 10px 0 0;
          height: 186px;
          margin-top: 4px;
        }

        &__lists {
          width: 28%;
          background: #fff;
          padding: 15px 0 15px 24px;
          height: 186px;
        }
        &__time {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.38);
          line-height: 20px;
          margin-bottom: 10px;
          text-align: center;
        }
        &__lines {
          // margin-bottom: $margin + 15px;
          position: relative;
          text-align: center;
          &.hasBorder{
            &::after{
              content: '';
              display: inline-block;
              background-color: #EAEAEA;
              width: 1px;
              height: 112px;
              position: absolute;
              top: -23px;
              right: 18px;
            }
          }
        }
      }
  }
</style>
