/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * 防抖
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * 数组去重
 * @param arr 需要去重的数组
 * @param Value 需要监听的值
 * @returns {*}
 */
export function reduceArr(arr, Value) {
  const obj = {}
  return arr.reduce((cur, next) => {
    // obj[next[Value]] ? '' : obj[next[Value]] = true && cur.push(next)
    if (!obj[next[Value]]) { obj[next[Value]] = cur.push(next) }
    return cur
  }, [])
}

/**
 * 时间转换
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/([ymdhisa])+/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * 截取指定字符串
 * @param string 需要被截取的字符串
 */
export function subStringFun(string) {
  string = string.replaceAll(' ', '')
  if (string) {
    const str1 = string.match(/\(/)
    const str2 = string.match(/（/)
    const str3 = string.match(/\[/)

    const index1 = string.indexOf('(')
    const index2 = string.indexOf('（')
    const index3 = string.indexOf('[')

    const strArr1 = (string.split('\('))[0]
    const strArr2 = (string.split('\（'))[0]
    const strArr3 = (string.split('\['))[0]
    const arr = [index1, index2, index3]

    const indexArr = arr.filter(item => item !== -1)
    const minArr = indexArr.sort(function(a, b) {
      return a - b
    }) // 首次出现的split符号

    // ()[],[](),[][],()(),（）[],[]（）
    if (str1 && minArr[0] === index1) { //  匹配英文括号
      return strArr1.substring(strArr1.length - 2)
    }
    if (str2 && minArr[0] === index2) { // 匹配中文括号
      return strArr2.substring(strArr2.length - 2)
    }
    if (str3 && minArr[0] === index3) { // 匹配中文括号
      return strArr3.substring(strArr3.length - 2)
    }
    return string.substring(string.length - 2)
  } else {
    return null
  }
}

/**
 * 获取当前选择的时间
 * @param time
 * @returns {{start: Date, end: Date}}
 */
export function getChooseTime(time) {
  const end = new Date()
  const start = new Date()
  const hours = 3600 * 1000 * time
  start.setTime(start.getTime() - hours)
  return { start, end }
}

/**
 * 格式化地址栏的search  成json对象
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

/**
 * 判断是什么文件，显示什么图标
 * @param type
 * @returns {null}
 */
export function judgeFileTypeToShowIcon(type) {
  let fileType = null
  if (type) {
    switch (type?.toLowerCase()) {
      case 'png': // 常见的图片格式
      case 'jpg':
      case 'jpeg':
      case 'pic':
      case 'bmp':
      case 'gif':
      case 'tif':
      case 'svg':
        fileType = 'IMAGE'
        break
      case 'ppt': // 常见ppt后缀名
      case 'pptx':
      case 'pps':
      case 'ppsx':
        fileType = 'PPT'
        break
      case 'xls': // 常见excel后缀名
      case 'xlsx':
      case 'csv':
        fileType = 'EXCEL'
        break
      case 'doc': // 常见word后缀名
      case 'docx':
        fileType = 'WORD'
        break
      case 'pdf':
        fileType = 'PDF'
        break
      case 'zip':
      case 'rar':
      case 'arj':
      case 'gz':
        fileType = 'ZIP'
        break
      case 'link':
        fileType = 'FILE_LINK'
        break
      default:
        fileType = 'DEFAULT'
        break
    }
  }
  return fileType
}


export function downloadFileByBlob(filePart, fileName, hasFileType, isNotExcel, type, suffix) {
  if (!type) type = 'application/octet-stream'
  const blob = new Blob([filePart], { type })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.style = 'display:none'
  let activeFilename = isNotExcel ? `${fileName}-${ACTIVE_TIME(new Date().getTime())}${hasFileType ? `.${hasFileType}` : ''}` : `${fileName}-${ACTIVE_TIME(new Date().getTime())}.xlsx`
  if (typeof fileName === 'object') {
    let namePure = ''
    const name = decodeURIComponent(`${fileName['content-disposition'].split(';')[1].split('filename=')[1]}`)
    const hasSuffix = name?.split('.')?.length > 1
    const currentName = `${fileName['content-disposition'].split(';')[1].split('filename=')[1]}`
    if (hasSuffix && name) { // 返回的名字有后缀名则使用返回的后缀
      activeFilename = currentName
    }
    if (name && !hasSuffix) { // 没有后缀名 则默认加上传入的后缀名，如果没有传入，则默认加上xlsx
      namePure = decodeURIComponent((`${fileName['content-disposition'].split(';')[1].split('filename=')[1]}`.split('.'))[0])
      activeFilename = `${namePure || '列表'}.${suffix || 'xlsx'}`
    }
  }
  a.download = decodeURIComponent(activeFilename?.replace(/['|"](.*)['|"]$/, '$1'))
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url) // 释放掉blob对象
}
