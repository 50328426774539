<template>
  <div class="area-container">
    <div class="title">最近30天应用健康度趋势</div>
    <div
      v-if="clickDate && !flagCatch"
      v-loading="chartLoading"
      element-loading-text="数据加载中..."
    >
      <div class="avg">
        <span
          class="month"
        >本月<span class="inter">|</span>平均值<span class="value">{{
          monthlyAvgValue || '--'
        }}</span></span>
        <span
          class="year"
        >本年<span class="inter">|</span>平均值<span class="value">{{
          annualAvgValue || '--'
        }}</span></span>
      </div>
      <div id="areaChart" ref="refArea" style="width: 100%;height: 110px;" />
    </div>
    <Empty v-if="flagCatch" description="暂无数据" />
  </div>
</template>
<script>
import resize from '@/views/components/charts/mixins/resize'
import { Empty } from '@/components'
import {
  areaChartOption,
  utilsDestroyedChart
} from '@/views/components/charts/options'
import { markRaw } from 'vue'
export default {
  components: { Empty },
  mixins: [resize],
  props: {
    dataDailyScores: {
      type: Object,
      default: function() {
        return {}
      }
    },
    monthlyAvgValue: {
      type: [Number, String],
      default: null
    },
    annualAvgValue: {
      type: [Number, String],
      default: null
    },
    xDataArr: {
      type: Array,
      default: function() {
        return []
      }
    },
    yDataArr: {
      type: Array,
      default: function() {
        return []
      }
    },
    clickDate: {
      // 点击跳转日期
      type: String,
      default: ''
    },
    flagCatch: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    chartLoading: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      // id: 'areaChart',
      chart: null
      // utilsNumStr
    }
  },
  watch: {
    dataDailyScores() {
      this.chartSet()
    }
  },
  mounted() {
    if (this.clickDate && !flagCatch) {
      this.chartSet()
    }
  },
  beforeUnmount() {
    utilsDestroyedChart(this, 'chart')
  },
  methods: {
    // initChartEvent() {
    //   this.$nextTick(() => {
    //     this.chartSet()
    //   })
    //   //  window.addEventListener('beforeunload', this.clearChart)
    // },
    chartSet() {
      const { $echarts, xDataArr, yDataArr } = this
      // this.chart = markRaw($echarts.init(document.getElementById('areaChart')))
      this.chart = markRaw($echarts.init(this.$refs.refArea))
      this.chart.setOption(areaChartOption({ $echarts, xDataArr, yDataArr }))
    },
    clearChart() {
      this.chart = null
      // 手动触发 destroy 相关的生命周期
      // this.$destroy()
    }
  }
}
</script>
<style lang="scss" scoped>
.area-container {
  height: 172px;
  position: relative;
  .title {
    padding: 20px 16px 0;
  }
  ::v-deep .empty-comp {
    margin-top: 2px;
  }
  .avg {
    position: absolute;
    right: 24px;
    top: 20px;
    .month {
      background: #f5f6fa;
      border-radius: 4px;
      padding: 6px;
      color: #a6acbc;
      font-size: 12px;
    }
    .year {
      margin-left: 12px;
      color: #0e1f40;
      font-size: 12px;
    }
    .month,
    .year {
      .inter,
      .value {
        display: inline-block;
        margin: 0 4px;
      }
    }
  }
}
</style>
