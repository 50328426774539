<template>
  <div class="rate-container clearfix">
    <div class="title">系统故障率
      <el-tooltip content="通过系统咨询工单中的故障类占比，来评估系统的故障率。" placement="right-start" effect="light">
        <span class="iconfont icon-tishi-wenhao" />
      </el-tooltip>
    </div>
    <div v-if="clickDate && !flagCatch" v-loading="chartLoading" element-loading-text="数据加载中...">
      <div class="clearfix">
        <div class="bar-container">
          <div class="chart-title">系统故障工单</div>
          <div id="systemRatePie" ref="refRatePie" style="width: 100%;height: 210px;float:left;" />

        </div>
        <div class="bar-container">
          <div class="chart-title">故障工单分布</div>
          <div v-if="!rateCatchDetail" id="systemRateBar" ref="refRateBar" style="width: 100%;height: 210px;float:left;" />
          <Empty v-if="noData || rateCatchDetail" description="暂无故障工单数据" />
        </div>
      </div>
      <div class="analysis">
        <div class="analysis-title">分析建议</div>
        <div id="contentFault" class="content content-anylysis" />
      </div>
    </div>
    <Empty v-if="flagCatch" description="暂无数据" />
  </div>

</template>
<script>
import { Empty } from '@/components'
import { markRaw } from 'vue'
import { pieOption, barOption, utilsDestroyedChart } from '@/views/components/charts/options'
import { utilsGoToApp } from '@/utils/gotoWeb.js'
export default {
  components: { Empty },
  props: {
    dataChartFault: {
      type: Object,
      default: function() {
        return {
          summary: {
            value: null,
            value2: null
          },
          industryAvgValue: null,
          details: [],
          aiSuggestion: null
        }
      }
    },
    clickDate: { // 点击跳转日期
      type: String,
      default: ''
    },
    flagCatch: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    rateCatchDetail: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    chartLoading: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      noData: false,
      systemKeyPie: null,
      systemKeyBar: null
    }
  },
  watch: {
    dataChartFault() {
      this.chartSet1()
      this.chartSet2()
    }
  },
  mounted() {
    if (this.clickDate && !flagCatch) {
      this.chartSet1()
      this.chartSet2()
    }
  },
  beforeUnmount() {
    utilsDestroyedChart(this, 'systemKeyPie')
    utilsDestroyedChart(this, 'systemKeyBar')
  },
  methods: {
    chartSet1() {
      const vm = this
      const { aiSuggestion } = this.dataChartFault
      let { value, value2 } = this.dataChartFault.summary
      document.getElementById('contentFault').innerHTML = aiSuggestion
      // value = null
      // value2 = null
      if (!value)value = 0
      if (!value2)value2 = 0
      const seriesData = [value, value2]
      const dataTitle = ['故障类工单', '非故障类工单']
      // 1.饼图
      // this.systemKeyPie = markRaw(this.$echarts.init(document.getElementById('systemRatePie')))
      this.systemKeyPie = markRaw(this.$echarts.init(this.$refs.refRatePie))
      this.systemKeyPie.setOption(pieOption({ title: '系统故障工单', seriesData, dataTitle }))
      this.systemKeyPie.off('click')
      this.systemKeyPie.on('click', () => utilsGoToApp(vm, '系统故障率', vm.clickDate))
    },
    chartSet2() {
      // 2.柱状图
      const vm = this
      const { details } = this.dataChartFault
      const xData = []
      const yData = []
      const yData2 = []
      const dataTitleBar = ['已解决故障工单', '未解决故障工单']
      details.forEach(item => {
        const { title, value, value2 } = item
        xData.push(title)
        yData.push(value)
        yData2.push(value2)
      })
      // yData = [null, null]
      // yData2 = [null, null]
      let num = yData.length
      let num2 = yData2.length
      yData.forEach(y => {
        if (!y) num--
      })
      yData2.forEach(y2 => {
        if (!y2) num2--
      })
      if (num === 0 && num2 === 0) {
        this.noData = true
        utilsDestroyedChart(this, 'systemKeyBar')
      } else {
        this.noData = false
        // this.systemKeyBar = markRaw(this.$echarts.init(document.getElementById('systemRateBar')))
        this.systemKeyBar = markRaw(this.$echarts.init(this.$refs.refRateBar))
        this.systemKeyBar.setOption(barOption({ title: '故障工单分布', dataTitle: dataTitleBar, xData, yData, yData2 }))
        this.systemKeyBar.off('click')
        this.systemKeyBar.on('click', () => utilsGoToApp(vm, '系统故障率', vm.clickDate))
      }
    }
  }

}
</script>
