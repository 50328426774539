import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'// 1.路由
import store from './store' // 2.vuex
import ElementPlus from 'element-plus'// 3.element plus
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import '@/styles/index.scss'// 4.全局css
import '@/styles/health.scss'
import './icons'// 5.icon svg
import SvgIcon from './components/SvgIcon'

import moment from 'moment'// 6.公共js：moment
import * as echarts from 'echarts'// 7.公共js：echarts

const app = createApp(App)
import Cookies from 'js-cookie'
Cookies.remove('IsTf')
app.use(ElementPlus, {
  locale: zhCn
}).component('svg-icon', SvgIcon)
  .use(store).use(router).mount('#app')
// import {getCurrentInstance} from 'vue'
// getCurrentInstance().appContext.config.globalProperties.$moment
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$moment = moment
