
export function initValue(value) {
  let val = '--'
  if (value || value === 0) {
    val = value
  }
  return val
}
// 显示整数，小数，带单位
export function utilsNumStr(value, unit, isRate, num) {
  let val = initValue(value)
  if (val === '--') return val
  if (isRate) { val = accMul(val, 100) }// 精度

  val = formatDecimal(val, num)// 取小数2位
  if (unit) val = val + unit
  return val
}
// 取整
export function utilsAll(value, unit) {
  let val = initValue(value)
  if (val === '--') return val
  val = accMul(val, 1) // 精度
  val = formatDecimal(val, 0)// 取整
  if (unit) val = val + unit
  return val
}
// 图中数值做比较，不带单位，hover增加单位
export function utilsNum(value, isRate, num) {
  let val = initValue(value)
  if (val === '--') return val
  if (isRate) { val = accMul(value, 100) } // 精度
  return formatDecimalNum(val, num)// 保留2位
}

/**
 * 增加千分号
 */
export function utilsToThousands(num) {
  let result = ''
  if (num || num === 0) {
    let counter = 0
    num = (num || 0).toString()
    for (let i = num.length - 1; i >= 0; i--) {
      counter++
      result = num.charAt(i) + result
      if (!(counter % 3) && i !== 0) { result = ',' + result }
    }
  } else { result = '--' }
  return result
}

// 系统活跃度
export function initRate(value, num, plus, unit, label) {
  if (label) console.log('a-----------', value, num, plus, unit)
  // console.log(value, num, plus, unit)
  if (!num && num !== 0) num = 2
  if (!plus) plus = 100
  if (value || value === 0) {
    const v = accMul(value, plus)
    if (label) console.log('v--------', formatDecimalNum(v, num) + unit || '')
    return formatDecimalNum(v, num) + unit || ''
  }
  return '--'
}

// 乘法：小数丢失精度问题修改
export function accMul(arg1, arg2) {
  let m = 0; const s1 = arg1.toString(); const s2 = arg2.toString()
  try { m += s1.split('.').length > 1 ? s1.split('.')[1].length : 0 } catch (e) { console.log(e) }
  try { m += s2.split('.').length > 1 ? s2.split('.')[1].length : 0 } catch (e) { console.log(e) }
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}

//   // 保留小数位数，不四舍五入
export function formatDecimal(num, decimal) {
  num = num.toString()
  const index = num.indexOf('.')
  if (index !== -1) {
    if (decimal === 0) {
      num = num.substring(0, decimal + index)
    } else {
      num = num.substring(0, decimal + index + 1)
    }
  } else {
    num = num.substring(0)
  }
  return parseFloat(Number(num).toFixed(decimal))
}
// 保留小数位数，四舍五入
export function formatDecimalNum(num, decimal) {
  return parseFloat(num.toFixed(decimal))
}
