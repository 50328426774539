<template>
  <div class="quality-container clearfix">
    <div class="title">
      系统应用质量
      <el-tooltip
        content="针对核心的业务场景制定了巡检规则，来检查关键业务功能是否被用户使用，用于评估系统的应用深度。"
        placement="right-start"
        effect="light"
      >
        <span class="iconfont icon-tishi-wenhao" />
      </el-tooltip>
    </div>
    <div
      v-if="clickDate && !flagCatch"
      v-loading="chartLoading"
      element-loading-text="数据加载中..."
    >
      <div class="clearfix">
        <div class="bar-container">
          <div class="chart-title">系统应用质量</div>
          <div
            id="systemQualityPie"
            ref="refQualityPie"
            style="width: 100%;height: 210px;float:left;"
          />
        </div>
        <div class="bar-container">
          <div class="chart-title">应用质量产品得分率分布</div>
          <div
            v-if="!qualityDistributeCatch"
            id="systemQualityBar"
            ref="refQualityBar"
            style="width: 100%;height: 210px;float:left;"
          />
          <Empty
            v-if="!flagCatch && qualityDistributeCatch"
            description="暂无数据"
          />
        </div>
      </div>
      <div class="analysis">
        <div class="analysis-title">分析建议</div>
        <div id="contentQuality" class="content content-anylysis" />
      </div>
    </div>
    <Empty v-if="flagCatch" description="暂无数据" />
  </div>
</template>
<script>
import { Empty } from '@/components'
import resize from '@/views/components/charts/mixins/resize'
import { markRaw } from 'vue'
import {
  pieOption,
  barComposition,
  utilsDestroyedChart
} from '@/views/components/charts/options'
import { utilsGoToApp } from '@/utils/gotoWeb.js'
export default {
  components: { Empty },
  mixins: [resize],
  props: {
    qualityDistributeCatch: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    dataChartQuality: {
      type: Object,
      default: function() {
        return {}
      }
    },
    clickDate: {
      // 点击跳转日期
      type: String,
      default: ''
    },
    flagCatch: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    chartLoading: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      systemQualityPie: null,
      systemQualityBar: null
    }
  },
  watch: {
    dataChartQuality() {
      this.chartSet1()
      this.chartSet2()
    }
  },
  mounted() {
    if (this.clickDate && !this.flagCatch) {
      this.chartSet1()
      this.chartSet2()
    }
  },
  beforeUnmount() {
    utilsDestroyedChart(this, 'systemQualityPie')
    utilsDestroyedChart(this, 'systemQualityBar')
  },
  methods: {
    chartSet1() {
      const vm = this
      const { aiSuggestion } = this.dataChartQuality
      const { value, value2 } = this.dataChartQuality.summary
      document.getElementById('contentQuality').innerHTML = aiSuggestion
      const seriesData = [value, value2]
      const dataTitle = ['通过项数', '异常项数']
      // 1.饼图
      // this.systemQualityPie = markRaw(this.$echarts.init(document.getElementById('systemQualityPie')))
      this.systemQualityPie = markRaw(
        this.$echarts.init(this.$refs.refQualityPie)
      )
      this.systemQualityPie.setOption(
        pieOption({ title: '系统应用质量', seriesData, dataTitle })
      )
      this.systemQualityPie.off('click')
      this.systemQualityPie.on('click', () => utilsGoToApp(this, '应用质量', vm.clickDate))
    },
    chartSet2() {
      const vm = this
      // 2.柱状图
      // this.systemQualityBar = markRaw(this.$echarts.init(document.getElementById('systemQualityBar')))
      this.systemQualityBar = markRaw(
        this.$echarts.init(this.$refs.refQualityBar)
      )
      this.systemQualityBar.setOption(
        barComposition(this.dataChartQuality.details)
      )
      this.systemQualityBar.off('click')
      this.systemQualityBar.on('click', () => utilsGoToApp(this, '应用质量', vm.clickDate))
    }
  }
}
</script>
