
import { initRate, utilsNum } from '@/utils/value.js'
// 饼图
export const pieOption = ({ title, seriesData, dataTitle }) => {
  return {
    color: ['#69B4F8', '#84E0BD'],
    title: {
      text: '',
      // subtext: 'Fake Data',
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'horizontal',
      x: 'center',
      y: 'bottom',
      icon: 'circle',
      itemWidth: 5, // 设置宽度
      itemHeight: 5, // 设置高度
      textStyle: {
        color: '#666'
      }
    },
    series: [
      {
        name: title,
        type: 'pie',
        radius: '62%',
        center: ['50%', '46%'],
        data: [
          { value: seriesData[0], name: dataTitle[0] },
          { value: seriesData[1], name: dataTitle[1] }
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }
}

export const barOption = ({ title, dataTitle, xData, yData, yData2 }) => {
  const opt = {
    color: ['#69B4F8', '#84E0BD'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
      }
    },
    legend: {
      orient: 'horizontal',
      x: 'center',
      y: 'bottom',
      icon: 'circle',
      itemWidth: 5, // 设置宽度
      itemHeight: 5, // 设置高度
      textStyle: {
        color: '#666'
      }
    },
    grid: {
      left: 40, // 组件离容器左侧的距离,百分比字符串或整型数字
      top: 40, // 组件离容器上侧的距离，百分比字符串或整型数字
      right: 60, // 组件离容器右侧的距离,百分比字符串或整型数字
      bottom: 50, // 组件离容器下侧的距离,百分比字符串或整型数字
      containLabel: false // grid 区域是否包含坐标轴的刻度标签，
    },
    yAxis: {
      minInterval: 1,
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: 'rgba(0,0,0,0.09)',
          type: 'dashed'
        }
      },
      axisLabel: {
        textStyle: {
          color: 'rgba(0,0,0,0.65)'
        }
      }
    },
    xAxis: {
      type: 'category',
      data: xData,
      axisLabel: {
        textStyle: {
          color: 'rgba(0,0,0,0.65)'
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(0,0,0,0.25)'
        }
      }
    },
    series: [
      {
        name: dataTitle[0],
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
          color: '#333'
        },
        emphasis: {
          focus: 'series'
        },
        data: yData,
        barMaxWidth: 32
      },
      {
        name: dataTitle[1],
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
          color: '#333'
        },
        emphasis: {
          focus: 'series'
        },
        data: yData2,
        barMaxWidth: 32
      }

    ]
  }
  if (calMax(yData) === 0 && calMax(yData2) === 0) {
    opt.yAxis.min = 0
    opt.yAxis.max = 1
  }
  return opt
}

export const barComposition = (details) => {
  // const barData = {
  //   xData: ['花溪区', '南明区', '观山湖区', '钟山区', '六枝特区', '红花岗区', '汇川区', '遵义县', '西秀区', '平坝县', '玉屏县'],
  //   yData: [0.1, 0.15, 0.18, 0.25, 0.23, 0.32, 0.33, 0.36, 0.57, 0.72, 0.45]
  // }
  const colors = ['#69B4F8', '#84E0BD', '#4150d8', '#28bf7e', '#ed7c2f', '#f2a93b', '#f9cf36', '#4a5bdc', '#4cd698', '#f4914e', '#fcb75b', '#ffe180', '#b6c2ff', '#96edc1']
  const colorsBg = ['#f6f6f6', '#eaeaea', '#cfcfcf', '#bfbfbf', '#a9a9a9', '#f6f6f6', '#eaeaea', '#cfcfcf', '#bfbfbf', '#a9a9a9']

  const xData = []
  const yData = []
  // details = {
  //   'V2.1': [ // 产品
  //     {
  //       'title': '销售系统', // 子系统
  //       'value': 0.96, // 得分率
  //       'value2': null
  //     },
  //     {
  //       'title': '成本系统',
  //       'value': 0.76,
  //       'value2': null
  //     }
  //   ],
  //   'V2.2': [ // 产品
  //     {
  //       'title': '销售系统2', // 子系统
  //       'value': 1.0, // 得分率
  //       'value2': null
  //     },
  //     {
  //       'title': '成本系统2',
  //       'value': 0.5,
  //       'value2': null
  //     },
  //     {
  //       'title': '成本系统3',
  //       'value': 0.5,
  //       'value2': null
  //     }
  //   ]
  // }
  const main = Object.keys(details) // 产品名字数组

  let total = 0
  main.forEach(m => {
    total += details[m].length// details[m]每个产品子系统数组
    details[m].forEach(s => {
      xData.push(s.title)
      // yData.push(s.value)
      yData.push(utilsNum(s.value, true, 2))
    })
  })
  const seriesArr = []

  main.forEach((m, i) => {
    const ser = {
      data: [{
        name: m,
        value: 1
      }],
      label: {
        show: true,
        position: 'inside',
        formatter: '{b}',
        offset: [0, 10],
        color: '#333'
        // textStyle: {
        //   color: '#777'
        // }
      },
      type: 'bar',
      // barMaxWidth: 32,
      barGap: 0,
      barWidth: initRate(details[m].length / total, 4, 100, '%'),
      itemStyle: {
        normal: {
          color: colorsBg[i] || '#eee'
        }
      },
      xAxisIndex: 1,
      yAxisIndex: 1
    }
    seriesArr.push(ser)
  })

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function(params) {
        return params[0].name + '<br />' +
         '<span style="display: inline-block;width: 10px;height: 10px;border-radius: 10px;margin-right: 10px;background:' +
         params[0].color + '"></span>' +
        '<b>' +
          params[0].value + '%' + '</b>'
      }
    },
    grid: [
      {
        top: 40,
        bottom: 41
      },
      {
        height: 40,
        bottom: 0
      }
    ],
    xAxis: [{
      type: 'category',
      data: xData,
      gridIndex: 0,
      axisLabel: {
        textStyle: {
          color: 'rgba(0,0,0,0.65)'
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(0,0,0,0.25)'
        }
      },
      axisTick: {
        lineStyle: {
          color: '#e7e7e7'
        }
      },
      zlevel: 2
    }, {
      type: 'category',
      gridIndex: 1,
      axisLine: {
        show: false
      },
      zlevel: 1
    }],
    yAxis: [{
      type: 'value',
      gridIndex: 0,
      minInterval: 1,
      axisLabel: {
        formatter: '{value}%',
        textStyle: {
          color: 'rgba(0,0,0,0.65)'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: 'rgba(0,0,0,0.09)',
          type: 'dashed'
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(0,0,0,0.25)'
        }
      },
      axisTick: {
        lineStyle: {
          color: '#ccc'
        }
      }
    }, {
      type: 'value',
      gridIndex: 1,
      minInterval: 1,
      axisLabel: {
        show: false
      },
      axisLine: {
        show: false
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: 'rgba(0,0,0,0.09)',
          type: 'dashed'
        }
      },
      axisTick: {
        show: false
      }
    }],
    series: [{
      data: yData,
      type: 'bar',
      barMaxWidth: 32,
      label: {
        show: false,
        position: 'top',
        color: '#333'
        // textStyle: {
        //   color: '#555'
        // }
      },
      itemStyle: {
        normal: {
          color: (params) => {
            return colors[params.dataIndex]
          }
        }
      },
      xAxisIndex: 0,
      yAxisIndex: 0
    },
    ...seriesArr
    ]
  }
  // console.log('option', option)
  return option
}

// 多轴图
export function barOption2({ titleArr, xData, yData, yData2 }) {
  const min1 = calMin(yData)
  const min2 = calMin(yData2)
  let max1 = calMax(yData)
  let max2 = calMax(yData2)
  let interval1 = (max1 - min1) / 5
  let interval2 = (max2 - min2) / 5
  if (max1 === 0) {
    max1 = 1
    interval1 = 1
  }
  if (max2 === 0) {
    max2 = 1
    interval2 = 1
  }
  return {
    color: ['#69B4F8', '#84E0BD'],
    grid: {
      left: 40,
      top: 60,
      right: 82,
      bottom: 50,
      containLabel: false
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    legend: {
      orient: 'horizontal',
      x: 'center',
      y: 'bottom',
      icon: 'circle',
      itemWidth: 5, // 设置宽度
      itemHeight: 5, // 设置高度
      textStyle: {
        color: '#666'
      }
    },
    //   data: titleArr
    // },
    xAxis: [
      {
        type: 'category',
        data: xData,
        axisPointer: {
          type: 'shadow'
        },
        axisLabel: {
          textStyle: {
            color: 'rgba(0,0,0,0.65)'
          }
        },
        axisLine: {
          lineStyle: {
            type: 'solid',
            color: 'rgba(0,0,0,0.25)'
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: titleArr[0],
        min: min1,
        max: max1,
        splitNumber: 5,
        interval: interval1,
        minInterval: 1,
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(0,0,0,0.65)'
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(0,0,0,0.09)',
            type: 'dashed'
          }
        }
      },
      {
        type: 'value',
        name: titleArr[1],
        min: min2,
        max: max2,
        splitNumber: 5,
        interval: interval2,
        minInterval: 1,
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(0,0,0,0.65)'
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(0,0,0,0.09)',
            type: 'dashed'
          }
        }
      }
    ],
    series: [
      {
        name: titleArr[0],
        type: 'bar',
        barMaxWidth: 32,
        data: yData
      },
      {
        name: titleArr[1],
        type: 'line',
        yAxisIndex: 1,
        data: yData2
      }
    ]
  }
}

// 近30天图
export function areaChartOption({ $echarts, xDataArr, yDataArr }) {
  return {
    grid: {
      left: 40,
      right: 40,
      top: 6,
      bottom: 0,
      textStyle: {
        color: '#fff'
      }
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      position: function(pt) {
        return [pt[0] - 36, '5%']
      },
      // formatter: '{b}<br /> <b>{c}%</b>'
      formatter: function(params) {
        return params[0].name + '<br /><span style="display: inline-block;width: 10px;height: 10px;border-radius: 10px;margin-right: 10px;background: rgb(114 159 249);"></span>' +
         '<b>' +
          params[0].value + '</b>'
      }
    },
    title: {
      left: 'center',
      text: '',
      show: false
    },
    toolbox: {
      show: false
    },

    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xDataArr,
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      }
    },

    series: [
      {
        name: '最近30天应用健康度趋势',
        type: 'line',
        symbol: 'none',
        sampling: 'lttb',
        itemStyle: {
          color: 'rgba(113, 158, 249, 1)'
        },
        areaStyle: {
          // color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //   {
          //     offset: 0,
          //     color: 'rgb(255, 158, 68)'
          //   },
          //   {
          //     offset: 1,
          //     color: 'rgb(255, 70, 131)'
          //   }
          // ])
          normal: {
            color: $echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#B7CFFF' // '#B7CFFF'
            }, {
              offset: 1,
              color: 'rgba(56, 138, 238, 0)'
            }], false),
            shadowColor: 'rgba(183, 207, 255, 0.1)',
            shadowBlur: 10
          }
        },
        data: yDataArr
      }
    ]
  }
}

// active chart
export function activeChartOption({ xData }) {
  const val = ((xData || xData === 0) && xData !== '--') ? Number(xData) : 0
  return {
    tooltip: {
      trigger: 'axis'
      // formatter: '{a} <br/>{b} : {c}%'
      // formatter: function(params) {
      //   console.log('params', params)
      //   return ''
      // }
    },
    series: [
      {
        itemStyle: {
          color: 'rgb(145, 233, 199)',
          borderWidth: 20,
          borderColor: 'rgb(145, 233, 199)'
        },

        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: false,
          clip: false,
          itemStyle: {
            borderWidth: 1,
            borderColor: '#ffffff'
          }
        },
        axisLine: {
          lineStyle: {
            color: [
              [1, '#69b4f8']
            ],
            width: 14
          }
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
          distance: 150
        },
        data: [{
          value: val, // 百分比的图不需要传%
          name: '',
          title: {
            offsetCenter: ['0%', '-30%']
          },
          detail: {
            offsetCenter: ['0%', '-20%']
          }
        }
        ],
        title: {
          fontSize: 14
        },
        detail: {
          width: 0,
          height: 14,
          fontSize: 14,
          color: 'auto',
          borderColor: 'auto',
          borderRadius: 20,
          borderWidth: 1,
          formatter: '{value}%',
          show: false
        }
      }]
  }
}
// 应用健康水平
export function qualityChartOption({ systemScore }) {
  const val = ((systemScore || systemScore === 0) && systemScore !== '--') ? Number(systemScore) : 0
  return {
    series: [
      {
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 100,
        splitNumber: 10,
        itemStyle: {
          color: val < 80 ? '#F59C9C' : '#84e0bd'
          // shadowColor: 'rgba(0, 0, 0, 0.07)',
          // shadowBlur: 14,
          // shadowOffsetX: -2,
          // shadowOffsetY: 2
        },
        progress: {
          show: true,
          roundCap: true,
          width: 14
        },
        pointer: {
          icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
          length: '86%',
          width: 10,
          offsetCenter: [0, '6%']
          // textStyle: {
          //   color: '#000'
          // }
        },
        axisLine: {
          roundCap: true,
          symbol: 'arrow',
          lineStyle: {
            width: 12,
            color: [[1, '#fff']]
          }
        },
        axisTick: {
          show: true,
          splitNumber: 1,
          lineStyle: {
            width: 1,
            type: [6, 10],
            dashOffset: 4,
            height: 1,
            color: '#d8d8d8'
          }
        },
        splitLine: {
          show: false,
          length: 12,
          lineStyle: {
            width: 3,
            color: '#fff'
          }
        },
        axisLabel: {
          show: false,
          distance: 30,
          color: '#999',
          fontSize: 20
        },
        title: {
          show: false
        },
        detail: {
          backgroundColor: '#fff',
          borderColor: '#999',
          // borderWidth: 2,
          width: '60%',
          lineHeight: 40,
          height: 20,
          borderRadius: 8,
          offsetCenter: [0, '-128%'],
          valueAnimation: true,
          formatter: function(value) {
            // return '{value|' + value.toFixed(0) + '}{unit|km/h}';
            return '{value|' + value.toFixed(0) + '}'
          },
          rich: {
            value: {
              fontSize: 26,
              fontWeight: 'bolder',
              color: val < 80 ? '#ff0000' : '#2fc25b'
            },
            unit: {
              fontSize: 20,
              color: '#999',
              padding: [0, 0, -20, 10]
            }
          }
        },
        data: [
          {
            value: val
            // itemStyle:{color:'#999'}
          }
        ]
      }
    ]
  }
}

export function systemChartOption({ valueSystem, valueAvg }) {
  let clientRadius = 76
  const w = document.documentElement.clientWidth
  if (w <= 1300) clientRadius = 30
  else if (w > 1300 && w <= 1440) clientRadius = 50
  else if (w > 1440 && w <= 1680) clientRadius = 70
  return {
    title: {
      text: ''// 应用质量
    },
    tooltip: {
      trigger: 'item',
      formatter: function(params) {
        let val = '<b>' + params.name + '</b>'
        val += '<br />' + '系统应用质量：' + params.data.value[0] + '分' +
      '<br />' + '系统故障率：' + params.data.value[3] + '%' +
      '<br />' + '系统三高一关键：' + params.data.value[2] + '分' +
      '<br />' + 'CXO业务触达率：' + params.data.value[1] + '%'
        return val
      }
    },
    legend: {
      show: false,
      data: [
        '系统应用质量',
        'CXO业务触达率',
        '系统三高一关键',
        '系统故障率'
      ]
    },
    radar: [
      {
        indicator: [
          { text: '系统应用质量', max: 100, axisLabel: { show: true, fontSize: 12, color: '#999', showMaxLabel: true, showMinLabel: true }},
          { text: 'CXO业务触达率', max: 100, axisLabel: { show: false, fontSize: 12, color: '#999', showMaxLabel: true, showMinLabel: true }},
          { text: '系统三高一关键', max: 100, axisLabel: { show: false, fontSize: 12, color: '#009ee0', showMaxLabel: true, showMinLabel: true }},
          { text: '系统故障率', max: 100, axisLabel: { show: false, fontSize: 12, color: '#999', showMaxLabel: true, showMinLabel: true }}
        ],
        radius: clientRadius,
        center: ['50%', '50%'],
        splitArea: {
          show: false,
          areaStyle: {
            color: 'rgba(255,255,255,1)' // 背景色
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(0,0,0,0.16)' // 背景色
          }
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(0,0,0,0.1)',
            // 坐标轴线线的颜色。
            width: 1,
            // 坐标轴线线宽。
            type: 'solid'
            // 坐标轴线线的类型。
          }
        },
        name: {
          textStyle: {
            color: 'rgba(0,0,0,0.38)'
          }
        }
      }
    ],
    series: [
      {
        type: 'radar',
        areaStyle: {},
        data: [
          {
            value: valueSystem,
            name: '健康水平',
            itemStyle: {
              normal: {
                color: '#69b4f8',
                lineStyle: {
                  color: '#0e77d7' // 图表中各个图区域的边框线颜色
                }
              },
              emphasis: { // 高亮时的样式
                color: '#0e77d7',
                lineStyle: {
                  color: '#0e77d7'
                }
              }
            },
            areaStyle: {
              normal: {
                width: 1,
                opacity: 0
              }
            }
          },
          {
            value: valueAvg,
            name: '行业均值',
            itemStyle: {
              normal: {
                color: '#91e9c7',
                lineStyle: {
                  // 图表中各个图区域的边框线颜色
                  color: '#84e0bd'
                }
              }, emphasis: { // 高亮时的样式
                color: '#84e0bd',
                lineStyle: {
                  color: '#84e0bd'
                }
              }
            },
            areaStyle: {
              normal: {
                width: 1,
                opacity: 0
              }
            }
          }
        ]
      }
    ]
  }
}
export function utilsDestroyedChart(vm, chart) {
  if (!vm[chart]) {
    return
  }
  // 清空 resize 事件处理函数
  window.removeEventListener('resize', vm.resize)
  // 清空 beforeunload 事件处理函数
  window.removeEventListener('beforeunload', vm.clearChart)

  // 组件销毁前，回收图表
  vm[chart].clear()
  vm[chart].dispose()
  vm[chart] = null
}

// 计算坐标轴最大值
export function calMax(arr) {
  let max = 0
  arr.forEach(e => {
    if (e !== '--' && (e || e === 0)) {
      if (max < e) {
        max = e
      }
    }
  })
  const maxInt = Math.ceil(max / 9.5)// 不让最高的值超过最上面的刻度
  const maxVal = maxInt * 10// 让显示的刻度是整数
  return maxVal
}

// 计算坐标轴最小值
export function calMin(arr) {
  let min = 0
  arr.forEach(e => {
    if (e !== '--' && (e || e === 0)) {
      if (min > e) {
        min = e
      }
    }
  })
  const minInt = Math.floor(min / 10)
  const minVal = minInt * 10// 让显示的刻度是整数
  return minVal
}

// let min1=calMin(arr)
// let min2=calMin(arr2)
// let max1=calMax(arr)
// let max2=calMax(arr2)
// min:min1,
// max:max1,
// splitNumber:5,
// interval:(max1-min1)/5,

// min:min2,
// max:max2,
// splitNumber:5,
// interval:(max2-min2)/5
