<template>
  <div class="resolved-page">
    <div class="table-wrap" :style="{minHeight}">

      <el-table
        ref="resolvedTable"
        v-loading="isLoading"
        :data="tableData"
        style="width: 100%"
        header-cell-class-name="table-th-class"
        :highlight-current-row="true"
        element-loading-text="加载中，请稍后..."
        :class="[isLoading?'table-loading':'',tableData&&tableData.length===0?' empty-table':'']"
      >

        <el-table-column
          prop="_sno"
          label="序号"
          width="60"
          show-overflow-tooltip
        />
        <el-table-column
          prop="productName"
          label="产品"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ scope.row.productName ?scope.row.productName:'--' }}
          </template>
        </el-table-column>

        <el-table-column
          prop="dimension"
          label="维度"
          show-overflow-tooltip
        />
        <el-table-column
          prop="checkName"
          label="检查大项"
          show-overflow-tooltip
        />
        <el-table-column
          prop="checkOneName"
          label="一级检查项"
          min-width="140"
          show-overflow-tooltip
        />
        <el-table-column
          prop="checkTwoName"
          label="二级检查项"
          min-width="160"
          show-overflow-tooltip
        />
        <el-table-column
          prop="score"
          label="扣分"
          width="70"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ (scope.row.score || scope.row.score===0)?scope.row.score:'--' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="firstAriseTime"
          label="首次诊断时间"
          width="110"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ scope.row.firstAriseTime?scope.row.firstAriseTime.substring(0,10):'' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="processersUsers"
          label="处理人"
          width="140"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ scope.row.processersUsers ?scope.row.processersUsers:'--' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="processState"
          label="处理状态"
          width="90"
          show-overflow-tooltip
        >
          <template #default="scope">
            <span v-if="scope.row.processState===0">待处理</span>
            <span v-if="scope.row.processState===6">挂起</span>
            <span v-if="scope.row.processState===3">处理中</span>
            <span v-if="scope.row.processState===10">已解决</span>
            <span v-if="scope.row.processState===11">自愈</span>
            <span v-else />
          </template>
        </el-table-column>
        <el-table-column
          label="处理日志"
          align="left"
          width="110"
          fixed="right"
        >
          <template #default="scope">
            <el-button
              type="text"
              @click="handleView(scope.row)"
            >查看</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column type="expand" width="1">
          <template #default="scope" :v-loading="isLoadingExpand">
            <Logs :logs="scope.row.logs" />
          </template>
        </el-table-column> -->
        <template v-if="tableData.length===0" #empty>
          <div class="my-empty-table">
            <div v-if="!isLoading" class="empty-txt">
              <div class="empty-icon" />
              <div class="empty-desc">暂无数据</div>
            </div>
            <div v-else />
          </div>
        </template>
      </el-table>
      <Pager
        :total="total"
        :current-page="currentPage"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
    <Logs ref="refLogs" :logs="logs" :dialog-view-visible="dialogViewVisible" @handleCloseView="handleCloseView" />
  </div>
</template>
<script>
import {
  apiKeyResolved, // 获取表格数据
  apiGetOperationLog
} from '@/api/health'
import Pager from '@/components/table/pagination.vue'
import Logs from '@/components/table/logs.vue'

export default {
  components: {
    Pager,
    Logs
  },
  data() {
    return {
      pageName: 'resolved',
      isLoading: false,
      total: 0,
      currentPage: 1,
      page: 1,
      pageSize: 20,
      pageSizes: [10, 20, 30, 40, 50, 100, 500],
      minHeight: '100px',
      tableData: [],
      dialogViewVisible: false,
      processState: '', // 当行的状态，处理弹框：根据状态显示操作下拉框内容
      id: null,
      logs: [],
      row: {}
    }
  },

  created() {
    const height = document.documentElement.clientHeight - 146
    this.minHeight = (height > 0 ? height : '100') + 'px'
    this.handleSearchTable()
  },
  methods: {

    // 表格数据显示
    handleSearchTable() {
      const vm = this
      const { page, pageSize } = vm
      const { tenantId, dimension, checkName } = this.$route.query
      const tablePara = { page, pageSize, params: { tenantId, dimension, checkName }}
      vm.isLoading = true
      apiKeyResolved(tablePara).then(res => {
        const { items, total } = res
        items.forEach(row => {
          const { processers } = row
          if (processers && processers.length > 0) {
            const users = []
            processers.forEach(processer => {
              users.push(processer.userName)
            })
            users.length === 1 ? row.processersUsers = users[0] : row.processersUsers = users.join('，')
          }
          row.logs = null
          apiGetOperationLog({ id: row.id }).then(res => {
            if (res.length > 0) {
              res.forEach(item => {
                item.isMore = true
              })
              row.logs = res
            }
          }).catch(() => {})
        })
        vm.tableData = items
        vm.total = total
      }).catch(() => {}).finally(() => { vm.isLoading = false })
    },
    /**
     @翻页，切换页面
     **/
    handleSearch(arg) {
      const { page, pageSize } = arg
      this.page = page
      this.pageSize = pageSize
      this.handleSearchTable()
    },
    // handleExpand(row) {
    //   const $table = this.$refs.resolvedTable
    //   $table.toggleRowExpansion(row)
    // },
    /**
     * 查看
     */
    handleView(row) {
      const vm = this
      const currentRow = JSON.parse(JSON.stringify(row))
      vm.id = currentRow.id
      vm.logs = currentRow.logs
      vm.dialogViewVisible = true
      // apiGetOperationLog({ id: vm.id }).then(res => {
      //   if (res.length > 0) {
      //     res.forEach(item => {
      //       item.isMore = true
      //     })
      //     vm.logs = res
      //   }
      //   vm.dialogViewVisible = true
      // }).catch(() => {})
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      this.page = 1
      this.handleSearchTable()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.page = val
      this.handleSearchTable()
    },

    handleCloseView(flag, isSubmit) {
      this.dialogViewVisible = flag
      if (isSubmit) this.handleSearchTable()
    }
  }
}
</script>
