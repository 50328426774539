<template>
  <el-pagination
    class="my-table-footer clearfix"
    background
    :current-page="currentPage"
    :hide-on-single-page="false"
    :page-sizes="pageSizes"
    :page-size="pageSize"
    :total="total"
    layout=" total, sizes, prev, pager, next, jumper"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>
<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default() {
        return 1
      }
    },
    total: {
      type: Number,
      default() {
        return 0
      }
    },
    pageSize: {
      type: Number,
      default() {
        return 0
      }
    },
    pageSizes: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.$emit('handleSizeChange', val)
    },
    handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val)
      // console.log(`当前页: ${val}`);
    }
  }
}
</script>
<style lang="scss">
  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";
  /* 翻页  开始 */
    .my-table-footer.is-background {
      text-align: right;
      background-color: #fff;
      padding: $padding - 5px $padding;
      .el-pagination__total {
        float: left;
        font-size: 14px;
      }
      .el-pagination__jump{
        margin-left: 5px;
      }
      .el-pagination__sizes {
        float: left;
        .el-input {
          margin-right: 0;
        }
      }
      .el-input__inner {
        font-size: 14px;
      }
      &.el-pagination {
        .el-pager {
          li {
            min-width: 28px;
            line-height: 26px;
            background-color: transparent;
            @include borderRadius(4px);
            border: 1px solid #DCDFE6;
            font-size: 14px;
            font-weight: normal;
            color: #55595E;
            &.active {
              background-color: transparent;
              border-color: $themeColor;
              color: $themeColor;
            }
          }
        }
        button {
          background-color: transparent;
          @include borderRadius(4px);
          border: 1px solid #DCDFE6;
        }
      }
    }
    /* 翻页  结束 */
</style>
