<template>
  <div class="details-page">
    <Search :search-params="getTableParams" @changeParams="changeParams"/>
    <MyTable
      :thisTable="this"
      ref="detailsTable"
      :row-key="'number'"
      :handle-option="handleOption"
      :tree-props="treeProps"
      :column-config="columnConfig"
      :table-config="tableConfig"
      :get-table-params="getTableParams"
      :is-loading="isLoading"
      :expand-all="true"
      :http-fun="getPlanDetail"
      :height="`calc( 100vh - 104px )`"
      @afterGetDataSuccess="afterGetDataSuccess"
    >
      <!--   服务状态   -->
      <template #StatusSlot="{ row }">
        <span
            v-if="row.hasOwnProperty('status')"
            class="table-status "
            :class="calcCurrentStatus(row.status).cls"
        >
        {{ calcCurrentStatus(row.status || 0).Name }}
      </span>
      </template>

      <!--   计划时间   -->
      <template #PlanTimeSlot="{ row }">
        <span  v-if="row.hasOwnProperty('status')"> {{ row.planTime ? formatTime(row.planTime) : '--' }}</span>
      </template>

      <!--   完成时间   -->
      <template #CompleteTimeSlot="{ row }">
        <span v-if="row.hasOwnProperty('status')">{{ row.completeTime ? formatTime(row.completeTime) : '--' }}</span>
      </template>

      <!--   责任人   -->
      <template #ResponsiblePersonNameSlot="{ row }">
        <span v-if="row.hasOwnProperty('status')">{{ row.responsiblePersonName || '--' }}</span>
      </template>

      <template #openDetails="{ row }">
        <span class=" handler-text" @click.stop="onOpenToShowDetails(row)" v-if="!row.hasOwnProperty('details')">查看</span>
      </template>
    </MyTable>

    <Details :visible.sync="isShowDetail" :row.sync="currentInfo" @onCloseDialog="onCloseDialog"></Details>
  </div>
</template>

<script>
import Search from './components/Search'
import MyTable from './components/MyTable'
import Details from './components/Details'
import { PLAN_STATUS } from '@/utils/constants'
import {  getPlanDetail } from '@/api/success'

export default {
  name: 'Detail',
  components: {
    Search, MyTable, Details
  },
  props: {},
  data() {
    return {
      getPlanDetail,
      isLoading: false,
      isShowDetail: false,
      treeProps: {
        children: 'details'
      },
      tableConfig: {
        tableWidth: '100%', // 表格宽度
        hasExpand: false, // 是否显示展开
        showTableOption: true, // 是否显示操作列
        cellOptionWidth: '80px', // 操作列宽
        showPagination: false, // 是否显示翻页
        pagerCount: 7, // 翻页按钮个数
        fixedHandleColumn: true, // 是否固定操作列
        hasExpandWidth: 1, // 展开列的宽度
        style: { height: 'auto' },
        handleLineText: '操作', // 操作列表头名称
        sortProp: '' // 默认排序字段和排序方式
      },
      getTableParams: {
        Year: '',
        ItemName: '',
        Status: -1
      },
      currentInfo: null,
      columnConfig: [
        { label: '工作项编号', prop: 'number', width: '110px', isHide: true },
        { label: '工作项名称', prop: 'name', width: '220px',  hasCustomHeader: true, headerSlot: 'NameTips', isHide: true },
        { label: '工作项内容说明', prop: 'content', width: 'auto', minWidth: '150px', hasCustomHeader: true, headerSlot: 'ContentTips', isHide: true },
        { label: '服务状态', prop: 'status', width: '150px', slot: 'StatusSlot', hasCustomHeader: true, headerSlot: 'StatusTips', isHide: true },
        { label: '计划时间', prop: 'planTime', slot: 'PlanTimeSlot', width: '150px', hasCustomHeader: true, headerSlot: 'EntCodeTips', isHide: true },
        { label: '完成时间', prop: 'completeTime', slot: 'CompleteTimeSlot', width: '150px', hasCustomHeader: true, headerSlot: 'YwStatusTips', isHide: true },
        { label: '责任人', prop: 'responsiblePersonName', slot: 'ResponsiblePersonNameSlot', width: '150px', hasCustomHeader: true, headerSlot: 'YearTips', isHide: true }
      ],
      handleOption: [
        { name: '查看', fun: 'onOpenToShowDetailsEvent', icon: '', type: 'open', slot:'openDetails' }
      ],
    }
  },
  computed: {},
  watch(){
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
    sessionStorage.removeItem('searchParams')
  },
  methods: {
    onOpenToShowDetails(row) {
      this.isShowDetail = true
      this.currentInfo = row
    },

    changeParams(params) {
      this.getTableParams = params
    },

    // 获取成功数据之后
    afterGetDataSuccess(res) {
      res.forEach(val => {
        const details = val.details || []
        details.forEach((v, i) => {
          v.name = `第${i+ 1}次`
        })
      })
    },

    // 计算当前的服务状态显示值
    calcCurrentStatus(Id) {
      const currentItems = PLAN_STATUS.findIndex(val => val.Id === Id)
      const { Name, cls } = currentItems > -1 ? PLAN_STATUS[currentItems] : {}
      return { Name, cls }
    },

    //时间格式化
    formatTime(time) {
      return this.$moment(time).format('YYYY-MM-DD')
    },

    onCloseDialog() {
      this.isShowDetail = false
      this.currentInfo = null
    }
  }
}
</script>

<style scoped lang='scss'>
.details-page {
  background-color: #fff;
  height: calc( 100vh - 52px );
}
</style>
