import { getHasNotRead } from '@/api/message'
import { getNoticeIsRead, setNoticeRead } from '@/api/notice'

const state = {
  hasMessage: 0,
  isNoticeRead: false
}

const mutations = {
  SET_HAS_MESSAGE: (stateMessage, hasMessage) => {
    stateMessage.hasMessage = hasMessage
  },
  SET_NOTICE_IS_READ: (stateNotice, hasNotice) => {
    stateNotice.isNoticeRead = hasNotice
  }
}
const actions = {
  setMessageStatus({ commit }) {
    return new Promise((resolve, reject) => {
      getHasNotRead()
        .then((data) => {
          commit('SET_HAS_MESSAGE', data)
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  setMessageNumber({ commit }) {
    commit('SET_HAS_MESSAGE', state.hasMessage + 1)
  },

  // 获取公告是否已读
  getNoticeIsRead({ commit }) {
    return new Promise((resolve, reject) => {
      getNoticeIsRead()
        .then((data) => {
          commit('SET_NOTICE_IS_READ', data)
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // 设置公告已读
  setNoticeReaded({ commit }) {
    return new Promise((resolve, reject) => {
      setNoticeRead()
        .then((data) => {
          commit('SET_NOTICE_IS_READ', false)
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
