import axios from 'axios'
// import { Message } from 'element-ui'
// import router from '../../example/router'
// import store from '@/store'
// import { get } from 'lodash'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API + '/mysm', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    // if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    // config.headers['X-Token'] = getToken()
    // }
    return config
  },
  (error) => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    const errorRes = error.response
    console.log(errorRes)
    // if (errorRes.status === 403 && errorRes.data === 'Please login.') {
    //   store.dispatch('user/logout').then(() => {
    //
    //   })
    //   return
    // }
    // Message({
    //   message: decodeURI(get(errorRes, 'headers.x-errormessage', '网络错误')),
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
