import { request } from './request'
// import qs from 'qs'
import { BASE_URL } from '@/utils/constants'

// 手机号验证
export function checkPhone(params) {
  return request({
    url: `${BASE_URL.WEB}/account/user/check-phone.svc`,
    method: 'GET',
    params
  })
}

// 获取验证码
export function getVerCode(params) {
  return request({
    url: `${BASE_URL.WEB}/account/user/get-ver-code.svc`,
    method: 'GET',
    params
  })
}

// 登录
export function loginByPhone(data) {
  return request({
    url: `${BASE_URL.WEB}/account/user/login-by-phone.svc`,
    method: 'POST',
    headers: {
      'content-type': 'application/json; chartset=utf-8'
    },
    data
  })
}

// 注册
export function registerAccount(data) {
  return request({
    url: `${BASE_URL.WEB}/account/user/register.svc`,
    method: 'POST',
    data
  })
}

// 登录域账号
export function loginByDomain(data) {
  return request({
    url: `${BASE_URL.WEB}/account/user/login-by-domain.svc`,
    method: 'POST',
    data
  })
}

// 后台跳转过来登录
export function loginByAdmin(params) {
  return request({
    url: `${BASE_URL.WEB}/account/user/login-from-admin.svc`,
    method: 'GET',
    params
  })
}
// 退出登录
export function logout() {
  return request({
    url: `${BASE_URL.WEB}/account/user/logout.svc`,
    method: 'GET'
  })
}

// 获取登录信息
export function getLoginInfo() {
  return request({
    url: `${BASE_URL.WEB}/account/user/login-info.svc`,
    method: 'GET'
  })
}

// 登录
export function login(data) {
  return request({
    url: `${BASE_URL.WEB}/account/user/login.svc`,
    method: 'POST',
    data
  })
}

// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: `${BASE_URL.WEB}/account/user/detail.svc `,
    method: 'GET',
    data
  })
}

// 更新用户信息
export function userUpdate(data) {
  return request({
    url: `${BASE_URL.WEB}/account/user/update.svc `,
    method: 'POST',
    data
  })
}
