<template>
  <div class="panel-items clearfix">

    <div class="product">

      <div class="product-detail">

        <el-popover
          placement="top-start"
          title=""
          width="400"
          trigger="hover"
          :content="
            panelData.subSysNameList &&
              panelData.subSysNameList.length > 0
              ? panelData.subSysNameList.join('\n')
              : ''
          "
        >
          <template #reference>
            <span

              :class="['product-name',(panelData.subSysNameList &&
                panelData.subSysNameList.length > 0)?'':' no-data']"
            >{{
              panelData.subSysNameList &&
                panelData.subSysNameList.length > 0
                ? panelData.subSysNameList.join('\n')
                : '----'
            }}</span>
          </template>
        </el-popover>

      </div>
      <div class="auxi" title="业务系统">业务系统</div>
    </div>
    <div class="line" />
    <div class="service">
      <div class="service-detail">
        <span :class="['service-name ',panelData.serviceStatus?'':' no-data']">{{ panelData.serviceStatus?panelData.serviceStatus:'----' }} </span>
        <span
          class="service-time"
        ><span>到期时间</span><label :class="panelData.dueOn?'':' no-data'">{{
          panelData.dueOn ? panelData.dueOn.substring(0, 10) : '----'
        }}</label></span>
      </div>
      <div class="auxi">服务状态</div>
    </div>
    <div class="line" />
    <div class="contract">
      <div class="contract-detail">
        <span :class="['contract-name',panelData.consultantName?'':' no-data']">{{
          panelData.consultantName? panelData.consultantName:'----'
        }}</span>
        <div class="contract-us">
          <span
            :class="['contract-phone',panelData.consultantPhone?'':' no-data']"
            @click="handleContract"
          >{{ panelData.consultantPhone?panelData.consultantPhone:'----' }}
            <span
              v-if="panelData.qrCode"
              class="wechat"
              @mouseover="handleOver"
              @mouseout="handleOut"
            ><svg-icon icon-class="wechat" />
              <div class="show-code">
                <el-collapse-transition>
                  <div v-show="isShowCode">
                    <div class="transition-box">
                      <el-avatar
                        class="avatar-img"
                        alt=""
                        :src="
                          panelData.qrCode
                            ? panelData.qrCode
                            : ''
                        "
                        :size="150"
                      />
                    </div>
                  </div>
                </el-collapse-transition></div></span>
            <span v-else class="wechat no-chat"><svg-icon icon-class="wechat" /></span>
          </span>
          <span :class="['contract-email',panelData.consultantEmail?'':' no-data']">{{
            panelData.consultantEmail?panelData.consultantEmail:'----'
          }}</span>
        </div>
      </div>
      <div class="auxi">明源客户成功经理</div>
    </div>
    <div class="line" />
    <div class="online">
      <div class="online-contract" @click="handleOnline">在线联系</div>
      <div class="auxi">明源运维专家</div>
    </div>
  </div>
</template>

<script>
// import Bus from '@/Bus'

export default {
  name: 'ServicePanelItems',
  components: {},
  props: {
    panelData: {
      // 需要展示的值
      type: Object,
      default() {
        return {}
      }
    },
    textAlign: {
      // 文字居中方式
      type: String,
      default() {
        return 'left'
      }
    }
  },
  data() {
    return {
      isShowCode: false
    }
  },
  methods: {
    handleContract() {},
    handleOnline() {
      const userInfo = this.$store.state.userInfo.userInfo
      const { domainUserGuid, userName, userCode } = userInfo
      const url =
                'https://sol.fdcyun.com/web/erp?guid=Robit_YYW&companyname=云运维后台&erpversion=ERP&userguid=' +
                `${domainUserGuid}&username=${userName}&appname=云运维&loingname=${userCode}&_from=Robit_YYW_HT`
      window.open(url, '_blank')
    },
    handleOver() {
      console.log('hello')
      this.isShowCode = true
    },
    handleOut() {
      this.isShowCode = false
    }
  }
}
</script>

<style scoped lang='scss'>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.scss';

 .show-code{
        position: absolute;
        right: 0;
        top: 26px;
        z-index:2;
    }
 .transition-box {
    margin-bottom: 10px;
    width: 156px;
    height: 156px;
    padding: 2px;
    border-radius: 2px;
    background:#Fff;
    border:1px solid #ccc;
    span{
        border-radius: 0;
    }
  }

.panel-items {
    display: flex;
    justify-content: space-between;
    .no-data{
        color: rgba(0, 0, 0, 0.25);
    }
    .product,
    .service,
    .contract {
        // float: left;
        // max-width: 28%;
        color: rgba(0, 0, 0, 0.85);
        font-size: 18px;
        position: relative;
        padding: 0 30px;
    }
    .line {
        width: 1px;
        height: 40px;
        position: relative;
        &:after {
            position: absolute;
            right: 0;
            width: 1px;
            height: 40px;
            background: #e9e9e9;
            content: '';
            top: 16px;
        }
    }
    .product {
        padding-left: 10px;
    }
    .auxi {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        display: block;
        padding-top: 4px;
    }
    .sub-product-name {
        display: inline-block;
        margin-left: 20px;
    }
    .service-name {
        display: inline-block;
        min-width: 110px;
        &.no-data{
            color: rgba(0, 0, 0, 0.25);
        }
    }
    .service-time,
    .contract-us {
        display: inline-block;
        // margin-left: 20px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        position: relative;
        top: -1px;
    }
    .service-time {
        min-width: 140px;
        position: relative;
        top: 1px;
        span {
            display: inline-block;
            margin-right: 6px;
        }
        label {
            font-weight: normal;
            display: inline-block;
        }
    }
    .contract {
        .auxi {
            margin-top: -4px;
        }

        .contract-us {
            vertical-align: top;
            position: relative;
            top: -9px;
            span {
                display: block;
            }
            // .contract-name {
            //     margin-right: 20px;
            // }
            .contract-email {
                margin-top: -4px;
            }
            .contract-phone {
                position: relative;
                top: 4px;
                .wechat {
                    padding: 4px 30px 4px 12px;
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    top: 2px;
                    &.no-chat{
                        cursor:default;
                        svg{
                            color: rgba(0, 0, 0, 0.25);
                        }
                        &:hover{
                            svg{
                            color: rgba(0, 0, 0, 0.25);
                         }
                        }
                    }
                    svg {
                        color: $activeText;
                        width: 1.3em;
                        height: 1.3em;
                    }
                    &:hover {
                        svg {
                            color: $hoverActiveText;
                        }
                    }
                }
            }
        }
    }
    .online {
        float: left;
        width: 14%;
        padding-left: 30px;
        .online-contract {
            width: 84px;
            height: 32px;
            line-height: 32px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid $activeText;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
            color: $activeText;
            margin-top: 7px;
            margin-bottom: 12px;
            &:hover {
                color: $hoverActiveText;
                border: 1px solid $hoverActiveText;
            }
        }
        .auxi {
            padding-top: 3px;
        }
    }

    .product-detail,
    .service-detail,
    .contract-detail {
        display: flex;
    }
    .product-name,
    .sub-product-name,
    .service-detail {
        padding: 2px 0 0 0;
        height: 24px;
        // width: 89%;
        line-height: 24px;

        // padding-right: 50px;
    }
    .product-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .contract-detail {
        padding: 2px 0 0 0;
        // width: 89%;
        line-height: 24px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        min-width: 296px;
    }
    .contract-name {
        min-width: 170px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
@media (max-width: 1280px) {
    .product-name,
    .sub-product-name,
    .service-detail,
    .contract-detail {
        // width: 94%;
        // padding: 0 10px;
    }
    .panel-items {
        .product,
        .service,
        .contract {
            // width: 29%;
            padding: 0 10px;
            &:after {
                position: absolute;
                right: 10px;
            }
        }
        .online {
            width: 13%;
            padding-left: 10px;
        }
    }
}
.product,
.service {
    .auxi {
        padding-top: 30px;
    }
}
.product{
  .auxi{
    width: 100%;
    overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
  }
}
</style>
