<template>
    <el-dialog
            class="service-add_dialog"
            v-model="visible"
            title="查看"
            width="600px"
            destroy-on-close
            @close="onCancelHandle"
    >
        <el-form
                slot="body"
                ref="addPlanFrom"
                :model="addForm"
                size="small"
                label-width="120px"
                v-loading="loadingDetails"
                element-loading-text="加载中，请稍后..."
                class="service-add clearfix disabled-preview"
        >
            <div class="form-inline clearfix">
                <el-form-item label="工作项名称 ：" prop="Name" :title="addForm.itemName">
                    {{addForm.itemName || '--'}}
                </el-form-item>
                <el-form-item label="工作项编号 ：" prop="Number" :title="addForm.number">
                    {{addForm.number || '--'}}
                </el-form-item>
            </div>
            <el-form-item label="工作内容说明：" prop="Content" :title="addForm.content">
                {{addForm.content || '--'}}
            </el-form-item>
            <el-form-item label="计划时间 ：" prop="PlanTime" :title="addForm.planTime">
               {{addForm.planTime ?  formatTime(addForm.planTime) : '--'}}
            </el-form-item>
            <el-form-item label="完成时间 ：" prop="CompleteTime" :title="addForm.completeTime">
                {{addForm.completeTime ? formatTime(addForm.completeTime) : '--'}}
            </el-form-item>
            <el-form-item label="服务交付说明 ：" class="deliverys-description" prop="DeliveryInstruction" :title="addForm.deliveryInstruction">
                <el-input type="textarea" size="small" disabled resize="none" placeholder="--"  :autosize="{ minRows: 1, maxRows: 3 }" v-model="addForm.deliveryInstruction"></el-input>
<!--              {{addForm.deliveryInstruction}}-->
            </el-form-item>
            <el-form-item label="服务交付物：" prop="fileList">
                <ul class="assigned-more_files" v-if="addForm.deliverables && addForm.deliverables.length > 0">
                    <li v-for="(item, index) in addForm.deliverables" :key="index" class="assigned-files_item" :title="'点击下载'">
                        <a :href="`/v20/api/cancerwebapp/customersuccess/download-deliverable.svc?id=${item.id}`" :download="item.name">
                            <SvgIcon class="assigned-file_icon" :icon-class="FILETYPEICON[judgeFileType(item.fileName || '')] || 'Default'" />
                            <span class="assigned-file_name" :title="item.fileName">{{ item.fileName || '--' }}</span>
                        </a>
                    </li>
                </ul>
                <template v-else>暂无交付物</template>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
    import { FILETYPEICON } from '@/utils/constants'
    import {judgeFileTypeToShowIcon} from "@/utils";
import { getDetails } from "@/api/success";
import { SvgIcon, Empty } from "@/components";

    export default {
  name: 'Add',
  components: { SvgIcon, Empty },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
      row: {
        type: Object,
          default: () => {
              return null
          }
      }
  },
  data() {
    return {
        FILETYPEICON,
        loadingDetails: false,
        addForm: {
            itemName: '',
            number: '',
            content: '',
            planTime: '',
            completeTime: '',
            deliveryInstruction: '',
            deliverables: []
        },
     }
  },
  computed: {},
  watch: {
    visible: {
      handler(visible) {
          if (visible) {
              const { id } = this.row
              this.getDetailsEvent(id)
          }
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
      getDetailsEvent(id) {
          this.loadingDetails = true
          getDetails({id}).then((res) => {
              // 这里要求不取详情接口的编号，直接取列表当前行的编号
              this.addForm = res || {}
              this.addForm.number = this.row.number
          }).finally(() => {
              this.loadingDetails = false
          })
      },

      // 判断文件格式
      judgeFileType(fileName) {
          const type = fileName.split('.')[fileName.split('.').length - 1] || ''
          return judgeFileTypeToShowIcon(type)
      },

      //时间格式化
      formatTime(time) {
          return this.$moment(time).format('YYYY-MM-DD')
      },

      onCancelHandle() {
          this.$emit('onCloseDialog')
      },
  }
}
</script>

<style scoped lang='scss'>
::v-deep .el-dialog__body {
    padding: 20px !important;
}
  .service-add {
      .el-dialog__body {
          min-height: 500px;
      }
    .form-inline {
      ::v-deep .el-form-item {
        width: 50%;
        float: left;
        &:nth-of-type(2){
          padding-left: 5%;
        }
      }
    }
    &.disabled-preview {
      .form-inline {
        ::v-deep .el-form-item {
          &:nth-of-type(1){
            /*width: 70%;*/
            width: 55%;
          }
          &:nth-of-type(2){
            width: 45%;
            /*width: 30%;*/
            padding-left: 0;
          }
        }
      }
    }
      ::v-deep .el-form-item__label {
          padding-left: 12px;
          position: relative;
          font-size: 13px;
          font-weight: normal;
          color: #666666 !important;
      }
      ::v-deep .el-form-item__content{
          color: #222222;
          font-size: 13px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
      }
      ::v-deep .el-form-item {
          &.deliverys-description {
              .el-textarea__inner {
                  padding-left: 0;
                  background-color: transparent;
                  color: #222222;
                  cursor: default;
                  border-color: transparent;
                  &::placeholder{
                      color:#222222;
                  }
              }
              .el-form-item__content {
                  overflow: unset;
                  white-space: unset;
                  text-overflow: unset;
              }
          }
      }
      .assigned-more_files{
          min-height: 150px;
          max-height: 180px;
          overflow-y: auto;
          padding-top: 4px;
      }
      .assigned-files_item {
          margin-bottom: 2px;
          padding: 0 4px;
          height: 24px;
          line-height: 24px;
          border-radius: 4px;
          &:last-of-type {
              margin-bottom: 0;
          }
          &:hover {
              background-color: #f5f5f5;
          }
          .assigned-file_icon {
              vertical-align: middle;
          }
          .assigned-file_name {
              margin-left: 8px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              display: inline-block;
              vertical-align: middle;
              width: calc( 100% - 40px );
          }
      }
 }

  .service-template{
    min-height: 120px;
  }
</style>
