<template>
  <div class="list">
    <CommonInfo />
    <div class="solution-page">
      <MenuCom :menu-list="menuList" @clickMenu="handleClickMenu" />
      <component :is="pages[pageId]" />
    </div>
  </div>
</template>

<script>

import CommonInfo from '@/views/components/commonInfo.vue'
import MenuCom from '@/views/components/menu'
import History from './history'
import Resolved from './resolved'

export default {
  components: {
    CommonInfo,
    MenuCom,
    History,
    Resolved
  },
  data() {
    return {
      pages: ['History', 'Resolved'],
      pageId: 0,
      charts: [],
      menuList: [{
        id: 0,
        name: '昨日异常项',
        value: 'history'
      }, {
        id: 1,
        name: '已解决异常项',
        value: 'resolved'
      }]
    }
  },
  methods: {
    // 点击切换横向菜单
    handleClickMenu() {
      this.pageId = arguments[0].id
    }
  }
}
</script>
