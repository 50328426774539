import {
  login,
  // logout,
  getLoginInfo,
  loginByPhone
} from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/cookie.js'
import { resetRouter } from '@/router'
import { apiLogout } from '@/api/app'
const getDefaultState = () => {
  return {
    login: -1, // 是否登录
    token: getToken(),
    userLoginInfo: null, // 用户登录信息
    loginUser: null, // 登录后获取到的用户信息
    roles: null,
    isAdmin: false,
    health: {
      index: {
        urlDate: ''
      }
    }
  }
}

const state = getDefaultState()

const mutations = {
  SET_LOGIN: (state, staus) => {
    state.login = staus
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  // 用户登录的信息
  SET_USERINFO: (state, userLoginInfo) => {
    state.userLoginInfo = userLoginInfo
  },
  SET_LOGINUSER: (state, loginUser) => {
    state.loginUser = loginUser
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ADMIN: (state, isAdmin) => {
    state.isAdmin = isAdmin
  },
  // URL时间参数
  SET_HEALTH_INDEX_URL: (state, { date }) => {
    state.health.index.urlDate = date
  }
}
const actions = {
  // // 用户登录
  login({ commit }, userLoginInfo) {
    // login({ commit, state, dispatch }, userLoginInfo) {
    const { EntCode, UserCode, Pwd, IsAdmin } = userLoginInfo
    const params = { UserCode, Pwd }

    commit('SET_ADMIN', IsAdmin)
    setToken(IsAdmin, 'IsAdmin')
    const baeParams = IsAdmin ? params : { EntCode, ...params }
    return new Promise((resolve, reject) => {
      loginByPhone(userLoginInfo)
        .then((res) => {
          // todo： 跳转
          commit('SET_TOKEN', res)
          setToken(UserCode)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      login(baeParams, IsAdmin)
        .then((res) => {
          commit('SET_TOKEN', res)
          setToken(UserCode)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 用户登录  手机号

  getInfo({ commit }) {
    const isAdmin = getToken('IsAdmin')
    return new Promise((resolve, reject) => {
      getLoginInfo(isAdmin === 'true' ? isAdmin : state.isAdmin)
        .then((response) => {
          // if (!response) {
          // return reject('您的登录已经过期')
          // }
          commit('SET_LOGINUSER', response)
          commit('SET_ROLES', response.UserRole)
          commit('SET_USERINFO', response)
          resolve(response.UserRole)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      apiLogout()
        .then(() => {
          // resetRouter();
          removeToken('xtoken')
          removeToken('token')
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // user logout
  // logout({ commit, state }) {
  //   const isAdmin = getToken('IsAdmin');
  //   return new Promise((resolve, reject) => {
  //     logout(isAdmin === 'true' ? isAdmin : state.isAdmin)
  //       .then(() => {
  //         removeToken(); // must remove  token  first
  //         removeToken('IsAdmin');
  //         resetRouter();
  //         commit('SET_TOKEN', '');
  //         commit('SET_ROLES', []);
  //         commit('SET_USERINFO', null);
  //         commit('SET_ROLES', null);
  //         resolve();
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken() // must remove  token  first
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_USERINFO', null)
      commit('SET_ROLES', null)
      resolve()
    })
  },
  setUrlParams({ commit }, params) {
    return new Promise((resolve) => {
      let commitState = ''
      if (params && params.date) {
        const { date, path } = params
        if (path && path.indexOf('/360d') > -1) {
          commitState = 'SET_HEALTH_INDEX_URL'
          commit(commitState, { date })
        }
      }
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
