<template>
  <div v-if="headerItems.length > 0" class="table-switch" :class="textAlign">
    <span
      v-for="(item, ind) in headerItems"
      :key="ind"
      class="table-switch_item"
      :class="{ active: activeInd === ind, disabled: item.IsDisabled }"
      :title="item.Name"
      @click="onSwitchTab(item, ind)"
    >
      <slot v-if="item.slot" :name="item.slot" />
      <span v-else>{{ item.Name }}</span>
    </span>
    <slot name="otherSlot" />
  </div>
</template>

<script>
export default {
  name: 'TableSwitch',
  components: {},
  props: {
    activeInd: {
      // 当前选中项(sync传递)
      type: Number,
      default: 0
    },
    textAlign: {
      type: String,
      default: ''
    },
    headerItems: {
      // 当前可以筛选的项
      type: Array,
      default() {
        return []
        // 数据例子：[{Name: '名称', Type: 'test',}]
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // console.log('-----headerItems', this.headerItems);
  },
  methods: {
    // 点击选中
    onSwitchTab(item, ind) {
      // this.$emit('update:activeInd', ind);
      this.$emit('updateActiveInd', ind)
      this.$emit('onSwitchTab', item)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.table-switch {
  height: 46px;
  background: #fff;
  line-height: 46px;
  text-align: center;
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  &_item {
    height: 100%;
    display: inline-block;
    padding: 0 10px;
    margin-right: 2px;
    position: relative;
    cursor: pointer;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: fit-content;
    width: -moz-fit-content;
    &:last-of-type {
      margin-right: 0;
    }
    &::before {
      content: '';
      display: inline-block;
      width: 0;
      height: 2px;
      background-color: #0f6dff;
      position: absolute;
      bottom: 0;
      left: 50%;
      transition: all 0.2s linear;
    }
    &.active {
      background-color: #fff;
      border-right: none;
      color: #0f6dff;
      &::before {
        width: 100%;
        left: 0;
      }
    }
    &:hover {
      color: #0f6dff;
      &::before {
        width: 100%;
        left: 0;
      }
    }

    &.disabled {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.25);
      &::before {
        display: none;
      }
      &:hover {
        color: rgba(0, 0, 0, 0.25);
      }
      &.active {
        background-color: inherit;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}
</style>
