import request from './index' // request带上参数router
export function get(url, p) {
  return request({
    url,
    method: 'get',
    params: p
  })
}
export function post(url, d) {
  return request({
    url,
    method: 'post',
    data: d
  })
}

export function deleteRequest(url, p) {
  return request({
    url,
    method: 'delete',
    params: p
  })
}

export function put(url, d) {
  return request({
    url,
    method: 'put',
    data: d
  })
}
