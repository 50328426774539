<template>
  <el-scrollbar ref="appMain" class="app-main" :class="{'has-bg': hasBgColor}" :style="{height: `calc(100vh - ${variables.headerHeight} )`}">
    <div class="app-main__content" :class="{'noPadding' : noPadding}">
      <!-- <Breadcrumb v-if="hasBreadcrumb" /> -->
      <slot name="headerLine" />
      <!-- <transition :name="hasAnimation ? 'fade-transform' : ''" mode="out-in">

      </transition> -->
      <router-view :key="key" />
    </div>
  </el-scrollbar>
</template>

<script>
// import variables from '@/styles/variables.scss'
// import { Breadcrumb } from '@/components'
import { defineComponent
//  ref
} from 'vue'

export default defineComponent({
  name: 'AppMain',
  // components: { Breadcrumb },
  props: {
    hasBreadcrumb: { // 是否有面包屑
      type: Boolean,
      default: true
    },
    hasBgColor: { // 是否有背景色
      type: Boolean,
      default: false
    },
    baseUrl: {
      type: String,
      default: 'mysm'
    }
  },
  setup() {
    // const isCollapse = ref(false)
    const levelList = []
    const key = () => {
      return this.$route.path
    }
    const variables = () => {
      return { headerHeight: '60px' }
    }
    const hasAnimation = () => {
      const pathName = ['Activity', 'Ranking', 'Company', 'Unused', 'MobileIndex', 'MobileDetail', 'ApproveIndex', 'ApproveDetail']
      return !pathName.includes(this.$route.name)
    }
    return {
      levelList,
      key,
      variables,
      hasAnimation
    }
  },
  computed: {
    noPadding() {
      return this.$route.meta.noPadding
    }
  }
})

// watch: {
//   $route() {
//     this.getBreadcrumb()
//   }
// },
// created() {
//   this.getBreadcrumb()
// },
// methods: {

//   getBreadcrumb() {
//     const logParams = {}
//     // only show routes with meta.title
//     const matched = this.$route.matched.filter(item => item.meta && item.meta.title)
//     this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
//     this.levelList.forEach((val, i) => {
//       logParams[`m${i + 1}`] = val.meta.title
//     })
//     // baseUrl目前都是mysm， 预留是为了后期如果需要单独做
//     this.$store.dispatch('appLog/appMLog', { params: logParams, baseUrl: this.baseUrl })
//   }
// }

</script>

<style scoped lang="scss">

.app-main {
  width: 100%;
  &.has-bg {
    // background-color: $workbenchBg;
  }
}
</style>
