import request from '../utils/request'

export function getInfo() {
  return request({
    url: `/account/user/login-info.svc`,
    method: 'GET'
  })
}

// 退出登录
export function logout() {
  return request({
    url: `/account/user/logout.svc`,
    method: 'GET'
  })
}

// 是否显示大屏按钮
export function isShowDashboardBtn() {
  return request({
    url: `/workbench/get-service-value-report.svc`,
    method: 'GET'
  })
}
