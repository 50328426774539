<template>
  <div class="header-navbar-wrapper clearfix">
    <Left
      :menu-list="menuList"
      :current-app="currentApp"
      :current="current"
      :left-icons="leftIcons"
      :has-no-left-icon="hasNoLeftIcon"
      :current-id="currentId"
    />
    <Right
      class="header-navbar-right"
      :biz-code="bizCode"
      :tenant-name="tenantName"
      :menu-list="menuList"
      :product-name="productName"
      :user-name="userName"
      :current-id="currentId"
      :help-link="helpLink"
      :user-icon="userIcon"
      :current="current"
      :is-tenant-admin="isTenantAdmin"
      :current-app="currentApp"
      :has-message="hasMessage"
      :has-notice="isNoticeRead"
      :right-icon-custom-event="rightIconCustomEvent"
      :has-no-right-icon="hasNoRightIcon"
      :user-panel-show-obj="userPanelShowObj"
      @logout="logout"
      @getCurrentRoute="getCurrentRoute"
      @handelOnRightIcon="handelOnRightIcon"
      @onShowNotice="setNoticeReaded"
    >
      <slot />
    </Right>
  </div>
</template>

<script>
import Left from './components/left'
import Right from './components/right'
import { BASE_URL } from '../../utils/constants'
import { getActiveHttp } from '../../utils'
import { getHasNotRead, getHeaderPermission } from '../../api/message'
import { getNoticeIsRead, setNoticeRead } from '../../api/notice'
import { isShowDashboardBtn } from '../../api/user'
import * as signalR from '@microsoft/signalr'
import { setToken } from '../../utils/auth'

export default {
  name: 'HeaderNav',
  components: { Left, Right },
  props: {
    current: { type: String, default: '' },
    helpLink: { type: String, default: '' },
    tenantName: { type: String, default: '' },
    productName: { type: String, default: '全部系统' },
    userIcon: {
      type: String,
      default:
        'https://myom-release.oss-cn-hangzhou.aliyuncs.com/mysm/resource/workbench/profile.png'
    },
    leftIcons: {
      type: Array,
      default() {
        return ['Workbench', 'Handle']
      }
    },
    userName: { type: String, default: '' },
    currentApp: { type: String, default: '' },
    currentMessageNumber: { type: Number, default: 0 },
    hasNoRightIcon: { type: Boolean, default: true }, // 是否不需要图标
    hasNoLeftIcon: { type: Boolean, default: true }, // 是否不需要图标
    userPanelShowObj: {
      type: Array,
      default() {
        return ['Company', 'Service', 'Organization', 'Manage', 'UserInfo']
      }
    },
    rightIconCustomEvent: {
      type: Array,
      default() {
        return []
      }
    } // 哪些按钮是自定义事件，传值为 ['Notice', 'Message', 'Help']
  },
  data() {
    return {
      currentId: '',
      menuList: [
        { title: '服务管理（ITSM）', icon: 'ITSM', menus: [] },
        { title: '运维管理（ITOM）', icon: 'ITOM', menus: [] },
        { title: '服务运营（ITOA）', icon: 'ITOA', menus: [] }
      ],
      isTenantAdmin: false,
      hasMessage: 0,
      isNoticeRead: false,
      socketMessage: {},
      bizCode: null // 是否显示服务价值报告按钮
    }
  },
  computed: {},
  watch: {
    menuList: {
      immediate: true,
      deep: true,
      handler() {
        this.currentId = this.getCurrentId()
      }
    },
    socketMessage(message) {
      const { socketType } = message
      if (socketType === 'message') {
        this.setMessageNumber()
      }
    },
    currentMessageNumber: {
      handler(currentMessageNumber) {
        this.hasMessage = currentMessageNumber
      }
    },
    userName: {
      deep: true,
      immediate: true,
      handler() {
        this.initWebsock()
        this.setMessageStatus()
        this.getNoticeIsRead()
        this.getCurrentPermission()
        this.isShowDashboardBtn()
      }
    }
  },
  methods: {
    getIsConfirmToShowStop() {
      this.$confirm(`
        <div class="stop-title">停服提醒</div>
        <div class="stop-tips">基于双方服务合作约定，由于贵公司未能及时完成年度运维服务合同的签订，我司已暂停对贵公司使用的明源ERP软件提供任何形式的技术支持服务，如果因此给贵公司造成的不便，敬请谅解！可通过以下两种方式，洽谈后续服务签订事宜：</div>
        <div class="stop-tips">1、联系当地明源分公司与客户成功经理洽谈</div>
        <div class="stop-tips">2、致电明源官方电话：<span class="stop-phone">400-602-7009</span> 咨询</div>
      `, '', {
        type: 'info',
        customClass: 'stop-service-content-new',
        iconClass: 'el-icon-warning-outline',
        showCancelButton: false,
        confirmButtonText: '我知道了',
        dangerouslyUseHTMLString: true,
        closeOnClickModal: false
      })
    },
    // 获取导航信息(包含权限)
    getCurrentPermission() {
      this.isTenantAdmin = false
      const sortArr = {}
      getHeaderPermission().then(({ MenuList, IsTenantAdmin,IsShowExpire }) => {
        // 0正常    1没有租户权限  2没有用户权限
        // 将返回值进行分组
        MenuList.forEach((item) => {
          for (const argumentsKey in item) {
            item[this.firstToLowerCase(argumentsKey)] =
              item[argumentsKey] === '维护计划'
                ? '变更管理'
                : item[argumentsKey]
          }
          if (Object.keys(sortArr).indexOf(item.serviceType) === -1) { sortArr[item.serviceType] = [] }
          sortArr[item.serviceType] = [...sortArr[item.serviceType], item]
        })
        // 将分组后的数据值赋值给指定分类
        this.menuList.forEach((val) => {
          val.menus = sortArr[val.icon.toLocaleLowerCase()]
        })
        const element = document.querySelector('.stop-service-content-new')
        if (IsShowExpire && !element) {
          this.getIsConfirmToShowStop()
        }
        this.isTenantAdmin = !!IsTenantAdmin
        this.$emit('getCurrentMenus', this.menuList)
      })
    },

    // 获取当前导航是否需要显示大屏按钮
    isShowDashboardBtn() {
      isShowDashboardBtn().then((res) => {
        this.bizCode = res || null
      })
    },

    // 将字符串首字母转换成小写
    firstToLowerCase(str) {
      return str.replace(str.charAt(0), str.charAt(0).toLocaleLowerCase())
    },

    initWebsock() {
      const websock = new signalR.HubConnectionBuilder()
        .withUrl(`/v20/mysmws/notify`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .build()

      websock.on('Indicator', function(message) {
        this.socketMessage = { ...message, socketType: 'indicator' }
      })
      websock.on('Message', function(message) {
        this.socketMessage = { ...message, socketType: 'message' }
      })
      websock.start().then(() => {
        setToken('websocketStart', 'websocketStart', true)
      })
    },

    setMessageStatus() {
      getHasNotRead().then((data) => {
        this.hasMessage = data || 0
      })
    },

    setMessageNumber() {
      this.hasMessage = this.hasMessage + 1
    },

    // 获取公告是否已读
    getNoticeIsRead() {
      getNoticeIsRead().then((data) => {
        this.isNoticeRead = !!data
      })
    },

    // 设置公告已读
    setNoticeReaded() {
      setNoticeRead().then(() => {
        this.isNoticeRead = false
      })
    },

    handelOnRightIcon(item) {
      this.$emit('handelOnRightIcon', item)
    },
    getCurrentId() {
      const { menuList, current } = this

      let routes = []
      menuList.forEach((val) => {
        routes = [...routes, ...val.menus]
      })

      return routes.filter((routesItem) => routesItem.label === current)[0]
        ?.appCode
    },
    goToAppUrl(AppCode, RedirectUrl) {
      return `/v20/${BASE_URL.WEB}/frontend/app/go-to-app.svc?AppCode=${AppCode}&MenuCode=001&RedirectUrl=${RedirectUrl}`
    },
    logout() {
      this.$emit('logout')
    },
    getCurrentRoute({ path }, callback) {
      callback(
        `${location.origin}/v20/${
          BASE_URL.WEB
        }/account/user/back-to-workbench.svc?returnUrl=${this.getActiveUrl()}${path}`
      )
    },
    getActiveUrl() {
      return getActiveHttp(
        'https://mysm.fdcyun.com',
        'https://mysm-test.fdcyun.com',
        `http://localhost:7777`,
        false,
        'https://auth-qa.fdcyun.com'
      )
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/variables';
.header-navbar-wrapper {
  width: 100%;
  /*z-index: 100;*/
  justify-content: space-between;
  background-color: $backgroundColor;
  padding: 0 $margin;
  box-sizing: border-box;
  border-bottom: $border;
  position: relative;
  .header-navbar-right {
    height: 51px;
    line-height: 51px;
  }
}
</style>
