import * as signalR from '@microsoft/signalr'
import { setToken } from '@/utils/auth'
const state = {
  websocket: null,
  socketMessage: null
}

const mutations = {
  SET_MESSAGE: (status, socketMessage) => {
    status.socketMessage = socketMessage
  },
  SET_SOCKET: (status, websocket) => {
    status.websocket = websocket
  }
}
const actions = {
  initWebsock({ commit }) {
    const websock = new signalR.HubConnectionBuilder()
      .withUrl(`/v20/mysmws/notify`, { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
      .withAutomaticReconnect()
      .build()

    websock.on('Indicator', function(message) {
      commit('SET_MESSAGE', { ...message, socketType: 'indicator' })
      // console.log('指标：', message)
    })
    websock.on('Message', function(message) {
      commit('SET_MESSAGE', { ...message, socketType: 'message' })
      // console.log('消息：', message)
    })
    websock.start().then(() => {
      setToken('websocketStart', 'websocketStart', true)
      console.log('websocketStart!')
    })
    commit('SET_SOCKET', websock)
  },
  removeFromGroup({ commit }, params) {
    state.websocket.invoke('RemoveFromGroup', params).then((res) => {
      // console.log('主动发消息给socket：', res)
    })
  },
  addToGroup({ commit }, params) {
    state.websocket.invoke('AddToGroup', params).then((res) => {
      // console.log('主动发消息给socket：', res)
    })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
