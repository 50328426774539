<template>
  <!-- <div :class="classObj" class="app-wrapper">
    <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" :is-collapse="true" />
    <div class="main-container max">
      <div :class="{ 'fixed-header': fixedHeader }">
        <NavHeader :title="'MYSM管理后台'" class="admin-nav-heaher" :has-tenant-info="false" @logout="logout" />
      </div>
      <app-main :has-bg-color="true" :has-breadcrumb="hasBreadcrumb">
        <template v-if="!hasOtherNav" slot="headerLine" class="service-header">
          <BorderChange :header-items="headerItems" :active-ind="activeInd" @onSwitchTab="onSwitchTab" />
        </template>
      </app-main>
    </div>
  </div> -->

  <div class="app-wrapper">
    <div v-show="loginReadyStatus" class="fixed-header">
      <NavHeader
        class="admin-nav-heaher"
        :current="'客户360'"
        :current-app="'apphealth'"
        :user-icon="userIcon"
        :has-tenant-info="false"
        :tenant-name="tenantName"
        :product-name="productName"
        :user-name="tenantName"
        :has-message="hasMessage"
        :has-notice="isNoticeRead"
        @logout="logout"
        @openToolGroup="openToolGroup"
        @getCurrentRoute="getCurrentRoute"
      />
    </div>
    <Sidebar class="sidebar-container" :is-collapse="true" />
    <div class="main-container max" :style="{ height }">
      <app-main :has-bg-color="true" :has-breadcrumb="true">
        <!-- <template v-if="!hasOtherNav" v-slot:headerLine class="service-header">
          <BorderChange :header-items="headerItems" :active-ind="activeInd" @onSwitchTab="onSwitchTab" />
        </template> -->
      </app-main>
    </div>
  </div>
</template>
<script>
import NavHeader from '@/layout//NavHeader/HeaderNav/topNav/index.vue'
import { Sidebar, AppMain } from '@/components'
import { apiGetUserInfo } from '@/api/app.js'
// import { NavHeader, Sidebar, AppMain, BorderChange } from '@/components'
// import { activePath } from '@/utils/constants'
// import { mapGetters } from 'vuex'
// import { setWatermark } from '@/utils/watermark'
import { useStore } from 'vuex'
import { getActiveHttp } from '@/components/HeaderNav/topNav/js/constantFun.js'
import { BASE_URL, activePath } from '@/utils/constants'
import { mapGetters } from 'vuex'
export default {
  name: 'Layout',
  components: {
    Sidebar,
    AppMain,
    NavHeader
  },
  data() {
    return {
      height: document.documentElement.clientHeight - 52 + 'px',
      userIcon: '',
      fixedHeader: true,
      tenantName: '',
      // userName: '',
      productName: '全部系统',
      activePath,
      loginReadyStatus: false
    }
  },
  created() {
    this.getUserInfo()
  },
  computed: {
    //
    hasMessage() {
      return this.$store.state.message.hasMessage
    },
    isNoticeRead() {
      console.log(
        'this.$store.state.message.isNoticeRead',
        this.$store.state.message.isNoticeRead
      )
      return this.$store.state.message.isNoticeRead
    },
    ...mapGetters(['userInfo'])
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      // console.log('userInfo', this.$store)
      const vm = this
      const store = useStore()
      apiGetUserInfo()
        .then((res) => {
          res.userIcon = res.thumbAvatar
          this.userIcon = res.thumbAvatar
          // vm.$store.commit('userInfo/SET_USER_INFO', res)
          vm.tenantName = res.tenantName
          store.commit('userInfo/SET_USER_INFO', res)
          store.dispatch('socket/initWebsock', {}, { root: true })
          store.dispatch('message/setMessageStatus', {}, { root: true })
          store.dispatch('message/getNoticeIsRead', {}, { root: true })
          // store.commit('userInfo/SET_LOGINUSER', res);
          // store.commit('userInfo/SET_ROLES', res.UserRole || 'admin');
          this.loginReadyStatus = true
          // console.log('userInfo', vm.$store.state.userInfo.userInfo)
        })
        .catch(() => {
          this.loginReadyStatus = true
        })
    },
    openToolGroup() {
      window.location.href = `${location.origin}/v20/${BASE_URL.WEB}/account/user/back-to-workbench.svc?returnUrl=/tool/all-tool`
    },

    getCurrentRoute({ path }, callback) {
      callback(
        `${location.origin}/v20/${
          BASE_URL.WEB
        }/account/user/back-to-workbench.svc?returnUrl=${this.getActiveUrl()}${path}`
      )
    },
    getActiveUrl() {
      return getActiveHttp(
        'https://mysm.fdcyun.com',
        'https://mysm-test.fdcyun.com',
        `http://localhost:7777`,
        false,
        'https://auth-qa.fdcyun.com'
      )
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      window.location.href = `${this.activePath}/v20/identityserver/account/logout.svc`
    }
  }

  //   components: {
  //     Sidebar, NavHeader, BorderChange, AppMain
  //   },
}
</script>
<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.scss';
.app-wrapper {
  height: 100%;
  line-height: 1.1;
}
.fixed-header {
  position: fixed;
  //   left: $leftMenuMinWidth;
  left: 0;
  top: 0;
  // width: calc(100% - #{$leftMenuMinWidth});
  width: 100%;
  z-index: 2000;
  line-height: 1.15;
  font-size: 13px;
  ::v-deep .table-switch {
    text-align: left !important;
  }
  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #507afe !important;
  }
}
</style>
