<template>
  <div class="cxo-container clearfix">
    <div class="title">CXO业务触达率
      <el-tooltip content="通过评估近30日移动报表和大屏关键用户的应用情况，来分析cxo业务的触达率。" placement="right-start" effect="light">
        <span class="iconfont icon-tishi-wenhao" />
      </el-tooltip>
    </div>
    <div v-if="clickDate && !flagCatch" v-loading="chartLoading" element-loading-text="数据加载中...">
      <div class="clearfix">
        <div class="bar-container">
          <div class="chart-title">CXO业务触达分布</div>
          <div id="systemCxoPie" ref="refCxoPie" style="width: 100%;height: 210px;float:left;" />
        </div>
        <div class="bar-container">
          <div class="chart-title">CXO报表使用分布</div>
          <div id="systemCxoBar" ref="refCxoBar" style="width: 100%;height: 210px;float:left;" />
          <Empty v-if="noData" description="暂无访问数据" />
        </div>
      </div>
      <div class="analysis">
        <div class="analysis-title">分析建议</div>
        <div id="contentCxo" class="content content-anylysis" />
      </div>
    </div>
    <Empty v-if="flagCatch" description="暂无访问数据" />
  </div>

</template>
<script>
import { Empty } from '@/components'
import { markRaw } from 'vue'
import resize from '@/views/components/charts/mixins/resize'
import { pieOption, barOption2, utilsDestroyedChart } from '@/views/components/charts/options'
import { utilsGoToApp } from '@/utils/gotoWeb.js'
export default {
  components: { Empty },
  mixins: [resize],
  props: {
    dataChartCxo: {
      type: Object,
      default: function() {
        return {}
      }
    },
    clickDate: { // 点击跳转日期
      type: String,
      default: ''
    },
    flagCatch: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    chartLoading: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      systemCxoPie: null,
      systemCxoBar: null,
      noData: false
    }
  },
  watch: {
    dataChartCxo() {
      this.chartSet1()
      this.chartSet2()
    }
  },
  mounted() {
    if (this.clickDate && !flagCatch) {
      this.chartSet1()
      this.chartSet2()
    }
  },
  beforeUnmount() {
    utilsDestroyedChart(this, 'systemCxoPie')
    utilsDestroyedChart(this, 'systemCxoBar')
  },
  methods: {
    chartSet1() {
    // 1.饼图
      const vm = this
      // this.systemCxoPie = markRaw(this.$echarts.init(document.getElementById('systemCxoPie')))
      this.systemCxoPie = markRaw(this.$echarts.init(this.$refs.refCxoPie))
      const { summary, aiSuggestion } = this.dataChartCxo
      document.getElementById('contentCxo').innerHTML = aiSuggestion
      let { value, value2 } = summary
      // value = null
      // value2 = null
      if (!value)value = 0
      if (!value2)value2 = 0
      const seriesData = [value, value2]

      const dataTitle = ['月触达CXO数', '月未触达CXO数']
      // 1.饼图
      this.systemCxoPie.setOption(pieOption({ title: 'CXO业务触达分布', seriesData, dataTitle }))
      this.systemCxoPie.off('click')
      this.systemCxoPie.on('click', () => utilsGoToApp(vm, 'CXO触达', vm.clickDate))
    },
    chartSet2() {
      // 2.柱状图
      const vm = this
      const { details } = this.dataChartCxo
      const xData = []
      const yData = []
      const yData2 = []
      details.forEach(item => {
        const { title, value, value2 } = item
        xData.push(title)
        yData.push(value)
        yData2.push(value2)
      })
      // yData = [null, null]
      // yData2 = [null, null]
      let num = yData.length
      let num2 = yData2.length
      yData.forEach(y => {
        if (!y) num--
      })
      yData2.forEach(y2 => {
        if (!y2) num2--
      })
      if (num === 0 && num2 === 0) {
        utilsDestroyedChart(this, 'systemCxoBar')
        this.noData = true
      } else {
        this.noData = false
        const titleArr = ['CXO用户数', '访问次数']
        // this.systemCxoBar = markRaw(this.$echarts.init(document.getElementById('systemCxoBar')))
        this.systemCxoBar = markRaw(this.$echarts.init(this.$refs.refCxoBar))
        this.systemCxoBar.setOption(barOption2({ titleArr, xData, yData, yData2 }))
        this.systemCxoBar.off('click')
        this.systemCxoBar.on('click', () => utilsGoToApp(vm, 'CXO触达', vm.clickDate))
      }
    }
  }

}
</script>
