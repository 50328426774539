/**
 * 获取当前环境的域名
 * @param prod 生产
 * @param test 测试
 * @param dev 开发环境
 * @param isWithoutEdn  是否需要拼劲泛解析域名
 * @returns {string}
 */
export function getActiveHttp(prod, test, dev, isWithoutEdn) {
  const nodeEnv = process.env.VUE_APP_ACTIVE_ENV_STATUS // staging表示测试环境

  const href = window.location.host
  const testEnv = href.match('-test')
  let activePath = prod // 默认前台生产

  if (nodeEnv !== 'development') {
    // 如果不是开发环境
    if (testEnv && testEnv.length > 0) {
      // 如果是测试环境
      activePath = test
    }
  } else {
    // 开发环境
    activePath = dev
  }
  // 拼接泛域名
  if (!isWithoutEdn) {
    activePath = addExtensionDomainName(activePath, getExtensionDomainName())
  }
  return activePath
}

/** *
 * 拼接泛域名
 * @param target 例如http://mysm.fdcyun.com
 * @param edn
 * @returns {string}
 */
export function addExtensionDomainName(target, edn) {
  const arrUrl = target.split('//')
  if (arrUrl.length < 2) {
    return arrUrl
  }
  if (edn) {
    return arrUrl[0] + '//' + edn + '.' + arrUrl[1]
  }
  return target
}

export function getExtensionDomainName(url) {
  url = url || window.location.href
  let count = 3
  // 兼容本地调试可能直接用localhost的情况
  if (url.indexOf('localhost') !== -1) {
    count = 1
  }
  const reg1 = new RegExp('[^.\\/]*(\\.[^.\\/]*){' + count + '}', 'g')
  url = url.match(reg1)
  if (url) {
    const reg2 = new RegExp('(\\.[^.\\/]*){' + count + '}', 'g')
    url = url[0].replace(reg2, '')
    return url
  }
  return ''
}

// export function utilsGetUrl() {
//   const href = window.location.host;
//   const testEnv = href.match('-test');
//   if (!testEnv) {
//     //生产环境
//     return 'https://'+'bjzgjm.'+'mysm.fdcyun.com';
//   }
//   return 'https://mysm-test.fdcyun.com';
// }
