// import { createStore } from 'vuex'

// export default createStore({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
import state from './state'
import mutations from './mutations'
import actions from './state'
import getters from './getters'
import { createStore, createLogger } from 'vuex'
// import createLogger from 'vuex/dist/logger';
import userInfo from './userInfo'
import user from './user'
import message from './message'
import socket from './socket'
const debug = process.env.NODE_ENV !== 'production'
export default createStore({
  state,
  mutations,
  actions,
  getters,
  plugins: debug ? [createLogger()] : [],
  modules: {
    userInfo,
    user,
    message,
    socket
  }
})
