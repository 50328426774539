export const loginUrl = () => {
  // var location = `${window.location.protocol}//${window.location.host}`
  // let params = `${encodeURIComponent(`/ai-report/#${router}?`)}`
  // var url = `${location}?url=${encodeURIComponent(`/monitoring/log/log-query?url=${params}`)}`
  // console.log('-------------------------------logout', url)
  // return url
  return window.location.href.indexOf('360d-test.') === -1 ? 'https://mysm.fdcyun.com/' : 'https://mysm-test.fdcyun.com/'
}
// export function loginTips(tips, store) {
export function loginTips(tips) {
  console.log('process.env', process.env.NODE_ENV)
  // 弹出窗口根据环境选择一个库，确定以后返回一个token,将token写入cookie。
  tips(
    '您未登陆或登陆已超期，请重新登陆！',
    '登陆提醒',
    {
      confirmButtonText: '重新登录',
      showCancelButton: false,
      type: 'warning',
      customClass: 'confirm-warning login-warning'
    }
  ).then(e => {
    if (e) {
      // store.dispatch('common/resetToken').then(() => {
      window.location.replace(
        loginUrl(window.location.href.split('/#')[1])
      )
      // })
    }
  })
}
