// import Layout from '@/layout'
// import { navRoutes } from '@/router/sidebarRoutes'
// export const routes= [
//     {
//       path: '/',
//       name: 'Index',
//       component: () => import(/* webpackChunkName: "about" */ '../views/general/index.vue')
//     },
//     {
//       path: '/list',
//       name: 'List',
//       // route level code-splitting
//       // this generates a separate chunk (about.[hash].js) for this route
//       // which is lazy-loaded when the route is visited.
//       component: () => import(/* webpackChunkName: "about" */ '../views/list/index.vue')
//     }
//   ]
import Layout from '@/layout'
import { navRoutes } from '@/router/sidebarRoutes'
import { success } from '@/router/success'
export const routes = [
  ...navRoutes(Layout),
  ...success(Layout),
  {
    path: '/404',
    name: '404',
    component: () => import('@/components/pages/error'),
    hidden: true
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
    hidden: true
  },
  {
    path: '/downloading',
    name: 'downloading',
    component: () => import('@/views/success/downloading'),
    hidden: true,
    meta: {
      title: '文件准备中...'
    }
  }
]
