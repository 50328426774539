import request from '../utils/request'
/**
 * 读取公告是否已读
 * @param params
 */
export function getNoticeIsRead(params) {
  return request({
    url: `/frontend/announcement/has-unread.svc`,
    method: 'get',
    params
  })
}

/**
 * 设置公告已读
 * @param params
 */
export function setNoticeRead(params) {
  return request({
    url: `/frontend/announcement/set-read.svc`,
    method: 'get',
    params
  })
}

/**
 * 获取公告列表
 * @param data
 */
export function getNoticeLists(data) {
  return request({
    url: `/frontend/announcement/list.svc`,
    method: 'post',
    data
  })
}
