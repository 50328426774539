<template>
  <div
    :class="['right-nav ', isSafariStyle() ? ' safari-style' : ' chrome-style']"
  >
    <template v-if="hasNoRightIcon">
      <div class="dropdown" @mouseenter="showTenant" @mouseleave="hideTenant">
        {{ tenantName }}
        <span class="el-icon-arrow-down" />
        <div v-show="tenantShow" class="panel tenant">
          <li class="item" :title="tenantName">{{ tenantName }}</li>
        </div>
      </div>
      <div
        v-if="productName"
        class="dropdown"
        @mouseenter="showProduct"
        @mouseleave="hideTenant"
      >
        {{ productName }}
        <span class="el-icon-arrow-down" />
        <div v-show="productShow" class="panel product">
          <li class="item">{{ productName }}</li>
        </div>
      </div>
      <el-link
        :underline="false"
        :href="
          currentApp === 'web'
            ? null
            : `/v20/${
              BASE_URL.WEB
            }/account/user/back-to-workbench.svc?returnUrl=${getActiveUrl()}/tool/all-tool?current=${currentId}`
        "
        class="toolbar"
        @click="goToTool"
      >
        <svg-icon icon-class="toolbar" class="toolbar-icon" />
        工具集
      </el-link>
      <div class="header-nav-icon clearfix">
        <template v-for="(handleItem, handleIndex) in handleItems">
          <!--    写两个是为了非当服务云前台下，其他工具做跳转的时候为了防止页面被拦截，采用的a标签让用户主动点击    -->
          <router-link
            v-if="currentApp === 'web' && handleItem.routeName !== 'Notice'"
            :key="'webNotice' + handleIndex"
            :to="{ name: handleItem.routeName }"
            class="nv-handle-icon"
            :class="[
              { [handleItem.icon]: handleItem.routeName !== 'Message' },
              { iconfont: handleItem.isIconfont },
            ]"
            @mouseenter.native.stop="onMouseEnter(handleItem, $event)"
            @mouseleave.native.stop="onMouseLeave(handleItem, $event)"
          >
            <el-badge
              v-if="handleItem.routeName === 'Message'"
              :is-dot="isDot"
              :hidden="hasMessage <= 0"
              :value="hasMessage"
              :max="999"
            >
              <svg-icon :icon-class="handleItem.icon" />
            </el-badge>
            <template v-else>
              <svg-icon
                v-if="handleItem.routeName !== 'Notice'"
                :icon-class="handleItem.icon"
              />
              <el-badge v-else :is-dot="true" :hidden="!hasNotice">
                <em
                  class="iconfont"
                  :class="handleItem.icon"
                  @click="onShowNotice"
                />
              </el-badge>
            </template>
          </router-link>
          <el-link
            v-else
            :key="handleIndex"
            :underline="false"
            :href="
              handleItem.routeName === 'Notice'
                ? null
                : `${getCurrentRoute(handleItem)}?current=${currentId}` || null
            "
            class="nv-handle-icon"
            :class="[
              {
                [handleItem.icon]:
                  handleItem.routeName !== 'Message' &&
                  handleItem.routeName !== 'Notice',
              },
              { iconfont: handleItem.isIconfont },
            ]"
            @mouseenter.native.stop="onMouseEnter(handleItem, $event)"
            @mouseleave.native.stop="onMouseLeave(handleItem, $event)"
          >
            <el-badge
              v-if="handleItem.routeName === 'Message'"
              :is-dot="isDot"
              :hidden="hasMessage <= 0"
              :value="hasMessage"
              :max="999"
            >
              <svg-icon :icon-class="handleItem.icon" />
            </el-badge>
            <template v-else>
              <svg-icon
                v-if="handleItem.routeName !== 'Notice'"
                :icon-class="handleItem.icon"
              />
              <el-badge v-else :is-dot="true" :hidden="!hasNotice">
                <em
                  class="iconfont"
                  :class="handleItem.icon"
                  @click="onShowNotice"
                />
              </el-badge>
            </template>
          </el-link>
        </template>
      </div>
    </template>
    <slot />
    <div class="userinfo-wrapper">
      <el-avatar
        class="user-avatar"
        :size="30"
        :src="userIcon || defaultIcon"
      />
      <ul class="user-handle-container">
        <el-menu class="user-handle-bottom">
          <el-sub-menu index="1">
            <template #title>
              <span><em class="iconfont handle-items-icon icon-qiyeqiye" /><span
                class="handle-item-text"
              >企业</span></span>
            </template>
            <el-menu-item
              v-if="userPanelShowObj.includes('Service')"
              index="1-1"
              @click="goToPage('Service')"
            >
              <el-link
                :underline="false"
                :href="
                  currentApp !== 'web'
                    ? goToPage('Service', '/service/info')
                    : null
                "
              >服务总览</el-link></el-menu-item>

            <el-menu-item
              v-if="userPanelShowObj.includes('Organization')"
              index="1-2"
              @click="goToPage('Organization')"
            >
              <el-link
                :underline="false"
                :href="
                  currentApp !== 'web'
                    ? goToPage('Organization', '/organization/list')
                    : null
                "
              >组织架构</el-link>
            </el-menu-item>
            <el-menu-item
              v-if="userPanelShowObj.includes('Manage')"
              index="1-3"
              @click="goToPage('Manage')"
            >
              <el-link
                :underline="false"
                :href="
                  currentApp !== 'web'
                    ? goToPage('Manage', '/web-service/manage/organize')
                    : null
                "
              >服务云管理</el-link>
            </el-menu-item>
          </el-sub-menu>
          <el-menu-item
            v-if="userPanelShowObj.includes('UserInfo')"
            index="2"
            class="user-handle-item"
            @click="goToPage('UserInfo')"
          >
            <template #title>
              <el-link
                :underline="false"
                :href="
                  currentApp !== 'web'
                    ? goToPage('UserInfo', '/settings/user-info')
                    : null
                "
              >
                <em class="icon-userinfo iconfont handle-items-icon" /><span
                  class="handle-item-text"
                >个人信息</span>
              </el-link>
            </template>
          </el-menu-item>
        </el-menu>
        <li class="handle-items" @click="logout">
          <!--          <svg-icon class="handle-items-icon" icon-class="logout" />-->
          <em class="icon-logout iconfont handle-items-icon" />
          <span class="handle-item-text">退出</span>
        </li>
      </ul>
    </div>
    <!-- <NoticePanel :visible="visible"></NoticePanel> -->
    <NoticePanel :visible="visible" @updateVisible="updateVisible" />
  </div>
</template>
<script>
import defaultIcon from '../icon/profile.png'
import { BASE_URL } from '@/utils/constants'
import { getActiveHttp } from '@/components/HeaderNav/topNav/js/constantFun.js'
// import { SvgIcon } from '@/components'
import NoticePanel from '../../../notice/notice.vue'

export default {
  name: 'Right',
  components: {
    NoticePanel
    // NoticePanel: (resolve) => require(['../../../notice/notice'], resolve),
  },
  props: {
    current: { type: String, default: '' },
    tenantName: { type: String, default: '暂无租户' },
    productName: { type: String, default: '暂无产品' },
    userIcon: { type: String, require: true, default: defaultIcon },
    userName: { type: String, default: '暂无用户名称' },
    currentApp: { type: String, default: '' },
    currentId: { type: String, required: '' }, // 当前工具alias
    hasMessage: { type: Number, default: 0 },
    hasNoRightIcon: { type: Boolean, default: true }, // 是否不需要图标
    userPanelShowObj: {
      type: Array,
      default() {
        return ['Company', 'Service', 'Organization', 'Manage', 'UserInfo']
      }
    },
    hasNotice: { type: Boolean, default: false } // 是否有公告
  },
  data() {
    return {
      BASE_URL,
      defaultIcon,
      visible: false,
      tenantShow: false,
      productShow: false,
      currentAppId: '',
      handleItems: [
        { label: '设置', routeName: 'Notice', path: '', icon: 'icon-gonggao1' },
        {
          label: '消息',
          routeName: 'Message',
          path: '/message/list',
          icon: 'notice_icon'
        },
        {
          label: '帮助',
          routeName: 'Help',
          path: '/help/information',
          icon: 'help_icon',
          isIconfont: true
        }
      ],
      userItems: [
        {
          label: '企业',
          children: [
            { label: '服务总览', routerName: 'Service', path: '/service/info' },
            {
              label: '组织架构',
              routerName: 'Organization',
              path: '/organization/list'
            },
            {
              label: '服务云管理',
              routerName: 'Manage',
              path: '/web-service/manage/organize'
            }
          ]
        },
        {
          label: '个人信息',
          routerName: 'UserInfo',
          path: '/settings/user-info'
        }
      ],
      isDot: true //
    }
  },
  computed: {},
  mounted() {
    window.addEventListener('click', this.hidePanel)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.hidePanel)
  },
  methods: {
    isSafariStyle() {
      return !(
        /chrome/.test(navigator.userAgent.toLowerCase()) &&
        /win/.test(navigator.userAgent.toLowerCase())
      )
    },
    goToPage(name, path) {
      let url = ''
      if (this.currentApp === 'web') {
        if (this.$route.name !== name) this.$router.push({ name })
      } else {
        url = `${location.origin}/v20/${
          BASE_URL.WEB
        }/account/user/back-to-workbench.svc?returnUrl=${this.getActiveUrl()}${path}?current=${
          this.currentId
        }`
      }
      return url
    },
    getActiveUrl() {
      return getActiveHttp(
        'https://mysm.fdcyun.com',
        'https://mysm-test.fdcyun.com',
        `http://localhost:7777`,
        false,
        'https://auth-qa.fdcyun.com'
      )
    },
    //
    getCurrentRoute(handleItem) {
      let url = null
      this.$emit('getCurrentRoute', handleItem, (currentUrl) => {
        url = currentUrl
      })
      return url
    },
    onShowNotice() {
      this.visible = true
      this.$store.dispatch('message/setNoticeReaded')
    },
    updateVisible(visible) {
      this.visible = visible
    },
    onMouseEnter({ routeName }) {
      if (routeName === 'Message') this.isDot = false
    },
    onMouseLeave() {
      this.isDot = true
    },
    showTenant() {
      this.tenantShow = true
      this.productShow = false
    },
    hideTenant() {
      this.tenantShow = false
      this.productShow = false
    },
    showProduct() {
      this.productShow = true
      this.tenantShow = false
    },
    goToTool() {
      // this.$router.push({ path: '/tool/all-tool' });
    },
    openToolGroup() {
      this.$emit('openToolGroup')
    },
    hidePanel() {
      this.tenantShow = false
      this.productShow = false
    },
    logout() {
      this.$emit('logout')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../style/variables';
.right-nav {
  display: flex;
  z-index: 2000;
  .dropdown {
    margin-right: $margin;
    cursor: pointer;
    font-size: 14px;
    color: #222222;

    span {
      margin-left: 4px;
    }
    .panel {
      position: absolute;
      top: $navBarHeight - 8px;
      width: 100px;
      background-color: $backgroundColor;
      box-shadow: $panelBoxShadow;
      .item {
        height: $panelListHeight;
        line-height: $panelListHeight;
        padding: 0 $padding;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
        &:hover {
          background-color: $hoverBGColor2;
        }
      }
    }
  }
}
.toolbar {
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  color: #222222 !important;
  &-icon {
    width: 18px;
    height: 18px;
    vertical-align: -3px;
  }
  &:hover {
    color: $themeColor !important;
  }
  &::before,
  &::after {
    content: '';
    width: 1px;
    height: 30%;
    background-color: #e9ebee;
  }
  &::before {
    margin-right: $margin;
  }
  &::after {
    margin-left: $margin;
  }
}
::v-deep .el-badge__content.is-fixed {
  right: auto;
}
.header-nav-icon {
  position: relative;
  // transform: translateY(-1px);
  left: 0px;
  .nv-handle-icon {
    font-size: 16px;
    margin-left: 12px;
    color: #919fb4;
    font-weight: 500;
    cursor: pointer;
    display: block;
    float: left;
    // vertical-align: middle;
    // height: 52px;
    // line-height: 52px;
    // &:first-of-type {
    //   margin-top: 0px;
    // }

    &:hover {
      // color: $themeHoverColor;
      color: #919fb4;
    }
    ::v-deep .el-link--inner {
      height: 51px;
    }
    ::v-deep .el-badge {
      height: 51px;
      vertical-align: unset;
      z-index: 2;
      .el-badge__content.is-fixed {
        // line-height: 16px;
        // top: 15px;
        z-index: 1;
        transform: scale(1) !important;
        top: 6px !important;
        left: 6px !important;
        &.is-dot {
          top: 11px !important;
          left: 11px !important;
        }
      }
    }
  }
}
.chrome-style {
  .header-nav-icon .nv-handle-icon:nth-child(3) {
    ::v-deep .el-link--inner {
      top: 1px;
      position: relative;
    }
  }
}
.userinfo-wrapper {
  cursor: pointer;
  position: relative;
  height: 100%;
  padding-top: 9px;
  margin-left: $margin;
  .user-handle-container {
    width: 150px;
    display: none;
    position: absolute;
    top: $navBarHeight;
    right: -$margin;
    background-color: $backgroundColor;
    border: 1px solid $borderColorTable;
    z-index: 3004;
    .handle-items {
      height: 42px;
      line-height: 42px;
      // padding: 0px $padding;
      &:hover {
        background-color: $hoverBGColor;
      }
      .user-avatar {
        margin-right: 8px;
      }
      &:first-of-type {
        border-top: 1px solid $borderColor;
        padding-left: 12px;
      }
    }
    ::v-deep .el-menu-item {
      padding-left: 40px !important;
    }
    ::v-deep .el-sub-menu__title,
    ::v-deep .el-submenu__title {
      padding-left: 12px !important;
    }

    ::v-deep .el-sub-menu__title,
    ::v-deep .el-submenu__title,
    ::v-deep .el-menu-item {
      height: 40px;
      line-height: 40px;
      .el-link {
        display: block;
        width: 100%;
        height: 100%;
        font-weight: normal;
      }
      &.user-handle-item {
        padding-left: 12px !important;
      }
    }
  }
  .handle-items-icon {
    color: rgba(184, 184, 184, 1);
    margin-right: 13px;
  }
  &:hover {
    .user-handle-container {
      display: block;
    }
  }
}
.user-item {
  font-size: 14px;
  position: relative;
  vertical-align: -8px;
  width: calc(100% - 44px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.user-logout {
    cursor: pointer;
  }
}

// .el-link.:hover {
//   color: #507afe;
// }
</style>
