<template>
  <DialogMy
    :visible="visible"
    :confirm-text="'确 定'"
    :min-width="'660px'"
    :top="'5vh'"
    :width="`660px`"
    :height="'80vh'"
    :body-no-scroll="true"
    :has-footer="false"
    @onCancelHandle="onCancelHandle"
    @onConfirmHandle="onConfirmHandle"
  >
    <template v-if="visible" #titleCustom>
      <TableSwitch
        :active-ind="activeInd"
        :header-items="headerItems"
        :text-align="'left'"
        @onSwitchTab="onSwitchTab"
        @updateActiveInd="updateActiveInd"
      />
    </template>
    <template v-if="visible" #bodyNoScroll>
      <div
        v-loading="loadingContent"
        class="notice-scroll_content"
        element-loading-text="加载中，请稍后..."
        :style="{ height: `calc( 80vh - 150px )` }"
      >
        <NoticeItems
          :loading="loading"
          :notice-data="noticeData"
          @loadMoreData="loadMoreData"
        />
        <Empty
          v-if="noticeData.length <= 0"
          is-admin
          :description="'暂无公告'"
        />
      </div>
    </template>
  </DialogMy>
</template>

<script>
import { getNoticeLists } from '../../api/notice'
// import Dialog from '../Dialog/index';
import DialogMy from '@/components/DialogMy/index.vue'
import TableSwitch from '../TableSwitch/index'
import Empty from '../Empty/index'
import NoticeItems from './noticeItems'
export default {
  name: 'Notice',
  components: {
    // Dialog,
    DialogMy,
    TableSwitch,
    Empty,
    NoticeItems
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headerItems: [
        { Name: '所有公告', Type: null },
        { Name: '更新公告', Type: 0 },
        { Name: '平台公告', Type: 1 }
      ],
      activeInd: 0,
      loadingContent: false,
      loading: false,
      noticeParams: {
        page: 1,
        size: 5,
        Type: null
      },
      noticeData: []
    }
  },
  computed: {},
  watch: {
    visible(isShow) {
      if (isShow) {
        this.getNoticeLists()
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    updateActiveInd(activeInd) {
      this.activeInd = activeInd
    },
    // 搜索公告
    onSwitchTab({ Type }) {
      this.noticeParams.Type = Type
      this.noticeParams.page = 1
      this.noticeData = this.$options.data().noticeData
      this.getNoticeLists()
    },

    loadMoreData() {
      if (!this.noMore) {
        this.noticeParams.page = this.noticeParams.page + 1
        this.getNoticeLists()
      }
    },

    // 获取公告
    getNoticeLists() {
      this.loadingContent = true
      this.loading = true
      getNoticeLists(this.noticeParams)
        .then(({ data, count }) => {
          const { size } = this.noticeParams
          const maxPage = Math.ceil(count / size)
          this.noMore = !data || data?.length <= 0 || maxPage <= 1
          if (this.noMore) this.noticeParams.page = maxPage
          this.noticeData = [...this.noticeData, ...data]
        })
        .finally(() => {
          this.loadingContent = false
          this.loading = false
        })
    },
    onCancelHandle() {
      this.$emit('updateVisible', false)
    },
    onConfirmHandle() {
      this.$emit('updateVisible', false)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding-right: 10px;
}
::v-deep .el-dialog__header {
  font-size: 14px;
  font-weight: normal;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
::v-deep .el-dialog__headerbtn {
  top: 12px;
}
::v-deep .table-switch {
  text-align: left;
}
::v-deep .table-switch_item {
  font-weight: normal;
}
.notice-scroll_content {
  position: relative;
}
</style>
