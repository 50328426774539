import request from './index'

// 获取年份
export function getYears(data) {
  return request({
    url: `/api/cancerwebapp/customersuccess/years.svc`,
    method: 'post',
    data
  })
}

// 获取计划项
export function getPlanItems(params) {
  return request({
    url: `/api/cancerwebapp/customersuccess/get-plan-items.svc`,
    method: 'get',
    params
  })
}

// 获取计划详情
export function getPlanDetail(data) {
  return request({
    url: `/api/cancerwebapp/customersuccess/get-plan-detail.svc`,
    method: 'post',
    data
  })
}

// 获取交付物列表
export function getDeliverablesList(data) {
  return request({
    url: `/api/cancerwebapp/customersuccess/get-deliverables-list.svc`,
    method: 'post',
    data
  })
}


// 批量下载
export function downloadAll(params, cb) {
  return request({
    url: `/api/cancerwebapp/customersuccess/download-item-deliverables.svc`,
    method: 'get',
    params,
    onDownloadProgress: function(event){ // 下载进度监听
      console.log(event);
      cb(event)
      if(event.loaded===event.total){
        // 下载完成 loading = true
      }
    },
    timeout: 900000
  })
}



// 获取详情
export function getDetails(params) {
  return request({
    url: `/api/cancerwebapp/customersuccess/get-plan-item-detail.svc`,
    method: 'get',
    params
  })
}
