import { goToApp, fetchGrayTenantList } from '@/api/goto.js'

export function utilsGoToApp(vm, from, date) {
  goToApp().then(token => {
    const { entCode } = vm.$store.state.userInfo.userInfo
    const _entCode = entCode ? (entCode + '.') : ''
    const _host = window.location.host.indexOf('-test') > -1 ? 'mysm-test.fdcyun.com' : 'mysm.fdcyun.com'

    getUrlParams(from, date, vm.$store.state.userInfo.userInfo.tenantId).then(res => {
      const { appcode, menucode, redirecturl } = res

      const endUrl = `/v20/mysm/frontend/app/go-to-app.svc?` + `AppCode=${appcode}&MenuCode=${menucode}&RedirectUrl=${encodeURIComponent(redirecturl)}`
      const url = `https://${_entCode}${_host}/v20/mysm/account/user/login-from-identityserver.svc?` +
          `token=${token}&redirecturl=${encodeURIComponent(endUrl)}`

      window.open(url)
    })
    // const { appcode, menucode, redirecturl } = getUrlParams(from, date, vm.$store.state.userInfo.userInfo.tenantId)
  }).catch(() => {})
}

export function getUrlParams(from, date, tenantId) {
  return fetchGrayTenantList().then(res => {
    let isGray = false
    if (res.includes(tenantId)) {
      isGray = true
    }
    let appcode = ''
    let menucode = '001'
    let redirecturl = '/'
    const _date = date ? utilsOneDay(date) : ''
    switch (from) {
      case '三高一关键':// 穿透到：云监控-系统总览
        appcode = 'ywmonitor'
        menucode = '001'
        redirecturl = `/web20/system?date=${_date}`
        break
      case '系统活跃度':// 穿透到：用户行为分析-活跃用户
        appcode = 'userBehaviorAnalysis'
        menucode = '001'
        redirecturl = `/index?date=${_date}`
        break
      case '应用质量':// 穿透到：应用巡检-巡检桌面
        appcode = 'inspection'
        menucode = '001'
        redirecturl = `/appins/desktop?date=${_date}`
        break
      case '系统故障率':// 穿透到：工单工具-工具总览
        appcode = 'ticket'
        menucode = '001'
        // redirecturl = `/overview?date=${_date}`
        redirecturl = `/overview?startDate=${_date}&endDate=${_date}&timeType=daterange&ticketType=fault`
        break
      case 'CXO触达':// 穿透到：用户行为分析-CXO应用
        appcode = 'userBehaviorAnalysis'
        menucode = '001'
        redirecturl = `/cxo?date=${_date}`
        break
      default:break
    }
    return { appcode, menucode, redirecturl }
  })
}

export function utilsOneDay(day) {
  const today = new Date(day)
  const targetday_milliseconds = today.getTime() - 1000 * 60 * 60 * 24
  today.setTime(targetday_milliseconds) // 注意，这行是关键代码
  let currentDate = ''
  const tYear = today.getFullYear()
  let tMonth = today.getMonth()
  const tDate = today.getDate()
  tMonth = tMonth + 1
  currentDate += tYear + '-'
  if (tMonth >= 10) {
    currentDate += tMonth + '-'
  } else {
    currentDate += '0' + tMonth + '-'
  }
  if (tDate >= 10) {
    currentDate += tDate
  } else {
    currentDate += '0' + tDate
  }
  return currentDate
}

export function utilsAddOneDay(day) {
  const today = new Date(day)
  const targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24
  today.setTime(targetday_milliseconds) // 注意，这行是关键代码
  let currentDate = ''
  const tYear = today.getFullYear()
  let tMonth = today.getMonth()
  const tDate = today.getDate()
  tMonth = tMonth + 1
  currentDate += tYear + '-'
  if (tMonth >= 10) {
    currentDate += tMonth + '-'
  } else {
    currentDate += '0' + tMonth + '-'
  }
  if (tDate >= 10) {
    currentDate += tDate
  } else {
    currentDate += '0' + tDate
  }
  return currentDate
}
