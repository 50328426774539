<template>
  <div class="header-navbar-wrapper">
    <Left
      :menu-list="menuList"
      :current-app="currentApp"
      :current="current"
      :current-id="currentId"
      :left-icons="leftIcons"
      :has-no-left-icon="hasNoLeftIcon"
    />
    <Right
      class="header-navbar-right"
      :tenant-name="tenantName"
      :product-name="productName"
      :user-name="userName"
      :current-id="currentId"
      :user-icon="userIcon"
      :current="current"
      :current-app="currentApp"
      :has-message="hasMessage"
      :has-notice="hasNotice"
      :has-no-right-icon="hasNoRightIcon"
      :user-panel-show-obj="userPanelShowObj"
      @logout="logout"
      @openToolGroup="openToolGroup"
      @getCurrentRoute="getCurrentRoute"
    >
      <slot />
    </Right>
  </div>
</template>

<script>
import Left from './components/left'
import Right from './components/right'
import defaultIcon from './icon/profile.png'
import { BASE_URL } from '@/utils/constants'

// import { getActiveHttp } from '@/utils'
// const location = `${window.location.protocol}//${window.location.host}`
export default {
  name: 'HeaderNav',
  components: { Left, Right },
  props: {
    current: { type: String, default: '' },
    tenantName: { type: String, default: '' },
    productName: { type: String, default: '' },
    userIcon: { type: String, default: defaultIcon },
    leftIcons: {
      type: Array,
      default() {
        return ['Workbench', 'Handle']
      }
    },
    userName: { type: String, default: '' },
    currentApp: { type: String, default: '' },
    hasMessage: { type: Number, default: 0 },
    hasNotice: { type: Boolean, default: false }, // 是否有公告
    hasNoRightIcon: { type: Boolean, default: true }, // 是否不需要图标
    hasNoLeftIcon: { type: Boolean, default: true }, // 是否不需要图标
    userPanelShowObj: {
      type: Array,
      default() {
        return ['Company', 'Service', 'Organization', 'Manage', 'UserInfo']
      }
    }
  },
  data() {
    return {
      currentId: '',
      menuList: [
        {
          title: '服务管理（ITSM）',
          icon: '',
          menus: [
            {
              label: '工单管理',
              alias: 'gdgl',
              description: '多维度明源云客服工单信息',
              url: this.goToAppUrl('ticket', '/'),
              icon: ''
            },
            {
              label: '客服机器人',
              alias: 'kfjqr',
              description: '在线自助问题解决',
              url: '',
              icon: ''
            },
            {
              label: '知识库',
              alias: 'zsk',
              description: '知识沉淀与知识学习',
              url: '',
              icon: ''
            },
            {
              label: '在线视频',
              alias: 'zxsp',
              description: '视频知识在线学习',
              url: '',
              icon: ''
            }
          ]
        },
        {
          title: '运维管理（ITOM）',
          multiColumn: true,
          menus: [
            {
              label: '安装部署',
              alias: 'azbs',
              description: '容器化安装部署工具',
              url: this.goToAppUrl('container_cloud', '/'),
              icon: ''
            },
            {
              label: '数据容灾',
              alias: 'sjrz',
              description: '混合云数据容灾解决方案',
              url: this.goToAppUrl('cloudbackup', '/'),
              icon: ''
            },
            {
              label: '智能监控',
              alias: 'znjk',
              icon: '',
              description: '全栈式实时采集监控工具',
              url: this.goToAppUrl('ywmonitor', '/web20/system/overview')
            },
            {
              label: '智能告警',
              alias: 'zngj',
              icon: '',
              description: '融入AIOps实践的告警工具',
              url: this.goToAppUrl('ywmonitor', '/web20/warn/center')
            },
            {
              label: '变更管理',
              alias: 'yjfk',
              icon: '',
              description: '系统变更风险全流程管控',
              url: this.goToAppUrl('maintenanceplan', '/')
            },
            {
              label: '补丁管理',
              alias: 'cpbd',
              icon: '',
              description: '已知风险、漏洞识别，更新提醒',
              url: this.goToAppUrl('productpatch', '/version')
            },
            {
              label: '配置管理',
              alias: 'pzgl',
              description: '对象配置信息及关系维护工具',
              url: '',
              icon: ''
            },
            {
              label: '容量与性能管理',
              alias: 'nlyxngl',
              description: '确保各项服务达到预期的性能水平',
              url: '',
              icon: ''
            },
            {
              label: '安全管理',
              alias: 'aqgl',
              icon: '',
              description: '系统安全风险全面诊断与提升',
              url: ''
            },
            {
              label: '自动化运维中心',
              alias: 'zdhywzx',
              description: '批量化脚本处理工具',
              icon: '',
              url: ''
            }
          ]
        },
        {
          title: '服务运营（ITOA）',
          menus: [
            {
              label: '智能巡检',
              alias: 'znxj',
              icon: '',
              description: '应用底线、数据质量全面诊断',
              url: this.goToAppUrl('inspection', '/')
            },
            {
              label: '用户行为分析',
              alias: 'yhxwfx',
              icon: '',
              description: '多维度用户访问情况分析',
              url: this.goToAppUrl('userBehaviorAnalysis', '/')
            },
            {
              label: '智能报告',
              alias: 'znbg',
              icon: '',
              description: '一站式IT服务报告平台',
              url: this.goToAppUrl('reportplatform', '/ai-report/')
            },
            {
              label: '客户360',
              alias: 'kh360',
              icon: '',
              description: '客户360应用数据价值平台',
              url: this.goToAppUrl('apphealth', '/360d')
            },
            {
              label: '可视化中心',
              alias: 'kshzx',
              icon: '',
              description: '数据可视化应用搭建工具',
              url: this.goToAppUrl('dashboard', '/')
            }
          ]
        }
      ]
    }
  },
  watch: {
    current: {
      immediate: true,
      deep: true,
      handler() {
        this.currentId = this.getCurrentId()
      }
    }
  },
  methods: {
    getCurrentId() {
      const { menuList, current } = this
      let routes = []
      menuList.forEach((val) => {
        routes = [...routes, ...val.menus]
      })
      return routes.filter((routesItem) => routesItem.label === current)[0]
        ?.alias
    },
    goToAppUrl(AppCode, RedirectUrl) {
      return `/v20/${BASE_URL.WEB}/frontend/app/go-to-app.svc?AppCode=${AppCode}&MenuCode=001&RedirectUrl=${RedirectUrl}`
    },
    logout() {
      this.$emit('logout')
    },
    getCurrentRoute(handleItem, callback) {
      let url = null
      this.$emit('getCurrentRoute', handleItem, (currentUrl) => {
        url = currentUrl
      })
      callback(url)
    },
    openToolGroup() {
      this.$emit('openToolGroup')
    }
  }
}
</script>

<style scoped lang="scss">
@import 'style/variables';
.header-navbar-wrapper {
  width: 100%;
  display: flex;
  z-index: 3006;
  justify-content: space-between;
  background-color: $backgroundColor;
  padding: 0 $margin;
  box-sizing: border-box;
  border-bottom: $border;

  .header-navbar-right {
    height: 51px;
    line-height: 51px;
  }
}
</style>
