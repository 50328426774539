<template>
  <div class="right-nav">
    <el-link
      v-if="bizCode"
      :underline="false"
      target="_blank"
      :href="`/v20/screen/board/redirect-dmp?biz_code=${bizCode}`"
      class="vice-text"
    >
      <svg-icon-comp :icon-class="'panel_icon'" class="toolbar-icon" />
      <span class="panel-label">服务报告</span>
    </el-link>
    <template v-if="hasNoRightIcon">
      <div class="dropdown" @mouseenter="showTenant" @mouseleave="hideTenant">
        {{ tenantName }}
        <span class="el-icon-arrow-down" />
        <div v-show="tenantShow" class="panel tenant">
          <li class="item" :title="tenantName">{{ tenantName }}</li>
        </div>
      </div>
      <div
        v-if="productName"
        class="dropdown"
        @mouseenter="showProduct"
        @mouseleave="hideTenant"
      >
        {{ productName }}
        <span class="el-icon-arrow-down" />
        <div v-show="productShow" class="panel product">
          <li class="item">{{ productName }}</li>
        </div>
      </div>
      <!-- <el-link
        :underline="false"
        :href="currentApp === 'web' ? null : `/v20/${BASE_URL.WEB}/account/user/back-to-workbench.svc?returnUrl=${getActiveUrl()}/tool/all-tool?current=${currentId}`"
        :class="{'active': currentRoute === 'Alltool'}"
        class="toolbar"
        @click="goToTool"
      >
        <svg-icon-comp
          :icon-class="
            currentRoute === 'Alltool' ? 'toolbar_active' : 'toolbar'
          "
          class="toolbar-icon"
        />
        工具集
      </el-link> -->
      <div class="header-nav-icon clearfix">
        <template v-for="(handleItem, handleIndex) in handleItems">
          <!--    写两个是为了非当服务云前台下，其他工具做跳转的时候为了防止页面被拦截，采用的a标签让用户主动点击    -->
          <router-link
            v-if="currentApp === 'web' && handleItem.routeName === 'Message'"
            :key="'webNotice' + handleIndex"
            :to="{ name: handleItem.routeName }"
            class="nv-handle-icon"
            @mouseenter.native.stop="onMouseEnter(handleItem, $event)"
            @mouseleave.native.stop="onMouseLeave(handleItem, $event)"
          >
            <el-badge
              :is-dot="isDot"
              :max="999"
              :hidden="hasMessage <= 0"
              :value="hasMessage"
            >
              <svg-icon-comp :icon-class="handleItem.icon" />
            </el-badge>
          </router-link>
          <el-link
            v-else
            :key="handleIndex"
            :underline="false"
            :target="handleItem.routeName === 'Help' ? '_blank' : '_self'"
            :href="
              handleItem.routeName === 'Notice' ||
                rightIconCustomEvent.includes(handleItem.routeName)
                ? null
                : (handleItem.path
                  ? `${getCurrentRoute(handleItem)}?current=${currentId}`
                  : getCurrentUrl() || null) || null
            "
            class="nv-handle-icon"
            :class="{ disabled: isCurrentRightIconDisabled(handleItem) }"
            @mouseenter.native.stop="onMouseEnter(handleItem, $event)"
            @mouseleave.native.stop="onMouseLeave(handleItem, $event)"
          >
            <el-badge
              v-if="handleItem.routeName === 'Message'"
              :is-dot="isDot"
              :max="999"
              :hidden="hasMessage <= 0"
              :value="hasMessage"
            >
              <svg-icon-comp
                :icon-class="handleItem.icon"
                @click="handelOnRightIcon(handleItem)"
              />
            </el-badge>
            <template v-else>
              <svg-icon-comp
                v-if="handleItem.routeName !== 'Notice'"
                :icon-class="
                  !isCurrentRightIconDisabled(handleItem)
                    ? handleItem.icon
                    : 'help_icon-disabled'
                "
                @click="handelOnRightIcon(handleItem)"
              />
              <el-badge v-else :is-dot="true" :max="999" :hidden="!hasNotice">
                <svg-icon-comp
                  :icon-class="handleItem.icon"
                  @click="onShowNotice(handleItem)"
                />
              </el-badge>
            </template>
          </el-link>
        </template>
      </div>
    </template>
    <slot />
    <div class="userinfo-wrapper">
      <el-avatar
        class="user-avatar"
        :size="30"
        :src="userIcon || defaultIcon"
      />
      <ul class="user-handle-container">
        <el-menu class="user-handle-bottom" :default-active="activeIndex">
          <el-sub-menu index="1">
            <template #title>
              <span><SvgIconComp
                class="handle-items-icon"
                icon-class="qiyeqiye"
              /><span class="handle-item-text">企业</span></span>
            </template>
            <el-menu-item
              v-if="userPanelShowObj.includes('Service')"
              index="1-1"
              @click="goToPage('Service')"
            >
              <el-link
                :underline="false"
                :href="
                  currentApp !== 'web'
                    ? goToPage('Service', '/service/info')
                    : null
                "
              >服务总览</el-link>
            </el-menu-item>
            <el-menu-item
              v-if="userPanelShowObj.includes('Organization')"
              index="1-2"
              @click="goToPage('Organization')"
            >
              <el-link
                :underline="false"
                :href="
                  currentApp !== 'web'
                    ? goToPage('Organization', '/organization/list')
                    : null
                "
              >组织架构</el-link>
            </el-menu-item>
            <el-menu-item
              v-if="userPanelShowObj.includes('Manage') && isTenantAdmin"
              index="1-3"
              @click="goToPage('Manage')"
            >
              <el-link
                :underline="false"
                :href="
                  currentApp !== 'web'
                    ? goToPage('Manage', '/web-service/manage/organize')
                    : null
                "
              >服务云管理</el-link>
            </el-menu-item>
          </el-sub-menu>
          <el-menu-item
            v-if="userPanelShowObj.includes('UserInfo')"
            index="2"
            class="user-handle-item"
            @click="goToPage('UserInfo')"
          >
            <template #title>
              <el-link
                :underline="false"
                :href="
                  currentApp !== 'web'
                    ? goToPage('UserInfo', '/settings/user-info')
                    : null
                "
              >
                <svg-icon-comp
                  class="handle-items-icon"
                  icon-class="userinfo"
                /><span class="handle-item-text">个人信息</span>
              </el-link>
            </template>
          </el-menu-item>
        </el-menu>
        <li class="handle-items" @click="logout">
          <svg-icon-comp class="handle-items-icon" icon-class="logout" />
          <span class="handle-item-text">退出</span>
        </li>
      </ul>
    </div>

    <NoticePanel :visible="visible" @updateVisible="updateVisible" />
  </div>
</template>

<script>
import { BASE_URL } from '../../../utils/constants'
import { getActiveHttp } from '../../../utils'
import NoticePanel from '../../../components/notice/notice'
import SvgIconComp from '../../../components/SvgIcon/index'
export default {
  name: 'Right',
  components: {
    SvgIconComp,
    NoticePanel
  },
  props: {
    current: { type: String, default: '' },
    bizCode: { type: String, default: null },
    helpLink: { type: String, default: '' },
    tenantName: { type: String, default: '暂无租户' },
    productName: { type: String, default: '全部系统' },
    userIcon: {
      type: String,
      require: true,
      default:
        'https://myom-release.oss-cn-hangzhou.aliyuncs.com/mysm/resource/workbench/profile.png'
    },
    userName: { type: String, default: '暂无用户名称' },
    currentApp: { type: String, default: '' },
    menuList: { type: Array, required: true }, // 当前菜单列表
    isTenantAdmin: { type: Boolean, required: true }, // 是否有权限访问 '服务云管理'页签
    currentId: { type: String, default: '' }, // 当前工具AppCode
    hasMessage: { type: Number, default: 0 },
    hasNoRightIcon: { type: Boolean, default: true }, // 是否不需要图标
    userPanelShowObj: {
      type: Array,
      default() {
        return ['Company', 'Service', 'Organization', 'Manage', 'UserInfo']
      }
    },
    hasNotice: { type: Boolean, default: false }, // 是否有公告
    rightIconCustomEvent: {
      type: Array,
      default() {
        return []
      }
    } // 右侧哪些按钮是自定义事件
  },
  data() {
    setTimeout(() => {
      console.log(this.currentId, 'currentId---')
    }, 2000)
    return {
      BASE_URL,
      defaultIcon:
        'https://myom-release.oss-cn-hangzhou.aliyuncs.com/mysm/resource/workbench/profile.png',
      visible: false,
      tenantShow: false,
      productShow: false,
      handleItems: [
        { label: '公告', routeName: 'Notice', path: '', icon: 'gonggao' },
        {
          label: '消息',
          routeName: 'Message',
          path: '/message/list',
          icon: 'notice_icon'
        },
        { label: '帮助', routeName: 'Help', path: '', icon: 'help_icon' }
      ],
      userItems: [
        {
          label: '企业',
          routerName: 'Company',
          children: [
            { label: '服务总览', routerName: 'Service', path: '/service/info' },
            {
              label: '组织架构',
              routerName: 'Organization',
              path: '/organization/list'
            },
            {
              label: '服务云管理',
              routerName: 'Manage',
              path: '/web-service/manage/organize'
            }
          ]
        },
        {
          label: '个人信息',
          routerName: 'UserInfo',
          path: '/settings/user-info'
        }
      ],
      isDot: true //
    }
  },
  computed: {
    currentRoute() {
      return this.$route.name
    },
    activeIndex() {
      return this.$route.path
    }
  },
  watch: {},
  mounted() {
    window.addEventListener('click', this.hidePanel)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.hidePanel)
  },
  methods: {
    updateVisible(visible) {
      this.visible = visible
    },
    // 获取当前帮助文档的链接
    getCurrentUrl() {
      const { menuList, current, helpLink } = this
      let routes = []
      menuList.forEach((val) => {
        routes = [...routes, ...val.menus]
      })
      return (
        routes.filter((routesItem) => routesItem.label === current)[0]
          ?.helpLink ||
        helpLink ||
        null
      )
    },
    // 获取当前右侧按钮的点击事件或者其他
    isCurrentRightIconDisabled({ routeName }) {
      const { rightIconCustomEvent } = this
      if (routeName === 'Help') {
        return rightIconCustomEvent.length <= 0 && !this.getCurrentUrl()
      } else {
        return false
      }
    },

    goToPage(name, path) {
      let url = ''
      if (this.currentApp === 'web') {
        if (this.$route.name !== name) this.$router.push({ name })
      } else {
        url = `${location.origin}/v20/${
          BASE_URL.WEB
        }/account/user/back-to-workbench.svc?returnUrl=${this.getActiveUrl()}${path}?current=${
          this.currentId
        }`
      }
      return url
    },
    getActiveUrl() {
      return getActiveHttp(
        'https://mysm.fdcyun.com',
        'https://mysm-test.fdcyun.com',
        `http://localhost:7777`,
        false,
        'https://auth-qa.fdcyun.com'
      )
    },
    handelOnRightIcon(item) {
      this.$emit('handelOnRightIcon', item)
    },
    //
    getCurrentRoute(handleItem) {
      let url = null
      this.$emit('getCurrentRoute', handleItem, (currentUrl) => {
        url = currentUrl
      })
      return url
    },
    onShowNotice(item) {
      const { rightIconCustomEvent } = this
      // 如果是自定义事件则不需要显示
      if (!rightIconCustomEvent.includes('Notice')) {
        this.visible = true
        this.$emit('onShowNotice')
      } else {
        this.$emit('handelOnRightIcon', item)
      }
    },

    onMouseEnter({ routeName }) {
      if (routeName === 'Message') this.isDot = false
    },
    onMouseLeave() {
      this.isDot = true
    },
    showTenant() {
      this.tenantShow = true
      this.productShow = false
    },
    hideTenant() {
      this.tenantShow = false
      this.productShow = false
    },
    showProduct() {
      this.productShow = true
      this.tenantShow = false
    },
    goToTool() {
      // if (this.$route.name !== 'AllTool') this.$router.push({ path: '/tool/all-tool' })
      // this.$router.push({ path: '/tool/all-tool' });
    },
    hidePanel() {
      this.tenantShow = false
      this.productShow = false
    },
    logout() {
      this.$emit('logout')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styles/variables';
.right-nav {
  display: flex;
  z-index: 3006;
  position: absolute;
  top: 50%;
  right: $panelLeftPadding;
  margin-top: -25px;
    .vice-text {
      margin-right: 20px;
      position: relative;
      font-weight: normal;
      font-size: 14px;
      min-width: 112px;
      height: 28px;
      line-height: 28px;
      border-radius: 28px;
      background: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMTEgMjgiIGNsYXNzPSJkZXNpZ24taWNvbmZvbnQiPgogIDxyZWN0IHdpZHRoPSIxMTEiIGhlaWdodD0iMjgiIHJ4PSIxNCIgZmlsbD0idXJsKCN2eXg0NmRpZmlfX3BhaW50MF9saW5lYXJfOTU2Xzc5NDgpIi8+CiAgPHBhdGggZD0iTTgxIDBINzhMNjkgMjhINzJMODEgMFoiIGZpbGw9IiNmZmYiIGZpbGwtb3BhY2l0eT0iLjA4Ii8+CiAgPHBhdGggZD0iTTQ3IDBINDBMMzEgMjhIMzhMNDcgMFoiIGZpbGw9IiNmZmYiIGZpbGwtb3BhY2l0eT0iLjA4Ii8+CiAgPGRlZnM+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9InZ5eDQ2ZGlmaV9fcGFpbnQwX2xpbmVhcl85NTZfNzk0OCIgeDE9IjAiIHkxPSIxNCIgeDI9IjExMSIgeTI9IjE0IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CiAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiM1MDdBRkUiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMkNEOUZGIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KPC9zdmc+Cg==") no-repeat center center / cover;
      color: #fff;
      margin-top: 11px;
      .panel-label {
        margin-left: 8px;
      }
      &:after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 15px;
        background-color: #E9EBEE;
        position: absolute;
        top: 49%;
        right: -10px;
        transform: translateY(-50%);
      }
      &:hover {
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMTEgMjgiIGNsYXNzPSJkZXNpZ24taWNvbmZvbnQiPgogIDxyZWN0IHdpZHRoPSIxMTEiIGhlaWdodD0iMjgiIHJ4PSIxNCIgZmlsbD0idXJsKCNhcWdvNWQ2aXdfX3BhaW50MF9saW5lYXJfOTU2Xzg1MDkpIi8+CiAgPHJlY3Qgd2lkdGg9IjExMSIgaGVpZ2h0PSIyOCIgcng9IjE0IiBmaWxsPSIjZmZmIiBmaWxsLW9wYWNpdHk9Ii4yIi8+CiAgPHBhdGggZD0iTTgxIDBINzhMNjkgMjhINzJMODEgMFoiIGZpbGw9IiNmZmYiIGZpbGwtb3BhY2l0eT0iLjA4Ii8+CiAgPHBhdGggZD0iTTQ3IDBINDBMMzEgMjhIMzhMNDcgMFoiIGZpbGw9IiNmZmYiIGZpbGwtb3BhY2l0eT0iLjA4Ii8+CiAgPGRlZnM+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImFxZ281ZDZpd19fcGFpbnQwX2xpbmVhcl85NTZfODUwOSIgeDE9IjAiIHkxPSIxNCIgeDI9IjExMSIgeTI9IjE0IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CiAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiM1MDdBRkUiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMkNEOUZGIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KPC9zdmc+Cg==");
      }
      &:active {
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMTEgMjgiIGNsYXNzPSJkZXNpZ24taWNvbmZvbnQiPgogIDxyZWN0IHdpZHRoPSIxMTEiIGhlaWdodD0iMjgiIHJ4PSIxNCIgZmlsbD0idXJsKCM5YTU3c2l2bzVfX3BhaW50MF9saW5lYXJfOTU2XzkyODcpIi8+CiAgPHJlY3Qgd2lkdGg9IjExMSIgaGVpZ2h0PSIyOCIgcng9IjE0IiBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9Ii4xIi8+CiAgPHBhdGggZD0iTTgxIDBINzhMNjkgMjhINzJMODEgMFoiIGZpbGw9IiNmZmYiIGZpbGwtb3BhY2l0eT0iLjA4Ii8+CiAgPHBhdGggZD0iTTQ3IDBINDBMMzEgMjhIMzhMNDcgMFoiIGZpbGw9IiNmZmYiIGZpbGwtb3BhY2l0eT0iLjA4Ii8+CiAgPGRlZnM+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9IjlhNTdzaXZvNV9fcGFpbnQwX2xpbmVhcl85NTZfOTI4NyIgeDE9IjAiIHkxPSIxNCIgeDI9IjExMSIgeTI9IjE0IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CiAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiM1MDdBRkUiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMkNEOUZGIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KPC9zdmc+Cg==");
      }
    }
  .dropdown {
    margin-right: $margin;
    cursor: pointer;
    font-size: 14px;
    color: #222222;
    span {
      margin-left: 4px;
    }
    .panel {
      position: absolute;
      top: $navBarHeight - 8px;
      max-width: 400px;
      z-index: 3006;
      background-color: $backgroundColor;
      box-shadow: $panelBoxShadow;
      .item {
        height: $panelListHeight;
        line-height: $panelListHeight;
        padding: 0 $padding;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          background-color: $hoverBGColor2;
        }
      }
    }
  }
}
.toolbar {
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  color: #222222 !important;
  &-icon {
    width: 18px;
    height: 18px;
    vertical-align: -3px;
  }
  &:hover,
  &.active {
    color: #0f6dff !important;
  }
  &::before,
  &::after {
    content: '';
    width: 1px;
    height: 30%;
    background-color: #e9ebee;
  }
  &::before {
    margin-right: $margin;
  }
  &::after {
    margin-left: $margin;
  }
}

.header-nav-icon {
  position: relative;
  .nv-handle-icon {
    font-size: 16px;
    margin-left: 12px;
    // color: #919fb4;
    font-weight: 500;
    cursor: pointer;
    display: block;
    float: left;
    vertical-align: middle;
    &.disabled {
      cursor: not-allowed;
    }
    &:hover {
      color: rgba($themecolor, 0.7);
    }
    ::v-deep .el-badge {
      vertical-align: unset;
      .el-badge__content.is-fixed {
        top: 20px;
        right: 7px;
        z-index: 1;
      }
    }
  }
  &::before {
    content: '';
    width: 1px;
    height: 15px;
    display: inline-block;
    background-color: #E9EBEE;
    position: absolute;
    top: 49%;
    left: 0;
    transform: translateY(-50%);
  }
}

.userinfo-wrapper {
  cursor: pointer;
  position: relative;
  height: 100%;
  padding-top: 9px;
  margin-left: $margin;
  .user-handle-container {
    width: 150px;
    display: none;
    position: absolute;
    top: $navBarHeight;
    right: -$margin;
    background-color: $backgroundColor;
    border: 1px solid $borderColor;
    z-index: 3006;
    .handle-items {
      height: 42px;
      line-height: 42px;
      .user-avatar {
        margin-right: 8px;
      }
      &:first-of-type {
        border-top: 1px solid $borderColor;
        padding-left: 12px;
      }
      &:hover {
        background-color: $hoverBGColor;
        .handle-item-text {
          color: $themecolor;
        }
      }
    }
    ::v-deep .el-submenu__title {
      padding-left: 12px !important;
    }
    ::v-deep .el-menu-item {
      padding-left: 40px !important;
    }
    ::v-deep .el-submenu__title,
    ::v-deep .el-menu-item {
      height: 40px;
      line-height: 38px;
      .el-link {
        display: inline-block;
        width: 100%;
        height: 100%;
        font-weight: normal;
      }
      &.user-handle-item {
        padding-left: 12px !important;
      }
      &:hover {
        background-color: $hoverBGColor;
        .el-link {
          color: $themecolor;
        }
      }
      &.is-active {
        background: rgba($themecolor, 0.1);
        .el-link {
          color: $themecolor;
        }
      }
    }
  }
  .handle-items-icon {
    color: rgba(184, 184, 184, 1);
    margin-right: 13px;
  }
  &:hover {
    .user-handle-container {
      display: block;
    }
  }
}
.user-item {
  font-size: 14px;
  position: relative;
  vertical-align: -8px;
  width: calc(100% - 44px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.user-logout {
    cursor: pointer;
  }
}
</style>
