<template>
  <div class="history-page" :style="{minHeight}">
    <div class="table-wrap">
      <!-- <div class="table-top clearfix">
        <div class="tenant-name">租户：<span>{{ $route.query.name }}</span></div>
      </div> -->
      <el-table
        ref="keyTable"
        v-loading="isLoading"
        :data="tableData"
        style="width: 100%"
        header-cell-class-name="table-th-class"
        :highlight-current-row="true"
        element-loading-text="加载中，请稍后..."
        :class="[isLoading?'table-loading':'',tableData&&tableData.length===0?' empty-table':'']"
      >

        <el-table-column
          prop="_sno"
          label="序号"
          width="60"
          show-overflow-tooltip
        />
        <el-table-column
          prop="type"
          label="类别"
          show-overflow-tooltip
        />
        <el-table-column
          prop="productName"
          label="产品"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ scope.row.productName ?scope.row.productName:'--' }}
          </template>
        </el-table-column>

        <el-table-column
          prop="dimension"
          label="维度"
          show-overflow-tooltip
        />
        <el-table-column
          prop="checkName"
          label="检查大项"
          show-overflow-tooltip
        />
        <el-table-column
          prop="checkOneName"
          label="一级检查项"
          min-width="140"
          show-overflow-tooltip
        />
        <el-table-column
          prop="checkTwoName"
          label="二级检查项"
          min-width="160"
          show-overflow-tooltip
        />
        <el-table-column
          prop="processState"
          label="当前状态"
          show-overflow-tooltip
        >
          <template #default="scope">
            <span v-if="scope.row.processState===0">待处理</span>
            <span v-if="scope.row.processState===6">挂起</span>
            <span v-if="scope.row.processState===3">处理中</span>
            <span v-if="scope.row.processState===10">已解决</span>
            <span v-if="scope.row.processState===11">自愈</span>
            <span v-else />
          </template>
        </el-table-column>
        <el-table-column
          prop="processersUsers"
          label="当前节点负责人"
          width="130"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ scope.row.processersUsers ?scope.row.processersUsers:'--' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="lastAriseTime"
          label="诊断时间"
          width="100"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ scope.row.lastAriseTime?scope.row.lastAriseTime.substring(0,10):'--' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="处理时间"
          width="100"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ scope.row.updateTime?scope.row.updateTime.substring(0,10):'--' }}
          </template>
        </el-table-column>

        <el-table-column
          label="处理方案"
          width="110"
          fixed="right"
        >
          <template #default="scope">
            <el-button
              type="text"
              @click="handleView(scope.row)"
            >查看</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column type="expand" width="1">
          <template #default="scope" :v-loading="isLoadingExpand">
            <ExpandList :expand-data="scope.row.expandData" :dimension="scope.row.dimension" />
          </template>
        </el-table-column> -->

        <template v-if="tableData.length===0" #empty>
          <div class="my-empty-table">
            <div v-if="!isLoading" class="empty-txt">
              <div class="empty-icon" />
              <div class="empty-desc">暂无数据</div>
            </div>
            <div v-else />
          </div>
        </template>
      </el-table>
      <Pager
        :total="total"
        :current-page="currentPage"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
    <ExpandDialog
      ref="refExpand"
      :expand-data="expandData"
      :dialog-view-visible="dialogViewVisible"
      @handleCloseView="handleCloseView"
    />
  </div>
</template>
<script>
import {
  apiKey, // 获取表格数据
  apiAdvise
} from '@/api/health'
import Pager from '@/components/table/pagination.vue'
import ExpandDialog from '@/components/table/expand.vue'
export default {
  components: {
    Pager,
    ExpandDialog
  },
  data() {
    return {
      pageName: 'history',
      isLoading: false,
      total: 0,
      currentPage: 1,
      page: 1,
      pageSize: 20,
      pageSizes: [10, 20, 30, 40, 50, 100, 500],
      minHeight: '100px',
      tableData: [],
      id: null,
      ids: [],
      logs: [],
      row: {}, // 编辑的某一行
      type: '',
      errorReason: '',
      expandData: {},
      // dimension: '',
      dialogViewVisible: false
    }
  },

  created() {
    const height = document.documentElement.clientHeight - 320
    this.minHeight = (height > 0 ? height : '100') + 'px'
    this.handleSearchTable()
  },
  methods: {

    // 表格数据显示
    handleSearchTable() {
      const vm = this
      const { page, pageSize } = vm
      // const { tenantId, dimension, checkName } = this.$route.query
      const tablePara = { page, pageSize }
      vm.isLoading = true
      apiKey(tablePara).then(res => {
        const { items, total } = res
        items.forEach(row => {
          const { processers } = row
          if (processers && processers.length > 0) {
            const users = []
            processers.forEach(processer => {
              users.push(processer.userName)
            })
            users.length === 1 ? row.processersUsers = users[0] : row.processersUsers = users.join('，')
          }
          row.expandData = null
          if (row.dimension === '系统三高一关键' || row.dimension === '系统应用质量') {
            const { dimension, checkOneName, checkTwoName } = row
            apiAdvise({
              dimension,
              firstCheckName: checkOneName,
              secondCheckName: checkTwoName
            }).then(expandData => {
              row.expandData = expandData
            }).catch(() => {})
          }
        })
        vm.tableData = items
        vm.total = total
        vm.handleGetDetail()
      }).catch(() => {}).finally(() => { vm.isLoading = false })
    },
    /**
     @翻页，切换页面
     **/
    handleSearch(arg) {
      const { page, pageSize } = arg
      this.page = page
      this.pageSize = pageSize
      this.handleSearchTable()
    },

    /**
     * 查看
     */
    handleView(row) {
      const vm = this
      vm.multi = false
      const currentRow = JSON.parse(JSON.stringify(row))
      vm.id = currentRow.id
      vm.dialogViewVisible = true
      // vm.dimension = row.dimension
      vm.expandData = row.expandData
      // apiGetOperationLog({ id: vm.id }).then(res => {
      //   if (res.length > 0) {
      //     res.forEach(item => {
      //       item.isMore = true
      //     })
      //     vm.logs = res
      //   }
      //   // vm.dialogViewVisible = true
      // }).catch(() => {})
    },
    // handleExpand(row) {
    //   const $table = this.$refs.keyTable
    //   $table.toggleRowExpansion(row)
    // },
    handleSizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      this.page = 1
      this.selectedRows = []
      this.selectedArgs = []
      this.selectedLength = 0
      this.handleSearchTable()
    },

    handleCurrentChange(val) {
      this.currentPage = val
      this.page = val
      this.selectedRows = []
      this.selectedArgs = []
      this.selectedLength = 0
      this.handleSearchTable()
    },
    handleCloseView(flag) {
      this.dialogViewVisible = flag
    }
  }
}
</script>
<style lang="scss" scoped>
.el-table{
  ::v-deep .el-table__expand-icon{
  display: none;
}
}

</style>
