<template>
  <el-dialog
    v-if="dialogViewVisible"
    v-model="dialogViewVisible"
    title="处理日志"
    width="526px"
    :close-on-click-modal="false"
    :show-close="false"
    class="common-dialog add-dialog"
    :before-close="handleClose"
  >
    <!-- <div class="expand-title">
        <span class="iconfont icon-wodeyiban" />
        <span class="top1Name">处理日志</span>
      </div> -->
    <div class="time-line">
      <el-timeline v-if="logs.length>0">
        <el-timeline-item
          v-for="(log, index) in logs"
          :key="index"
          :color="log.color"
        >
          {{ log.content }}
          <div>
            <div class="log-time">{{ log.createTime }}</div>
            <div class="log-content">
              <!-- <span class="log-process">{{ log.processType? log.processType:'操作' }}</span>了异常 -->
              <span class="log-process">{{ log.processTitle }}</span>
              <span v-if="log.isMore && index!==logs.length-1 && log.processState!==11" class="more" @click="log.isMore = !log.isMore ">查看详情</span>
              <span v-if="!log.isMore && index!==logs.length-1 && log.processState!==11" class="more" @click="log.isMore = !log.isMore ">收起</span></div>
            <div v-if="!log.isMore && log.processState!==11" class="log-detail">
              <el-form
                ref="logForm"
                label-width="66px"
                :rules="rules"
              >
                <el-form-item
                  v-if="log.processType==='处理'"
                  :key="21"
                  label="处理内容"
                  prop="operate"
                  class="form-input"
                >
                  <div class="content">{{ log.processContent }}</div>
                </el-form-item>
                <el-form-item
                  v-if="log.processType==='转派'"
                  :key="2"
                  label="转派人"
                  prop="operate"
                  class="form-input"
                >
                  <div class="content">{{ log.transferTo }}</div>
                  <!-- <div>{{ log.wxNotify?'':'不' }}通过企业微信发送通知</div> -->
                </el-form-item>
                <el-form-item
                  v-if="log.processType==='转派'"
                  :key="22"
                  label="转派原因"
                  prop="operate"
                  class="form-input"
                >
                  <div class="content">{{ log.processContent }}</div>
                </el-form-item>

                <el-form-item
                  v-if="log.processType==='挂起'"
                  :key="23"
                  label="挂起原因"
                  prop="operate"
                  class="form-input"
                >
                  <div class="content">{{ log.processContent }}</div>
                </el-form-item>
                <el-form-item
                  v-if="log.processType==='完成'"
                  :key="24"
                  label="根本原因"
                  prop="operate"
                  class="form-input"
                >
                  <div class="content">{{ log.processContent }}</div>
                </el-form-item>
                <el-form-item
                  v-if="log.processType==='完成'"
                  :key="25"
                  label="解决方案"
                  prop="operate"
                  class="form-input"
                >
                  <div class="content">{{ log.solution }}</div>
                </el-form-item>
                <el-form-item
                  v-if="log.processType==='补充说明'"
                  :key="24"
                  label="补充说明"
                  prop="operate"
                  class="form-input"
                >
                  <div class="content">{{ log.processContent }}</div>
                </el-form-item>
              </el-form>
            </div>
          </div>

        </el-timeline-item>
      </el-timeline>
      <div v-else class="no-data">暂无数据</div>
    </div>
    <div class="form-btn">
      <el-button
        type="primary"
        size="small"
        @click="handleClose"
      >确定</el-button>
    </div>
  </el-dialog>
</template>
<script>

export default {
  props: {
    logs: {
      type: Array,
      default: function() {
        return []
      }
    },
    dialogViewVisible: {
      type: Boolean,
      default: function() {
        return false
      }
    }
  },
  emits: ['handleCloseView'],
  setup(props, context) {
    function handleClose() {
      context.emit('handleCloseView', false)
    }
    return {
      handleClose
    }
  }
  // methods: {
  //   handleClose() {
  //     this.$emit('handleCloseView', false)
  //   }
  // }
}
</script>
<style scoped lang="scss">
  .form-btn{
    text-align: right;
  }
  .expand-title{
        font-size: 14px;
        line-height: 20px;
        border-bottom: 1px solid #EDEDED;
        padding: 12px 40px;
        svg,.iconfont{
         display: inline-block;
         margin-right: 10px;
         font-size: 14px;
         color: #0F6DFF;
        }
    }
.time-line{
  padding: 0 20px;
  max-height: 600px;
  overflow-y: auto;
  // margin-bottom: 20px;
 ::v-deep .el-timeline-item:first-child{
    .el-timeline-item__node{
        border:2px solid #1E89FF;
        background:#fff;
    }
  }
  ::v-deep .el-timeline-item__wrapper{
    top: 0;
  }
  .no-data{
    text-align: left;
    padding:  0 0 10px;
    color: #999;
  }
}
.log-time{
  font-size: 12px;
  color: rgba(51, 51, 51, 0.45);
}
.log-content{
    font-size: 12px;
    color: rgba(51, 51, 51, 0.45);
    margin: 10px 0;
  .log-process{
    color: rgba(51, 51, 51, 0.45);
  }
  .more{
    display: inline-block;
    margin: 0 0 0 10px;
    color:#0F6DFF;
    cursor: pointer;
  }
  .content{
    font-size: 12px;
    color: #999;
  }
}
.log-detail{
  margin-top: 12px;
  background: #f6f6f6;
  padding: 10px 10px 6px;
  ::v-deep .el-form-item__label{
    font-size: 12px;
    color: #999;
    line-height: 20px;
    position: relative;
    top: 0px;
  }
  ::v-deep .el-form-item__content{
    font-size: 12px;
    color: #333;
    line-height: 20px;
  }
 .el-form-item{
    margin-bottom: 6px;
  }

}
</style>
