
import {
  get,
  post
} from './api.js'
const pathHealth = '/api/cancerwebapp/360d'
// v20/api/cancerwebapp/360d/last-diagnosis-time.svc?date=2021-10-12
// v20/api/cancerwebapp/360d/get-app-health.svc?lastTime=2021-10-11
// v20/api/cancerwebapp/360d/get-dimension-score.svc?lastTime=2021-10-11
// v20/api/cancerwebapp/360d/get-daily-scores.svc?lastTime=2021-10-11
// v20/api/cancerwebapp/360d/get-chart-0.svc?lastTime=2021-10-12
/**
 * 查询诊断时间
 * @param {*} data
 * @returns
 */
export const apiDiagnosisTime = data => get(pathHealth + '/last-diagnosis-time.svc', data)
/**
 * 应用健康水平
 * @param {*} data
 * @returns
 */
export const apiAppHealth = data => get(pathHealth + '/get-app-health.svc', data)
/**
 * 应用健康水平雷达图
 * @param {*} data
 * @returns
 */
export const apiDimensionScore = data => get(pathHealth + '/get-dimension-score.svc', data)
/**
 * 近30天应用健康度趋势图
 * @param {*} data
 * @returns
 */
export const apiDailyScores = data => get(pathHealth + '/get-daily-scores.svc', data)
/**
 * 系统三高一关键图表
 * @param {*} data
 * @returns
 */
export const apiChartKey = data => get(pathHealth + '/get-chart-0.svc', data)

/**
 *  系统应用质量图表
 * @param {*} data
 * @returns
 */
export const apiChartQuality = data => get(pathHealth + '/get-chart-1.svc', data)

/**
 *  系统故障率图表
 * @param {*} data
 * @returns
 */
export const apiChartFault = data => get(pathHealth + '/get-chart-2.svc', data)

/**
 * CXO业务触达率图表
 * @param {*} data
 * @returns
 */
export const apiChartCxo = data => get(pathHealth + '/get-chart-3.svc', data)

/**
  * 用户活跃情况:饼图
**/
// const avtivePath = '/v20/api/cancerwebapp/userBehavior/mauAnalysis'
const avtivePath = '/api/cancerwebapp/360d'
export const apiGetMauSummary = data =>
  get(avtivePath + '/mau-summary.svc', data)

// apiDiagnosisTime,apiAppHealth,apiDimensionScore,apiDailyScores,apiChartKey,apiChartQuality,apiChartFault,apiChartCxo

// 查询昨日异常项表格
export const apiKey = data =>
  post(pathHealth + '/exceptions/list/0.svc', data)

export const apiKeyResolved = data =>
  post(pathHealth + '/exceptions/list/1.svc', data)
// 操作日志
export const apiGetOperationLog = data =>
  get(pathHealth + '/exceptions/get-operation-log.svc', data)

// 查询表格行明细
export const apiAdvise = data =>
  get(pathHealth + '/exceptions/get-solution-advise.svc', data)
