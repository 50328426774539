<template>
  <div class="assigned-page">
    <Search v-show="tableConfig.select.length <= 0" :search-params.sync="getTableParams"
            :has-status="false"  @changeParams="changeParams"/>
    <MyTable
      ref="assignedTable"
      :this-table="this"
      :row-key="'number'"
      :column-config="columnConfig"
      :table-config="tableConfig"
      :handle-more-btn="handleMoreBtn"
      :table-page-config="tablePageConfig"
      :handle-option="handleOption"
      :http-fun="getDeliverablesList"
      :get-table-params="getTableParams"
      :height="`calc( 100vh - 104px )`"
      @afterGetDataSuccess="afterGetDataSuccess"
    >
      <!--   批量下载   -->
<!--      <template #downloadAll="{ slotData }">-->
<!--        <a :download="getDownloadFilesAll().contents"-->
<!--           class="assigned-download"-->
<!--           target="_blank"-->
<!--           @click="onDownloadFilesAll(getDownloadFilesAll().hasFiles, $event)"-->
<!--           :href="getDownloadFilesAll().hasFiles ? `/v20/api/cancerwebapp/customersuccess/download-item-deliverables.svc?ids=${getDownloadFilesAll().ids}` : 'javascript:void(0);'">批量下载</a>-->
<!--      </template>-->

      <!--   完成时间   -->
      <template #CompleteTimeSlot="{ row }">
        {{ row.completeTime ? formatTime(row.completeTime) : '--' }}
      </template>

      <template #FilesSlot="{ row }">
        <div class="assigned-td">
          <template v-if="row.deliverables && row.deliverables.length > 0">
            <SvgIcon class="assigned-file_icon" :icon-class="FILETYPEICON[judgeFileType((row.deliverables &&  row.deliverables[0]) ? row.deliverables[0].fileName : '')] || 'Default'" />
            <span class="assigned-file_name outter" :title="(row.deliverables &&  row.deliverables[0]) ? row.deliverables[0].fileName : ''">
              <span  class="assigned-file_label" >{{(row.deliverables &&  row.deliverables[0]) ? row.deliverables[0].fileName : '--' }}</span>
              <span class="assigned-file_number" v-if="row.deliverables && row.deliverables.length > 0">
                （{{row.deliverables.length}}个）
              </span>
            </span>
          </template>
          <template v-else>--</template>
           <ul class="assigned-more_files" v-if="row.deliverables && row.deliverables.length > 0">
            <li v-for="(item, index) in row.deliverables" :key="index" class="assigned-files_item" :title="'点击下载'">
              <a :href="`/v20/api/cancerwebapp/customersuccess/download-deliverable.svc?id=${item.id}`" :download="item.name">
                <SvgIcon class="assigned-file_icon" :icon-class="FILETYPEICON[judgeFileType(item.fileName || '')] || 'Default'" />
                <span class="assigned-file_name" :title="item.fileName">{{ item.fileName || '--' }}</span>
              </a>
            </li>
          </ul>
        </div>
      </template>

    <!--   批量下载   -->
<!--      <template #download="{ row }">-->
<!--        <a class=" handler-text"-->
<!--           @click.stop-->
<!--           v-if="row.deliverables && row.deliverables.length > 0"-->
<!--           :download="row.content"-->
<!--           :href="`/v20/api/cancerwebapp/customersuccess/download-item-deliverables.svc?ids=${[row.id]}`">下载交付物</a>-->
<!--      </template>-->
    </MyTable>
  </div>
</template>

<script>
import Search from './components/Search'
import MyTable from './components/MyTable'
import { judgeFileTypeToShowIcon } from '@/utils'
import { FILETYPEICON } from '@/utils/constants'
import { SvgIcon } from '@/components'
import { getDeliverablesList, downloadAll } from '@/api/success'
import { ElMessage } from 'element-plus'
export default {
  name: 'Achievements',
  components: {
    Search, MyTable, SvgIcon
  },
  props: {},
  data() {
    return {
      FILETYPEICON,
      getDeliverablesList,
      tableConfig: {
        tableWidth: '100%', // 表格宽度
        hasExpand: true, // 是否显示展开
        showTableOption: true, // 是否显示操作列
        cellOptionWidth: '100px', // 操作列宽
        showPagination: false, // 是否显示翻页
        selectMore: true, // 是否显示多选
        pagerCount: 7, // 翻页按钮个数
        fixedHandleColumn: true, // 是否固定操作列
        hasExpandWidth: 1, // 展开列的宽度
        style: { height: 'auto' },
        handleLineText: '操作', // 操作列表头名称
        sortProp: '', // 默认排序字段和排序方式
        select: [],
        cancelLine: []
      },
      tablePageConfig: {
        page: 1,
        size: 20,
        count: 100
      },
      columnConfig: [
        { label: '年度', prop: 'year', width: '130px', showOverflowTooltip: true, isHide: true },
        // { label: '工作项编号', prop: 'number', width: '130px', showOverflowTooltip: true, isHide: true },
        { label: '工作项名称', prop: 'itemName', width: 'auto', showOverflowTooltip: true, minWidth: '200px', isHide: true },
        { label: '内容说明', prop: 'content', width: 'auto', showOverflowTooltip: true, minWidth: '150px', isHide: true },
        { label: '完成时间', prop: 'completeTime',  slot: 'CompleteTimeSlot', showOverflowTooltip: true, width: '120px', isHide: true },
        { label: '责任人', prop: 'responsiblePersonName', showOverflowTooltip: true, width: '150px', isHide: true },
        { label: '交付物', prop: 'deliverables', width: '260px', showOverflowTooltip: true, slot: 'FilesSlot', isHide: true }
      ],
      getTableParams: {
        Year: '',
        ItemName: ''
      },
      handleOption: [
        // , slot: 'download'
        { name: '下载交付物', fun: 'onDownloadFilesAllEvent', icon: '', type: 'download' }
      ],
      handleMoreBtn: [
        // , slot: 'downloadAll'
        { name: '批量下载', isLoading: false, fun: 'onDownloadFilesAllEvent' }
      ]
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
    sessionStorage.removeItem('searchParams')
  },
  methods: {
    // 搜索列表
    changeParams(params) {
      this.getTableParams = params
      this.$refs.assignedTable?.cancelSelectAll()
    },

    onDownloadFilesAllEvent(items) {
      const isDownloadSingle = items.hasOwnProperty('option')
      const { ids, hasFiles } = this.getDownloadFilesAll()
      const currentIds = isDownloadSingle ? [items.scope.row.id] : ids
      if (!isDownloadSingle) { //如果是下载所有，需要判断当前是否有文件
        if (!hasFiles) {
          ElMessage({
            message: '当前没有可以下载的交付物！',
            type: 'warning',
          })
          return
        }
      }
      if (currentIds?.length > 0) {
        const newPage = window.open(`/v20/api/cancerwebapp/customersuccess/download-item-deliverables.svc?ids=${currentIds}`)
        newPage.document.title = '文件准备中...'
      }
      // const newPage = this.$router.resolve({ name: 'downloading' })
      // if (currentIds?.length > 0) {
      //   newPage.href = `/v20/api/cancerwebapp/customersuccess/download-item-deliverables.svc?ids=${currentIds}`
      //   window.open(newPage.href, '_blank')
      // }
    },

    // 批量下载文件
    getDownloadFilesAll() {
      let ids =  []
      let contents = []
      let hasFiles = []
      this.tableConfig.select.forEach(({id, content, deliverables}) => {
        ids = [...ids, id]
        contents = [...contents, content]
        if (deliverables && deliverables.length > 0) hasFiles = [...hasFiles, deliverables]
      })
      return { ids, contents, hasFiles: hasFiles.length > 0 }
    },

    afterGetDataSuccess(data) {
      let hasFiles = []
      data.forEach(({ deliverables }) => {
        if (deliverables && deliverables.length > 0) hasFiles = [...hasFiles, deliverables]
      })
      this.tableConfig.showTableOption = hasFiles.length > 0
    },

    // 判断文件格式
    judgeFileType(fileName) {
      const type = fileName.split('.')[fileName.split('.').length - 1] || ''
      return judgeFileTypeToShowIcon(type)
    },

    //时间格式化
    formatTime(time) {
      return this.$moment(time).format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped lang='scss'>
.assigned-page {
  background-color: #fff;
  height: calc( 100vh - 52px );
    .assigned-file_name {
        display: inline-block;
        margin-left: 4px;
        vertical-align: middle;
        width: calc( 100% - 30px );
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      &.outter {
        width: calc(  100% - 50px );
      }
    }
    .assigned-file_icon {
        vertical-align: middle;
    }
    .assigned-files_item {
        margin-bottom: 2px;
      padding: 0 4px;
      border-radius: 4px;
        &:last-of-type {
            margin-bottom: 0;
        }
      &:hover {
        background-color: #f5f5f5;
      }
    }
    .assigned-td {
        position: relative;
        .assigned-more_files {
            border: 1px solid rgba(233,235,238,1);
            background: rgba(255,255,255,1);
            box-shadow: 0 3px 8px 0 rgba(0,0,0,0.1);
            width: 100%;
            position: absolute;
            top: 100%;
            left: 0;
            padding: 12px 8px;
            border-radius: 4px;
            display: none;
            z-index: 3003;
        }
        &:hover {
            .assigned-more_files {
                display: inline-block;
            }
        }
    }
    ::v-deep .el-table__body {
        .el-table__row {
          td {
            &:nth-last-of-type(2) {
              div.cell {
                overflow: visible;
              }
            }
          }
        }
    }
  ::v-deep .handel-more_btn {
    /*padding: 0;*/
    /*.el-icon-loading {*/
    /*  position: absolute;*/
    /*  top: 9px;*/
    /*  left: 6px;*/
    /*}*/
    /*.assigned-download {*/
    /*  display: inline-block;*/
    /*  padding: 9px 15px;*/
    /*  width: 100%;*/
    /*  height: 100%;*/
    /*}*/
  }
}
</style>
<style lang="scss">
  .el-popper {
    max-width: 500px;
  }
</style>
