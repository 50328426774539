import { get } from './api.js'
const pathApp = '/api/cancerwebapp'
const pathApp360d = '/api/cancerwebapp/360d'
// 获取用户信息
export const apiGetUserInfo = (data) =>
  get(pathApp + '/account/login-user.svc', data)

// 获取系统信息
export const apiGetBannerInfo = (data) =>
  get(pathApp360d + '/banner-info.svc', data)

// 获取图表总结信息
export const apiGetDxSummary = (data) =>
  get(pathApp360d + '/diagnosis-summary.svc', data)

// 获取异常项列表
export const apiGetDxDetail = (data) =>
  get(pathApp360d + '/diagnosis-details.svc', data)

// 获取检查列表
export const apiGetIsDetail = (data) =>
  get(pathApp360d + '/inspection-details.svc', data)

// 获取用户满意度
export const apiGetSatisfyRes = (data) =>
  get(pathApp360d + '/satisfiy-result.svc', data)

// 获取维度描述信息
export const apiGetDimensionDesc = (data) =>
  get(pathApp360d + '/dimension-desc.svc', data)

export const apiLogout = (data) =>
  get('/identityserver/account/logout.svc', data)
