import axios from 'axios'
import { ElMessageBox } from 'element-plus'
import { loginTips } from './login'
// import { getToken } from '@/utils/auth'
// import { addPending, removePending } from '@/utils/httpCancel'

export function request(router, store) {
  // create an axios instance
  const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000 // request timeout
  })

  // request interceptor
  service.interceptors.request.use(
    (config) => {
      // do something before request is sent

      // if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['X-Token'] = getToken()
      // }
      // removePending(config) // 在请求开始前，对之前的请求做检查取消操作
      // addPending(config) // 将当前请求添加到 pending 中

      return config
    },
    (error) => {
      // do something with request error
      console.log(error) // for debug
      return Promise.reject(error)
    }
  )
  // response interceptor
  service.interceptors.response.use(
    (response) => {
      store.commit('user/SET_LOGIN', 1)
      const res = response.data
      // removePending(response) // 在请求结束后，移除本次请求
      if (typeof res === 'number') {
        // if (res <= 0) {
        if (res < 0) {
          return Promise.reject()
        } else {
          return Promise.resolve(res)
        }
      } else {
        return res
      }
    },
    (error) => {
      if (error.message.includes('timeout')) {
        // 判断请求异常信息中是否含有超时timeout字符串
        Message({
          message: '请求超时，请稍后再试！',
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(error) // reject这个错误信息
      }

      // if (axios.isCancel(error)) {
      //     console.log('repeated request: ' + error.message)
      // } else {
      //     // handle error code
      // }
      // 如果是登录则自定义提示信息
      // if (config.url.match('/account/user/login.svc') && config.url.match('/account/user/login.svc').length > 0) {
      //   return Promise.reject(error)
      // }
      const errorRes = error.response
      // const { status, data } = errorRes
      // console.log('error.response', error)
      // 没有登录

      if (errorRes) {
        // 已经登录失败
        if (store.state.user.login === -2) return
        if (
          (errorRes.status && errorRes.status === 401) ||
          (errorRes.data && errorRes.data === 'Please login.')
        ) {
          // store.dispatch('user/logout')
          store.commit('user/SET_LOGIN', -2)
          loginTips(ElMessageBox.confirm, store)
        }
        const { data } = errorRes
        if (!data.Message && !data.message) return
        Message({
          // message: decodeURI(get(data.Message, 'headers.x-errormessage', '网络错误')),
          message: data.Message || data.message || '网络错误',
          type: 'error',
          duration: 5 * 1000
        })
      }

      return Promise.reject(error)
    }
  )
  return service
}
