<template>
  <div class="general">
    <CommonInfo />
    <div class="container-date">
      <el-date-picker
        v-model="data.date1"
        type="date"
        size="small"
        format="YYYY-MM-DD"
        value-format="YYYY-MM-DD"
        placeholder="请选择日期"
        :disabled-date="data.disabledDate"
        @change="handleSelectDate"
      />

      <span class="dig-date">{{ digDate }}</span><span class="last-time">{{ data.showLastTime }}</span>
    </div>
    <div class="container-sum clearfix">
      <!-- 应用健康水平 -->
      <HealthLevel
        class="sum-list health-level"
        :click-date="data.lastTime"
        :data-app-health="data.dataAppHealth"
        :data-dimension-score="data.dataDimensionScore"
        :level-catch="data.levelCatch"
        :apply-catch="data.applyCatch"
        :level-loading="data.levelLoading"
        :apply-loading="data.applyLoading"
      >
        <template #empty>
          <Empty />
        </template>
      </HealthLevel>
      <!-- 系统活跃度 -->
      <SystemActive
        class="sum-list system-active"
        :manu-summary-data="data.manuSummaryData"
        :x-data="data.manuSummaryData[0].value"
        :click-date="data.lastTime"
        :flag-catch="data.activeCatch"
        :chart-loading="data.activeLoading"
      >
        <template #empty>
          <Empty />
        </template>
      </SystemActive>
    </div>
    <!-- 最近30天 -->
    <AreaChart
      class="area-chart"
      :data-daily-scores="data.dataDailyScores"
      :monthly-avg-value="data.monthlyAvgValue"
      :annual-avg-value="data.annualAvgValue"
      :x-data-arr="data.xDataArr"
      :y-data-arr="data.yDataArr"
      :click-date="data.lastTime"
      :flag-catch="data.areaCatch"
      :chart-loading="data.areaLoading"
    >
      <template #empty>
        <Empty />
      </template>
    </AreaChart>
    <div class="key-quality clearfix">
      <!-- 系统三高一关键 -->
      <SystemKey
        :data-chart-key="data.dataChartKey"
        :click-date="data.lastTime"
        :flag-catch="data.keyCatch"
        :chart-loading="data.keyLoading"
      >
        <template #empty> <Empty /> </template></SystemKey>
      <!-- 系统应用质量 -->
      <SystemQuality
        :data-chart-quality="data.dataChartQuality"
        :click-date="data.lastTime"
        :chart-loading="data.qualityLoading"
        :flag-catch="data.qualityCatch"
        :quality-distribute-catch="data.qualityDistributeCatch"
      >
        <template #empty> <Empty /> </template></SystemQuality>
    </div>
    <div class="chart-cxo clearfix">
      <!-- 系统故障率 -->
      <SystemRate
        :data-chart-fault="data.dataChartFault"
        :click-date="data.lastTime"
        :chart-loading="data.rateLoading"
        :flag-catch="data.rateCatch"
        :rate-catch-detail="data.rateCatchDetail"
      >
        <template #empty> <Empty /> </template></SystemRate>
      <!-- cxo业务触达率 -->
      <SystemCxo
        :data-chart-cxo="data.dataChartCxo"
        :click-date="data.lastTime"
        :chart-loading="data.cxoLoading"
        :flag-catch="data.cxoCatch"
      >
        <template #empty> <Empty /> </template></SystemCxo>
    </div>
  </div>
</template>

<script>
import { Empty } from '@/components'
import CommonInfo from '@/views/components/commonInfo.vue'
import HealthLevel from '@/views/components/healthLevel.vue'
import SystemActive from '@/views/components/systemActive.vue'
import AreaChart from '@/views/components/areaChart.vue'
import SystemKey from '@/views/components/systemKey.vue'
import SystemQuality from '@/views/components/systemQuality.vue'
import SystemRate from '@/views/components/systemRate.vue'
import SystemCxo from '@/views/components/systemCxo.vue'
import {
  apiGetMauSummary,
  apiDiagnosisTime,
  apiAppHealth,
  apiDimensionScore,
  apiDailyScores,
  apiChartKey,
  apiChartQuality,
  apiChartFault,
  apiChartCxo
} from '@/api/health'

import { initRate, utilsToThousands, utilsNum } from '@/utils/value.js'
import {
  getCurrentInstance,
  reactive,
  computed,
  //  watch,
  watchEffect,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted
} from 'vue'
import { utilsOneDay, utilsAddOneDay } from '@/utils/gotoWeb.js'
import { useStore } from 'vuex'
export default {
  name: 'General',
  components: {
    Empty,
    CommonInfo,
    HealthLevel,
    SystemActive,
    AreaChart,
    SystemKey,
    SystemQuality,
    SystemRate,
    SystemCxo
  },
  setup() {
    const store = useStore()
    const { urlDate } = store.state.user.health.index

    const $global = getCurrentInstance().appContext.config.globalProperties
    const data = reactive({
      disabledDate(time) {
        // console.log('utilsOneDay(new Date())', utilsOneDay(new Date()))
        // return time.getTime() > Date.now() || time.getTime() < new Date('2021-10-20').getTime()
        return (
          time.getTime() > new Date().getTime() - 1000 * 60 * 60 * 24 ||
          time.getTime() < new Date('2021-10-20').getTime()
        )
      },
      // date1: $global.$moment(new Date(), 'YYYY-MM-DD', true).format(),
      date1: utilsOneDay(new Date()), // 昨天
      showLastTime: '',
      lastTime: '',
      dataAppHealth: {
        industryAvgValue: null,
        overPercent: '',
        systemScore: null
      }, // 1.应用健康水平
      dataDimensionScore: {
        avgScore: {
          faultRate: null,
          healthResult: null,
          mauCxoRate: null,
          scoreAll: null
        },
        tenantScore: {
          faultRate: null,
          healthResult: null,
          mauCxoRate: null,
          scoreAll: null
        }
      }, // 2.应用健康水平雷达图
      dataDailyScores: {
        monthlyAvgValue: null, // 本月均值
        annualAvgValue: null, // 本年均值
        monthAvgValues: null,
        dailyValues: []
      },
      // 3.近30天应用健康度趋势图
      dataChartKey: {
        summary: {
          value: null,
          value2: null
        },
        industryAvgValue: null,
        details: [],
        aiSuggestion: null
      },
      // 4.系统三高一关键图表
      dataChartQuality: {
        summary: {
          value: null,
          value2: null
        },
        industryAvgValue: null,
        details: [],
        aiSuggestion: null
      },
      // 5.系统应用质量图表
      dataChartFault: {
        summary: {
          value: null,
          value2: null
        },
        industryAvgValue: null,
        details: [],
        aiSuggestion: null
      },
      // 6.系统故障率图表
      dataChartCxo: {
        summary: {
          value: null,
          value2: null
        },
        industryAvgValue: null,
        details: [],
        aiSuggestion: null
      }, // 7.CXO业务触达率图表
      manuSummaryData: [
        {
          title: '月活跃度',
          activeNum: null,
          prop: 'mauRate',
          contentSlot: true,
          industryAvgValue: null,
          value: null,
          width: '50%',
          icon: 'icon-wodeyiban',
          iconBg: '#74BBC9;',
          hasBorder: false,
          padding: '0'
        },
        {
          title: '月活跃用户数',
          activeNum: null,
          prop: 'mau',
          width: '50%',
          icon: 'icon-quanburenwu',
          iconBg: '#F1725E',
          hasBorder: false,
          padding: '0'
        }
      ],
      monthlyAvgValue: '',
      annualAvgValue: '',
      xDataArr: [],
      yDataArr: [],
      levelCatch: false,
      applyCatch: false,
      activeCatch: false,
      areaCatch: false,
      keyCatch: false,
      qualityCatch: false,
      qualityDistributeCatchCatch: false,
      rateCatch: false,
      cxoCatch: false,
      levelLoading: true,
      applyLoading: true,
      activeLoading: true,
      areaLoading: true,
      keyLoading: true,
      qualityLoading: true,
      rateLoading: true,
      cxoLoading: true,
      rateCatchDetail: false
    })
    if (urlDate) {
      data.date1 = urlDate
    }
    const digDate = computed(() => {
      const res1 = handleComparedDate()
      if (!res1) {
        return ''
      }

      // const today = $global.$moment().format('YYYY-MM-DD')// 格式化比较日期
      const selectedDate = $global.$moment(data.date1) // 错误写法： $global.$moment(data.date1).format('YYYY-MM-DD')
      if (selectedDate.format('YYYY-MM-DD') === utilsOneDay(new Date())) {
        // if (selectedDate.format('YYYY-MM-DD') === (today)) {
        return '最近一次诊断时间：'
      }
      return '诊断时间：'
    })
    watchEffect(() => {})
    // watch(() => data.lastTime, () => { // 通过一个函数返回要监听的属性

    // })
    onBeforeMount(() => {})
    onMounted(() => {
      handlerSearch()
    })
    onBeforeUpdate(() => {})
    onUpdated(() => {})
    onBeforeUnmount(() => {})
    onUnmounted(() => {})
    function handlerSearch() {
      // console.log('data.date1', utilsOneDay(new Date()), data.date1)
      const realDate = utilsAddOneDay(data.date1) // 取昨天
      apiDiagnosisTime({ date: realDate })
        .then((lastTime) => {
          data.showLastTime = lastTime
          if (lastTime) {
            data.lastTime = lastTime.substring(0, 10) || ''
            // 报错不显示图
            data.levelCatch = false
            data.applyCatch = false
            data.activeCatch = false
            data.areaCatch = false
            data.keyCatch = false
            data.qualityCatch = false
            data.rateCatch = false
            data.cxoCatch = false
            // 报错不显示柱状图图
            data.rateCatchDetail = false
            // 加载不显示图
            data.levelLoading = true
            data.applyLoading = true
            data.activeLoading = true
            data.areaLoading = true
            data.keyLoading = true
            data.qualityLoading = true
            data.rateLoading = true
            data.cxoLoading = true
            getMauSummaryData() // 系统活跃度
            handleAppHealth() // 1.应用健康水平
            handleDimensionScore() // 2.应用健康水平雷达图
            handleDailyScores() // 3.近30天应用健康度趋势图
            handleChartKey() // 4.系统三高一关键图表
            handleChartQuality() // 5.系统应用质量图表
            handleChartFault() // 6.系统故障率图表
            handleChartCxo() // 7.CXO业务触达率图表
          } else {
            data.lastTime = ''
            data.levelCatch = true
            data.applyCatch = true
            data.activeCatch = true
            data.areaCatch = true
            data.keyCatch = true
            data.qualityCatch = true
            data.rateCatch = true
            data.cxoCatch = true
            // 报错不显示柱状图
            data.rateCatchDetail = true
          }
        })
        .catch(() => {})
    }
    // 1.应用健康水平
    function handleAppHealth() {
      apiAppHealth({ lastTime: data.lastTime })
        .then((res) => {
          data.dataAppHealth = res
          data.levelCatch = false
          data.levelLoading = false
        })
        .catch(() => {
          data.levelCatch = true
          data.levelLoading = false
        })
    }

    // 2.应用健康水平雷达图
    function handleDimensionScore() {
      apiDimensionScore({ lastTime: data.lastTime })
        .then((res) => {
          data.dataDimensionScore = res
          data.applyCatch = false
          data.applyLoading = false
        })
        .catch(() => {
          data.applyCatch = true
          data.applyLoading = false
        })
    }
    /**
     * 获取总分情况，比例图
     */
    function getMauSummaryData() {
      apiGetMauSummary({ lastTime: data.lastTime })
        .then((res) => {
          data.activeCatch = false
          data.activeLoading = false
          data.manuSummaryData[0].industryAvgValue = initRate(
            res.industryAvgValue,
            2,
            null,
            '%'
          )
          data.manuSummaryData[0].activeNum = initRate(
            res.mauRate,
            2,
            null,
            '%'
          )
          const val = initRate(res.mauRate, 2, null, '%')
          data.manuSummaryData[0].value = val.substring(0, val.length - 1) // 取整
          data.manuSummaryData[1].activeNum = utilsToThousands(res.mau)
          // data.manuSummaryData[1].activeNum = utilsToThousands(res.enabledUserCount)
        })
        .catch(() => {
          data.activeCatch = true
          data.activeLoading = false
        })
    }
    // 3.近30天应用健康度趋势图
    function handleDailyScores() {
      apiDailyScores({ lastTime: data.lastTime })
        .then((res) => {
          data.areaCatch = false
          data.areaLoading = false
          data.dataDailyScores = res
          data.monthlyAvgValue = utilsNum(res.monthlyAvgValue, false, 2)
          data.annualAvgValue = utilsNum(res.annualAvgValue, false, 2)
          data.xDataArr = []
          data.yDataArr = []

          res.dailyValues.forEach((item) => {
            data.xDataArr.push(item.date.substring(0, 10))
            data.yDataArr.push(utilsNum(item.value, false, 2))
          })
          data.xDataArr.reverse()
          data.yDataArr.reverse()
        })
        .catch(() => {
          data.areaCatch = true
          data.areaLoading = false
        })
    }
    // 4.系统三高一关键图表
    function handleChartKey() {
      apiChartKey({ lastTime: data.lastTime })
        .then((res) => {
          data.keyCatch = false
          data.keyLoading = false
          data.dataChartKey = res
        })
        .catch(() => {
          data.keyCatch = true
          data.keyLoading = false
        })
    }
    // 5.系统应用质量
    function handleChartQuality() {
      apiChartQuality({ lastTime: data.lastTime })
        .then((res) => {
          data.qualityDistributeCatch = Object.keys(res.details).length === 0
          data.qualityCatch = false
          data.qualityLoading = false
          data.dataChartQuality = res
        })
        .catch(() => {
          data.qualityCatch = true
          data.qualityLoading = false
        })
    }
    // 6.系统故障率图表
    function handleChartFault() {
      apiChartFault({ lastTime: data.lastTime })
        .then((res) => {
          data.rateLoading = false
          data.dataChartFault = res
          data.rateCatch = false
          if (
            data.dataChartFault.details &&
            data.dataChartFault.details.length === 0
          ) {
            data.rateCatchDetail = true
          } else {
            data.rateCatchDetail = false
          }
        })
        .catch(() => {
          data.rateCatch = true
          data.rateCatchDetail = true
          data.rateLoading = false
        })
    }
    // 7.CXO业务触达率图表
    function handleChartCxo() {
      apiChartCxo({ lastTime: data.lastTime })
        .then((res) => {
          data.cxoCatch = false
          data.dataChartCxo = res
          data.cxoLoading = false
        })
        .catch(() => {
          data.cxoCatch = true
          data.cxoLoading = false
        })
    }
    function handleSelectDate() {
      handlerSearch()
    }
    function handleComparedDate() {
      // TODO(发布时间)
      const publicDate = '2021-10-21'
      const today = $global.$moment() // $global.$moment().format('YYYY-MM-DD')
      const selectedDate = $global.$moment(data.date1)
      if (selectedDate.isBefore(publicDate) || selectedDate.isAfter(today)) {
        return false
      }
      return true
    }
    return {
      digDate,
      data,
      getMauSummaryData,
      handleDimensionScore,
      handleDailyScores,
      handleChartKey,
      handleChartQuality,
      handleChartFault,
      handlerSearch,
      handleChartCxo,
      handleSelectDate,
      handleComparedDate
    }
  },
  onUnmounted() {
    const store = useStore()
    store.state.user.health.index.urlDate = ''
  }
}
</script>
<style scoped lang="scss">
.container-date {
  padding: 10px 0;
  ::v-deep .el-input__inner {
    font-size: 14px;
    color: #333;
  }
}
.dig-date {
  display: inline-block;
  margin-left: 20px;
  position: relative;
  top: 2px;
}
.last-time {
  font-size: 14px;
  position: relative;
  top: 2px;
}
.container-sum {
  .sum-list {
    position: relative;
    float: left;
    width: calc(50% - 8px);
    margin-right: 8px;
    background: #fff;
    height: 278px;
    &:last-child {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}

.key-quality,
.chart-cxo {
  margin: 20px 0;
  .quality-container,
  .cxo-container,
  .rate-container,
  .key-container {
    position: relative;
    height: 410px;
    overflow: hidden;
    border: 1px solid #e9e9e9;
    float: left;
    width: calc(50% - 8px);
    padding: 20px;
    background: #fff;
    margin-right: 8px;
    margin-left: 0;
    padding: 15px 0 15px 0;
    &:nth-child(2) {
      margin-left: 8px;
      margin-right: 0;
    }
    ::v-deep .title {
      font-size: 14px;
      margin-bottom: 26px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      padding-left: 16px;
    }
    ::v-deep .icon-tishi-wenhao {
      display: inline-block;
      margin-left: 4px;
      position: relative;
      font-size: 14px;
      top: 1px;
      cursor: pointer;
      font-weight: normal;
    }
  }
}
.area-chart {
  padding: 0;
  background: #fff;
  margin: 12px 0 20px;
}
.health-container {
  padding: 20px 0 20px 20x;
}
.health-container,
.area-container {
  position: relative;
  ::v-deep .title {
    font-size: 14px;
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
  }
  .icon-tishi-wenhao {
    display: inline-block;
    margin-left: 4px;
    position: relative;
    font-size: 14px;
    top: 1px;
    cursor: pointer;
    font-weight: normal;
  }
}
.health-container {
  ::v-deep .title {
    margin-bottom: 0;
    padding: 16px 16px 0;
  }
}
</style>
