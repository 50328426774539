<template>
  <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
    <el-radio-button :label="false">expand</el-radio-button>
    <el-radio-button :label="true">collapse</el-radio-button>
  </el-radio-group> -->
  <div :class="{ 'has-logo': showLogo, 'collapse': isCollapse }">
    <el-scrollbar wrap-class="scrollbar-wrapper" :style="{height: `calc( 100% - 60px )`}">
      <el-menu
        :default-active="defaultActive"
        class="sidebar-box"
        :collapse="isCollapse"
        @open="handleOpen"
        @close="handleClose"
      >
        <!-- 【TODO]循环 -->
        <el-menu-item index="360d" @click="gotoRoute('360d')">
          <i class="icon-zuhucanshu iconfont" />
          <!-- <router-link to="/360d"></router-link> -->
          <!-- <a @click.native="gotoRoute('360d')></a> -->
          <a>360概览</a>
        </el-menu-item>
        <el-menu-item index="list" @click="gotoRoute('health')">
          <i class="icon-biaodandingzhimoban iconfont" />
          <!-- <router-link to="/health">异常列表</router-link> -->
          <a>异常预警</a>
        </el-menu-item>
        <el-sub-menu index="success">
          <template #title>
            <i class="icon-a-kehu3601 iconfont" />
            <a>客户成功计划</a>
          </template>
          <el-menu-item index="detail" @click="gotoRoute('success/detail')">计划详情</el-menu-item>
          <el-menu-item index="achievement" @click="gotoRoute('success/achievements')">交付成果</el-menu-item>
        </el-sub-menu>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {
    const isCollapse = ref(false)
    const showLogo = ref(true)
    const handleOpen = (key, keyPath) => {
      console.log(key, keyPath)
    }
    const handleClose = (key, keyPath) => {
      console.log(key, keyPath)
    }

    return {
      showLogo,
      isCollapse,
      handleOpen,
      handleClose
    }
  },
  computed: {
    defaultActive() {
      return this.$route.name
    }
  },
  mounted() {
  },
  methods: {
    gotoRoute(pathName) {
      this.$router.push('/' + pathName)
    }
  }
})
</script>

<style lang="scss" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu{
  .iconfont{
      margin-right: 10px;
    }
  .el-sub-menu {
    ::v-deep .el-menu-item {
      padding-left: 46px !important;
    }
  }
}
</style>
