<template>
  <div class="score-item" :style="{ width }">
    <div class="score-item_title">
      <span class="iconfont" :class="icon" :style="{backgroundColor: iconBg}" />
      <span class="score-item_label">{{ title }}</span>
    </div>

    <div class="score-item_num" :style="{color: color || '#000000'}">
      {{ activeNum || 0 }}
      <div v-if="contentSlot" class="content-slot">行业均值 <span>{{ industryAvgValue||'--' }}</span></div>
    </div>
  </div>
  <!-- <Empty v-else description="暂无数据" /> -->
</template>

<script>

// import {Empty}  from '@/components'
// components:{Empty},
export default {
  name: 'ScoreItems',
  components: {},
  props: {
    width: { // 当前宽度
      type: String,
      default: '100%'
    },
    activeNum: { // 当前分数
      type: [String, Number],
      default: ''
    },
    title: { // 当前模块标题
      type: String,
      default: '100%'
    },
    contentSlot: { // 是否为自定义内容
      type: Boolean,
      default: false
    },
    icon: { // 当前图标（iconfont类型的图标）
      type: String,
      default: ''
    },
    iconBg: { // 当前图标背景色
      type: String,
      default: '#74BBC9'
    },
    color: { // 文字颜色
      type: String,
      default: '#000'
    },
    industryAvgValue: { // 当前分数
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {
    industryAvgValue() {

    }
  },
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped lang='scss'>
  .score-item {
    &_title {
      margin-bottom: 8px;
    }
    .iconfont {
      display: inline-block;
      width: 23px;
      height: 23px;
      color: #fff;
      text-align: center;
      line-height: 23px;
      background: #74BBC9;
      border-radius:50%;
      vertical-align: middle;
      margin-right: 12px;
      font-size: 13px;
    }
    &_label {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      vertical-align: middle;
    }
    &_num {
      padding-left: 33px;
      font-size: 34px;
      font-weight: 500;
      color: #000000;
      .content-slot{
        font-size: 14px;
        margin-top: -2px;
        color:rgba(0,0,0,0.38);
         span{
           display: inline-block;
           margin-left: 6px;
         }
      }
    }
  }
</style>
