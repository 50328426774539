// 常量定义文件

// 请求公共部分
// export const BASE_URL = {
//   WEB: 'mysm',
//   ADMIN: 'mysmadmin',
// };

// title
// export const PAGETITLE = '服务解决方案管理器';

// 用户类型
export const USERTYPE = [
  { 0: '一般用户' },
  { 1: '管理员' },
  { 2: '域身份验证' }
]

//  首页图标
// export const INDEXICON = {
//   '000': 'icon-licaijingxuan', // 精选
//   '001': 'icon-gongdanguanli-jianying', // 日常
//   '002': 'icon-xitongshezhi-jianying', // 检测
//   '003': 'icon-gongzuotaixuanzhong', // 诊断
//   '004': 'icon-hotfixyidongrexiufu', // 修复
//   '005': 'icon-shujuzidian-jianying', // 优化
//   '006': 'icon-shujutongjixuanzhong', // 运营
//   '007': 'icon-kehuguanlixuanzhong', // 培训
//   '008': 'icon-dingdanguanlixuanzhong', // 需求
//   '009': 'icon-developer-fill', // 开发
//   '010': 'icon-yihuoxuanzhong', // 测试
//   '011': 'icon-zhuomianbushu', // 部署
// };

// // 翻页参数
// export function PAGECONFIG(pageSizes) {
//   const activePageSizes = pageSizes || [2, 3, 4, 5, 10];
//   return {
//     pageSize: 20,
//     page: 1,
//     pageSizes: activePageSizes.map((val) => {
//       return val * 10;
//     }),
//   };
// }
// 常量定义文件

// 请求公共部分
import { getActiveHttp } from '@/components/HeaderNav/topNav/js/constantFun.js'

export const BASE_URL = {
  LOGIN: 'identityserver', // 登陆
  WEB: 'mysm', // 前台
  ADMIN: 'mysmadmin', // 后台
  PATCH: 'productpatch', // 补丁管理
  DELIVER: 'document', // 运维交付物
  METRICS: 'api/metrics', // 日志
  WSOCKET: 'mysmws', // 运维交付物
  PLAN: 'maintenanceplan', // 维护计划
  TICKET: 'ticket', // 工单
  INSP: 'appinspection', // 应用巡检
  DATAINS: 'datainspection', // 数据巡检
  USERBEHAV: 'api/cancerwebapp',
  RISK: 'riskwarning', // 预警风控
  BACKUP: 'cloudbackup', // 云灾备工具
  SCREEN: 'screen' // 服务大屏
}

// logo地址
export const LOGO = {
  TOOL:
    'https://myom-release.oss-cn-hangzhou.aliyuncs.com/mysm/resource/tools/',
  PATCH: 'productpatch/',
  DOCUMENT: 'ywdelivery/',
  PLAN: 'maintenanceplan/',
  TICKET: 'ticket/',
  INSP: 'appins/',
  DATAINS: 'datains/', // 数据巡检
  USERBEHAV: 'userbehav/',
  RISK: 'risk/', // 预警风控
  SCREEN: 'largescreen/', // 服务大屏
  BACKUP: 'cloudbackup/' // 云灾备工具
}

// title 服务解决方案管理器
export const PAGETITLE = '服务云'

// 登陆页面跳转配置
export const activePath = getActiveHttp(
  'https://auth.fdcyun.com',
  'https://auth-test.fdcyun.com',
  `http://localhost:9898`
)

// //  首页图标
export const INDEXICON = {
  '000': 'icon-licaijingxuan', // 精选
  '001': 'icon-gongdanguanli-jianying', // 日常
  '002': 'icon-xitongshezhi-jianying', // 检测
  '003': 'icon-gongzuotaixuanzhong', // 诊断
  '004': 'icon-hotfixyidongrexiufu', // 修复
  '005': 'icon-shujuzidian-jianying', // 优化
  '006': 'icon-shujutongjixuanzhong', // 运营
  '007': 'icon-kehuguanlixuanzhong', // 培训
  '008': 'icon-dingdanguanlixuanzhong', // 需求
  '009': 'icon-developer-fill', // 开发
  '010': 'icon-yihuoxuanzhong', // 测试
  '011': 'icon-zhuomianbushu' // 部署
}

// 翻页参数
export function PAGECONFIG(pageSizes, pageSize, count) {
  const activePageSizes = pageSizes || [2, 3, 4, 5, 10]
  return {
    pageSize: pageSize || 20,
    page: 1,
    pageSizes: activePageSizes.map((val) => {
      return val * (count || 10)
    })
  }
}

//  格式化时间
export function formaterTime(date, month) {
  if (month) {
    return moment(new Date(date))
      .subtract(month, 'months')
      .format('YYYY-MM-DD HH:mm:ss')
  } else {
    return moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss')
  }
}

// 配置项 是否
export const ISTRUEORFALSE = [
  { Txt: '是', Val: 1 },
  { Txt: '否', Val: 0 }
]

// 禁用启用
export const DISABLEDORNOT = [
  { Txt: '启用', Val: 1 },
  { Txt: '禁用', Val: 0 }
]

// 数据源类型
export const DATASOURCETYPE = [
  { Txt: '客户ERP', Val: 'erp' },
  { Txt: '云运维', Val: 'yyw' }
]

// 数据源类型列表
export const DATABASETYPELIST = [
  { Txt: '数仓', Val: 'milkyway' },
  { Txt: '租户库', Val: 'tenant' },
  { Txt: '主库', Val: 'master' },
  { Txt: 'BI库', Val: 'bi' },
  { Txt: '告警库', Val: 'alert' },
  { Txt: '运维主数据库', Val: 'cus' },
  { Txt: '移动日志库', Val: 'mobile' }
]

// 客户ERP对应的值
export const DATAERP = [
  { Txt: '业务库', Val: 'erp' },
  { Txt: '日志库', Val: 'log' },
  { Txt: 'PA库', Val: 'pa' },
  { Txt: 'MySQL库', Val: 'mysql' }
]

// 规则类别
export const RULETYPE = [
  { Txt: '准确性', Val: '准确性' },
  { Txt: '完整性', Val: '完整性' },
  { Txt: '规范性', Val: '规范性' }
]

// 规则级别
export const RULELEVEL = [
  { Txt: '集团级', Val: 1 },
  { Txt: '公司级', Val: 2 },
  { Txt: '项目级', Val: 3 }
]
// 所属维度
export const BELONGTYPE = [
  { Txt: '系统活跃情况', Val: '系统活跃情况' },
  { Txt: '业务功能应用', Val: '业务功能应用' },
  { Txt: '管理价值输出', Val: '管理价值输出' },
  { Txt: '增值模块', Val: '增值模块' }
]

export const RULELEVELOBJ = {
  1: '集团级',
  2: '公司级',
  3: '项目级'
}

// 筛选类别
export const PLANETTYPE = [
  { Label: '运维平台', Value: 'yyw' },
  { Label: 'RDC', Value: 'rdc' },
  { Label: '云助手', Value: 'yzs' },
  { Label: '云客服', Value: 'ykf' },
  { Label: 'SCRM', Value: 'scrm' }
]

// 场景类别
export const SCENETYPE = [
  { Txt: '核心场景', Val: 0 },
  { Txt: '关键场景', Val: 1 },
  { Txt: '普通场景', Val: 2 }
]
export const SCENETYPEOBJ = {
  0: '核心场景',
  1: '关键场景',
  2: '普通场景'
}

// 修复方式
export const REPAIRTYPE = [
  { Txt: '自动修复', Val: 1 },
  { Txt: '手动修复', Val: 2 }
]

// 维护类型
export const PLANTYPE = {
  0: '默认维护',
  1: '集成上线',
  2: '全新安装',
  3: '专项需求上线',
  4: '零星需求与修复',
  5: '紧急BUG修复',
  6: '软硬件环境变更'
}

// 告警类别
export const WARNING_TYPE = [
  { label: '不限', value: '-1', type: ['监控告警', '数据巡检', '应用巡检'] },
  { label: '服务器', value: '服务器', type: ['监控告警'] },
  { label: 'WEB站点', value: 'WEB站点', type: ['监控告警'] },
  { label: 'Windows服务', value: 'Windows服务', type: ['监控告警'] },
  { label: '数据库', value: '数据库', type: ['监控告警'] },
  { label: '日志', value: '日志', type: ['监控告警'] },
  { label: '第三方服务', value: '第三方服务', type: ['监控告警'] },
  { label: '产品', value: '产品', type: ['监控告警'] },
  { label: 'URL', value: 'URL', type: ['监控告警'] },
  { label: 'Redis', value: 'Redis', type: ['监控告警'] },
  { label: 'MySQL', value: 'MySQL', type: ['监控告警'] },
  { label: 'Kubernetes', value: 'Kubernetes', type: ['监控告警'] },
  { label: '执行失败', value: '执行失败', type: ['数据巡检', '应用巡检'] }
]

// 运维状态
export const DEV_OPS_STATUS = [
  { label: '不限', value: '-1' },
  { label: '实施期', value: '实施期' },
  { label: '免维期', value: '免维期' },
  { label: '首年服务期', value: '首年服务期' },
  { label: '服务期', value: '服务期' },
  { label: '短期脱保', value: '短期脱保' },
  { label: '长期脱保', value: '长期脱保' },
  { label: '丢失客户', value: '丢失客户' },
  { label: '休眠期', value: '休眠期' },
  { label: '其他', value: '其他' }
]

// 部署方式
export const DEPLOY_TYPE = [
  { label: '不限', value: '-1' },
  { label: '上云', value: '上云' },
  { label: '下云', value: '下云' },
  { label: '私有化', value: '私有化' }
]
// 优先级
export const PRIORITY = [
  { label: '不限', value: '-1' },
  { label: 'P1', value: 'P1', cls: 'error' },
  { label: 'P2', value: 'P2', cls: 'warning' }
]

// 事件类型
export const EVENT_TYPE = [
  { label: '不限', value: '-1' },
  { label: '数据巡检', value: '数据巡检' },
  { label: '应用巡检', value: '应用巡检' },
  { label: '监控告警', value: '监控告警' }
]

// 事件状态
// 告警处理状态：-1不处理， 0未处理，1处理中，2已处理，3作废，4挂起 ,5已解决
export const EVENT_STATUS = [
  { label: '不限', value: -1 },
  { label: '已创建', value: 0, cls: '' },
  { label: '处理中', value: 1, cls: 'doing' },
  { label: '已处理', value: 2, cls: 'warning' },
  // { label: '已作废', value: 3, cls: 'other' },
  { label: '已挂起', value: 4, cls: 'info' },
  { label: '已解决', value: 5, cls: 'success' }, //
  { label: '已关闭', value: 6, cls: 'close' }
]

// 事件对象
export const EVENT_OBJECT = [
  { value: '硬件环境', label: '硬件环境' },
  { value: '网络环境', label: '网络环境' },
  { value: '操作系统', label: '操作系统' },
  { value: '明源产品', label: '明源产品' },
  { value: '数据库', label: '数据库' },
  { value: '第三方产品', label: '第三方产品' },
  { value: '容器环境', label: '容器环境' },
  { value: '测试环境', label: '测试环境' },
  { value: '其它', label: '其它' }
]

// 是否超时
export const OVERTIME = [
  { label: '不限', value: -1 },
  { label: '超时', value: 1 },
  { label: '未超时', value: 0 }
]

// 自愈情况
export const SELF_CURE = [
  { label: '不限', value: -1 },
  { label: '非自愈', value: 0 },
  { label: '待自愈', value: 1 },
  { label: '自愈中', value: 2 },
  { label: '自愈成功', value: 3 },
  { label: '自愈失败', value: 4 }
]

// 自动确认
export const AUTO_CONFIRM = [
  { label: '不限', value: -1 },
  { label: '非自动确认', value: 0 },
  { label: '自动确认', value: 1 }
]
// 事件升级
export const UPGRADE = [
  { label: '不限', value: -1 },
  { label: '未升级', value: 0 },
  { label: '已升级', value: 1 },
  { label: '升级完成', value: 2 }
]

// 常量定义文件

//  文件类型
export const FILETYPEICON = {
  EXCEL: 'Excel',
  IMAGE: 'Image',
  PPT: 'PPT',
  WORD: 'Word',
  PDF: 'Pdf',
  ZIP: 'Zip',
  FILE_LINK: 'FILE_LINK',
  DEFAULT: 'Default'
}

// 操作动作
export const HANDLE_TYPE = [
  { label: '汇报进展', value: '汇报进展', isHide: false },
  { label: '挂起', value: '挂起', isHide: false },
  { label: '取消挂起', value: '取消挂起', isHide: true },
  // { label: '更新告警解决信息', value: '更新告警解决信息', isHide: true },
  // { label: '更新告警关闭信息', value: '更新告警关闭信息', isHide: true },
  { label: '升级事件单', value: '升级事件单', isHide: false },
  { label: '升级问题单', value: '升级问题单', isHide: true }, // 已解决的才有
  { label: '已解决', value: '已解决', isHide: false },
  { label: '转派', value: '转派', isHide: false },
  { label: '关闭', value: '关闭', isHide: false }
]

// 挂起原因
export const SUSPEND_REASON = [
  {
    label: '等待授权',
    value: '1',
    optionTitle:
      '1、已通知一线，待一线授权远程； 2、已通知客户，待客户审核后进行处理。'
  },
  {
    label: '客户/区域联系不上',
    value: '2',
    optionTitle:
      '不同时段联系2次联系不上，P1高风险事件挂起前必须尝试电话联系；'
  },
  {
    label: '待客户验证',
    value: '3',
    optionTitle: '方案已提供，客户无法配合验证，需等客户最终验证结果'
  },
  {
    label: '等待区域回复',
    value: '4',
    optionTitle:
      '需要区域上门协助或者沟通，如需要区域去和客户沟通增购用户数、客户个性化代码问题需要区域去和客户协商修复等'
  }
]

// 影响等级
export const INFLUENCE_GRADE = [
  { label: '低', value: 0 },
  { label: '中', value: 1 },
  { label: '高', value: 2 }
]

// 告警原因
export const WARNING_REARON = [
  { label: '不限', value: -1 },
  { label: '原因待查', value: 7 }
]

export const CXO_INFO = [
  { Name: 'CEO', Id: 'CEO' },
  { Name: 'CFO', Id: 'CFO' },
  { Name: 'CIO', Id: 'CIO' },
  { Name: 'COO', Id: 'COO' },
  { Name: '董事长', Id: '董事长' },
  { Name: '营销总', Id: '营销总' },
  { Name: '成本总', Id: '成本总' },
  { Name: '区域总', Id: '区域总' },
  { Name: '副总裁', Id: '副总裁' },
  { Name: '其他', Id: '其他' }
]

// 导入提示样式
/**
 * 导入文件的错误提示
 * @param Total 总共导入条数
 * @param Success 成功条数
 * @param Fail 失败条数
 * @param ErrorList 失败列表 （数据结构为[{ testName:'需要展示的错误行名称', ErrorDescription: '这个是固定的，失败说名' }]）
 * @param ErrorInfo 展示的信息（prop：需要展示的错误行字段名称 label：表头名称）
 * @param Update 更新
 * @param Label 当前是什么操作
 * @returns {string}
 */
export function UPLOADFILEMESSAGEHTML(
  Total,
  Success,
  Fail,
  ErrorList,
  ErrorInfo,
  Update,
  Label
) {
  let errorLists = `<div class="import-table_tr"><span class="import-table_td">${ErrorInfo.label ||
    '规则名称'}</span><span class="import-table_td">失败原因</span></div>`
  ErrorList.forEach((val) => {
    const { ErrorDescription, ErrDesc } = val
    errorLists += `
        <div class="import-table_tr"><span class="import-table_td" title="${
  val[ErrorInfo.prop]
}">${
  val[ErrorInfo.prop]
}</span><span class="import-table_td" title="${ErrorDescription ||
      ErrDesc}">${ErrorDescription || ErrDesc}</span></div>
        `
  })
  let html = `<div class="import-tips_title">共${Label ||
    '导入'}<span class="import-number total">${Total ||
    0}</span>条，其中成功<span class="import-number success">${Success ||
    0}</span>条，失败<span class="import-number fail">${Fail ||
    0}</span>条 </div>`
  if (ErrorInfo.label) {
    html = `<div class="import-tips_title">共${Label ||
      '导入'}<span class="import-number total">${Total ||
      0}</span>条，其中新增<span class="import-number success">${Success ||
      0}</span>条，更新<span class="import-number success">${Update ||
      0}</span>条，失败<span class="import-number fail">${Fail ||
      0}</span>条 </div>`
  }
  return ErrorList.length <= 0
    ? html
    : `${html}<div class="import-error_content">${errorLists}</div>`
}

// 星期选择
export const WEEKDAYS = [
  { label: '周一', type: 'week', value: 1 },
  { label: '周二', type: 'week', value: 2 },
  { label: '周三', type: 'week', value: 3 },
  { label: '周四', type: 'week', value: 4 },
  { label: '周五', type: 'week', value: 5 },
  { label: '周六', type: 'week', value: 6 },
  { label: '周日', type: 'week', value: 0 }
]

// 工具的appcode
export const APP_CODE = {
  login: 'mysmlogin', // 登录
  admin: 'mysmadmin', // 后台
  web: 'mysm', // 前台
  backup: 'cloudbackup', // 云灾备工具
  inspection: 'appins', // 两巡检
  datains: 'sjxj', // 数据巡检
  datainsadj: 'sjxj', // 数据巡检工具后台
  deliver: 'ywdelivery', // 服务成果
  screen: 'dashboard', // 服务大屏
  ticket: 'ticket', // 工单
  health: 'apphealth', // 应用健康在线
  patch: 'productpatch', // 补丁管理
  plan: 'maintenanceplan', // 维护计划
  insp: 'appins', // 应用巡检
  inspadj: 'appins', // 应用巡检工具后台
  userbehav: 'userBehaviorAnalysis', // 用户行为分析
  risk: 'riskwarning' // 预警风控
}
export const APP_NAME = {
  web: '服务云前台',
  mysmlogin: '服务云登录',
  dashboard: '服务大屏',
  mysmadmin: '服务云管理后台',
  mysmhelper: '服务云小助手',
  cloudbackup: '云灾备工具',
  inspection: '智能巡检', // 两巡检
  sjxj: '数据巡检',
  ywdelivery: '服务成果',
  ticket: '工单工具',
  apphealth: '应用健康在线',
  productpatch: '补丁管理工具',
  maintenanceplan: '变更管理',
  appins: '应用巡检',
  userBehaviorAnalysis: '用户行为分析',
  riskwarning: '预警风控'
}

// 计划状态
export const PLAN_STATUS = [
  { Name: '全部', Id: -1 },
  { Name: '待确认', Id: 0, cls: 'warning', handleText: '制定计划' },
  { Name: '未开始', Id: 2, cls: 'info' },
  { Name: '审批中', Id: 1, cls: '' },
  { Name: '交付中', Id: 3, cls: 'doing', handleText: '执行计划', btnText: '调整计划' },
  { Name: '已完成', Id: 4, cls: 'success', handleText: '修改计划' }
]
