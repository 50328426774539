<template>
  <div class="table-container">
    <!--   批量操作生效的内容   -->
    <div v-if="tableConfig.selectMore && tableConfig.select.length" class="handel-more">
      <div class="handel-info">
        <em class="el-icon-close" @click="cancelSelectAll"/>
        <span class="handel-info_total">已选中<span class="handel-info_number">{{ tableConfig.select.length }}</span>条</span>
      </div>
      <el-button
        v-for="(item, index) in handleMoreBtn"
        :key="index"
        class="handel-more_btn"
        size="small"
        type="default"
        :loading="item.isLoading"
        :icon="item.icon"
        @click="onClickToHandleMore(item)"
      >
        <template v-if="!item.slot">{{ item.name }}</template>
        <slot :name="item.slot" :slot-data="tableConfig.select"></slot>
      </el-button>
      <slot name="handelMore" />
    </div>
    <el-table
      ref="myTable"
      v-loading="isLoading"
      :border="resizable"
      :row-key="rowKey || 'Id'"
      :data="tableData"
      :height="height"
      element-loading-text="加载中，请稍后..."
      :lazy="isLazy"
      :load="loadData"
      :size="'small'"
      :default-expand-all="expandAll"
      :default-sort="{ prop: tableConfig.sortProp, order: 'descending' }"
      :tree-props="treeProps"
      @selection-change="clickToTableChangeRows"
      @select-all="clickToTableChangeAll"
      @select="clickToTableChangeSelectRows"
      @row-click="onSelectCheckbox"
    >
      <!--   多选   -->
      <el-table-column
        v-if="tableConfig.selectMore"
        type="selection"
        width="45"
        :resizable="false"
      ></el-table-column>
      <template v-for="(column, index) in columnConfig">
        <el-table-column
          v-if="column.isHide"
          :key="index"
          :sortable="Boolean(column.isSortable) ? 'custom' : false"
          :show-overflow-tooltip="showOverflowTooltip || column.showOverflowTooltip"
          :prop="column.prop"
          :width="column.width"
          :min-width="column.minWidth || 100"
          :max-width="column.maxWidth"
          :resizable="column.resizable"
          :fixed="column.fixed"
          :label="column.label"
          :align="column.align"
          class="column-table"
        >
          <template #default="scope">
            <!-- slot 自定义列  -->
            <template v-if="column.slot">
              <slot :name="column.slot" :row="scope.row" :$index="scope.$index" />
            </template>
            <!--如果当前列存在格式化则走第二个div-->
            <template
              v-else-if="column.formatter"
              v-html="column.formatter[scope.row[column.prop]] ? column.formatter[scope.row[column.prop]].replace('${value}', scope.row[column.prop]) : scope.row[column.prop]"
            />
            <!--否则则走第三个div-->
            <template v-else>{{ scope.row[column.numberNotToLine] ? scope.row[column.prop] : (scope.row[column.prop] || '--') }}</template>
          </template>
        </el-table-column>
      </template>

      <!-- 表格操作列设置 -->
      <el-table-column
        v-if="tableConfig.showTableOption"
        prop="option"
        :width="tableConfig.cellOptionWidth"
        :label="tableConfig.handleLineText || '操作'"
        :fixed="tableConfig.fixedHandleColumn ? 'right': false"
      >
        <template #default="scope">
          <!-- 自定义操作类型 -->
          <slot
            name="handleSlot"
            :data="{row: scope.row}"
            :row="scope.row"
            :$index="scope.$index"
          />
          <template v-for="(option,key) in handleOption">
            <!--       自定义某一个按钮        -->
            <slot
              v-if="option.slot"
              :name="option.slot"
              :item="option"
              :row="scope.row"
              :$index="scope.$index"
            />
            <!--     操作按钮为文字     -->
            <span
              v-else
              :key="key"
              :title="option.name"
              :class="`${option.icon || ''} handler-text ${option.class || ''}`"
              @click.stop="clickToTableOptionBtn({option, scope})"
            >{{ option.name }} </span>
          </template>
        </template>
      </el-table-column>

      <template #empty>
        <Empty :is-admin="true" ></Empty>
      </template>

    </el-table>
    <!--表格底部布局，表格分页设置 jumper-->
    <el-pagination
      v-if="!!tableConfig.showPagination && tablePageConfig"
      class="my-table-footer clearfix"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="tablePageConfig.page"
      :page-sizes="tablePageConfig.pageSizes"
      :page-size="tablePageConfig.pageSize"
      :total="tablePageConfig.count"
      :pager-count="tableConfig.pagerCount"
      background
      @size-change="clickToTableSizeChange"
      @current-change="clickToTableCurrentChange"
    />
  </div>
</template>

<script>
import { Empty } from '@/components'
import { reduceArr } from '@/utils'
export default {
  name: 'MyTable',
  components: { Empty },
  props: {
    thisTable: { // 当前table的this指向
      type: Object,
      default: () => {}
    },
    columnConfig: { // 列表信息
      type: Object,
      default: () => {
        return []
        //  [{ label: '告警主题', prop: 'subject', width: 'auto', isHide: true, formatter: {0: '测试'}, slot: 'test' }]
      }
    },
    getTableParams: { // 搜索参数
      type: Object,
      default: () => {
        return {}
      }
    },
    handleMoreBtn: { // 批量操作按钮
      type: Array,
      default: () => {
        return []
      }
    },
    tableConfig: { // 表格参数信息
      type: Object,
      default: () => {
        return {
          tableWidth: '100%', // 表格宽度
          // hasExpand: false, // 是否显示展开
          showTableOption: true, // 是否显示操作列
          cellOptionWidth: '100px', // 操作列宽
          showPagination: true, // 是否显示翻页
          selectMore: true, // 是否显示多选
          pagerCount: 7, // 翻页按钮个数
          fixedHandleColumn: true, // 是否固定操作列
          hasExpandWidth: 1, // 展开列的宽度
          handleLineText: '操作', // 操作列表头名称
          sortProp: '', // 默认排序字段和排序方式
          cancelLine: [],
          select: []
        }
      }
    },
    tablePageConfig: { // 表格翻页参数
      type: Object,
      default: () => {
        return null
      }
    },
    handleOption: { // 操作列按钮
      type: Array,
      default: () => {
        return []
      }
    },
    sortProp: { // 远程排序的字段
      type: Object,
      default: () => {
        return {}
      }
    },
    height: { // 表格内容高度
      type: [Number, String],
      default:() => {
        return null
      }
    },
    isLazy: { // 是否懒加载
      type: Boolean,
      default:() => {
        return false
      }
    },
    expandAll: { // 是否展开所有
      type: Boolean,
      default:() => {
        return false
      }
    },
    resizable: { // 是否可以拖拽改变列宽
      type: Boolean,
      default:() => {
        return false
      }
    },
    rowKey: { // 当前行的唯一标识
      type: String,
      default: () => {
        return 'Id'
      }
    },
    treeProps: { // 展开子节点对应的字段名称
      type: Object,
      default: () => {
        return {
          children: 'children', hasChildren: 'hasChildren'
        }
      }
    },
    showOverflowTooltip: { // 是否超出省略号
      type: Boolean,
      default: () => {
        return true
      }
    },
    httpFun: { // 请求方法
      type: Function,
      default: () => {
        return null
      }
    },
  },
  watch: {
    getTableParams: {
      deep: true,
      handler(getTableParams) {
        this.initTable(getTableParams)
      }
    }
  },
  data() {
    return {
      tableData: [],
      isLoading: false
    }
  },
  computed: {},

  created() {
  },
  mounted() {
  },
  methods: {
    initTable(getTableParams) {
      if (!this.httpFun) return
      this.isLoading = true
      this.httpFun({ ...getTableParams  }).then((res) => {
        this.tableData = res || []
        this.$emit('afterGetDataSuccess', res || [])
      }).finally(() => {
        this.isLoading = false
      })
    },

    // 点击展开更多的时候，懒加载的内容
    loadData(tree, treeNode, resolve) {
      this.$emit('loadData', { tree, treeNode, resolve })
    },

    /**
            * 自定义表格按钮统一操作
            *  判断父组件函数是否存在，如果存在则执行，否则不执行
            * @Method formSubmit
            */
    clickToTableOptionBtn({ option, scope }) {
      this.thisTable[option.fun] ? this.thisTable[option.fun].call(this, { option, scope }) : ''
    },

    //  批量操作按钮
    onClickToHandleMore(item) {
      this.thisTable[item.fun] ? this.thisTable[item.fun].call(this, item) : ''
    },

    /**
            * 表格分页
            * 点击每页条数下拉列表进行选择数据进行查询
            * @Method tableSizeChange
            */
    clickToTableSizeChange(pageSize) {
      const { count, page } = this.tablePageConfig
      const maxPage = Math.ceil(count / pageSize)
      this.tablePageConfig.pageSize = pageSize
      this.tablePageConfig.page = page <= maxPage ? (page || 1) : (maxPage || 1)
      this.$emit('changePageSize', pageSize)
      this.$emit('changeCurrentPage', this.tablePageConfig.page)
    },

    /**
            * 表格分页
            * 首先数据选择渲染this.table.page数据
            * 其次调用初始化table数据
            * @Method tableCurrentChange
            */
    clickToTableCurrentChange(page) {
      this.tablePageConfig.page = page
      this.$emit('changeCurrentPage', page)
    },

    // 默认select选中
    // checkboxSelect(row, rowIndex) {
    // return this.$emit('checkboxSelect', row, rowIndex)
    // },

    // 监听选中项的变化
    clickToTableChangeRows(row) {
      this.tableConfig.select = row
      this.$emit('changeSelectRow', row)
    },

    // 单行选中
    clickToTableChangeSelectRows(selection, row) {
      // 同样的选项进行去除重复操作
      this.tableConfig.select = reduceArr([...this.tableConfig.select, ...selection], this.rowKey)
      // 判断当前是否取消了某一行
      const selected = selection.length && selection.indexOf(row) !== -1 // true表示选中 false表示取消
      if (!selected) {
        const cancelLine = this.tableConfig.cancelLine || []
        this.tableConfig.cancelLine = reduceArr([...cancelLine, row], this.rowKey)
      }
    },

    // 全选
    clickToTableChangeAll(selection) {
      if (selection.length <= 0) { // 如果是空，则表示取消了当前所有的=
        this.tableConfig.cancelLine = this.tableData
      } else { // 如果不是空，则表示选中了所有，取消了 0
        this.tableConfig.cancelLine = [] // todo：这里最好写成取消了默认的选中项
        this.tableConfig.select = selection
      }
      this.tableConfig.cancelLine = reduceArr(this.tableConfig.cancelLine, this.rowKey)
      this.$emit('onSelectedAll', { selection, cancelLine: this.tableConfig.cancelLine })
    },

    // 点击行选中
    onSelectCheckbox(val) {
      // this.$refs.myTable.toggleRowSelection(val)
    },
    cancelSelectAll() {
      this.$refs.myTable.clearSelection()
    }
  }
}
</script>

<style lang='scss'>
.table-container{
  .handel-more {
    width: 100%;
    height: 52px;
    line-height: 52px;
    padding-left: 20px;
    font-size: 13px;
    color: #222222;
    border-bottom: 1px solid rgba(233,235,238,1);
    .el-icon-close {
      cursor: pointer;
      &:hover {
        color: #507AFE;
      }
      &:active {
        color: rgba(#507AFE, .7);
      }
    }
    .handel-info_total {
      margin: 0 10px;
    }
    .handel-info_number {
      color: #507AFE;
    }
    .handel-info {
      display: inline-block;
      width: fit-content;
    }
  }
   .el-table {
     .el-table__header,
     .el-table__body,
     .el-table__footer{
       width: 100% !important;
       table-layout: fixed !important;
     }
      &.el-table--border{
         border-color: transparent !important;
         .el-table__cell {
            border-right-color: transparent !important;
         }
      }
      &.empty {
         &::before{
            display: none;
         }
      }
      .cell {
         &.el-tooltip {
            font-size: 13px;
            min-width: 100%;
            width: 100% !important;
            overflow: hidden;
            color: #222 !important;
         }
      }
      .column-table_drag {
         width: 2px;
         height: 50%;
         background: #E9EBEE;
         position: absolute;
         top: 50%;
         right: 0;
         display: none;
         transform: translateY(-50%);
      }
      /* 表头 开始 */
      .el-table__header {
         tr, th {
            background-color:  #FBFBFB;
         }
         th {
            color: #222222;
            border-bottom: 1px solid #E9EBEE;
            padding: 3px 0;
            height: 40px;
            position: relative;
           div{
             font-size: 13px;
             font-weight: 400;
           }
            //border-right: 1px solid #E9EBEE;
            &:last-of-type{
               border-right: unset;
            }
            &.el-table-column--selection {
               .cell {
                  padding-left: 20px;
               }
            }
            &:hover {
               .column-table_drag {
                  display: inline-block;
               }
            }
         }
      }
      /* 表头 结束 */

      /* 表格内容  开始  */
      .el-table__body {
         tr{
            td{
               color: #222222;
               font-weight: 400;
               &.el-table__expanded-cell {
                  padding: 20px;
                  background-color: #FAFDFF;
               }
            }
            &.hover-row {
               .table-checkbox_handle{
                  opacity: 1;
               }
            }
         }
         // 有子节点 且未展开
         .el-table__expand-icon {
            vertical-align: middle;
            margin-top: -2px;
            .el-icon-arrow-right:before {
               background: url("~@/assets/common/right-icon.png") no-repeat center center;
               content: "";
               display: block;
               width: 20px;
               height: 20px;
               font-size: 20px;
               background-size: 20px;
            }
            &.el-table__expand-icon--expanded {
               transform: none;  // 展开折叠时不进行动画，否则图标会旋转
               .el-icon-arrow-right:before { // // 有子节点 且已展开
                  background: url("~@/assets/common/down-icon.png") no-repeat center center;
                  content: "";
                  display: block;
                  width: 20px;
                  height: 20px;
                  font-size: 20px;
                  background-size: 20px;
               }
            }
            ~ span {
               vertical-align: middle;
            }
         }
      }

      /* 表格内容  结束  */

      tr {
         th:first-of-type,
         td:first-of-type{
            .cell {
               padding-left: 20px !important;
            }
         }
      }
   }
  .el-table__empty-block {
    position: relative;
    .el-table__empty-text {
      line-height: normal;
      min-height: unset !important;
    }
  }
   /* 翻页  开始 */
   .my-table-footer {
      text-align: right;
      background-color: #fff;
      border: 1px solid #E9EBEE;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
      padding: 20px - 9px 20px;
      .el-pagination__total {
         float: left;
         font-size: 13px;
         font-weight: 400;
         color: #222222;
      }
      .el-pagination__jump{
         margin-left: 5px;
         font-weight: 400;
         color: #222222;
      }
      .el-pagination__sizes {
         .el-input {
            margin-right: 0;
         }
      }
      .el-input__inner {
         font-size: 13px;
         color: #222222;
         font-weight: 400;
      }
      &.el-pagination {
         .el-pager {
            li {
               min-width: 28px;
               line-height: 26px;
               background-color: transparent;
               border-radius: 4px;
               border: 1px solid #DCDFE6;
               font-size: 13px;
               color: #222222;
               font-weight: 400;
               &.active {
                  background-color: transparent;
                  border-color: #507AFE;
                  color: #507AFE;
               }
            }
         }
         button {
            background-color: transparent;
            border-radius: 4px;
            border: 1px solid #DCDFE6;
         }
      }
   }
   /* 翻页  结束 */

  .handler-text{
    color: #507AFE;
    margin-right: 16px;
    cursor: pointer;
    position: relative;
    font-size: 13px;
    &:hover {
      color: rgba(#507AFE, 0.7);
    }
    &:active{
      color: #507AFE;
    }
    &::after {
      content: '';
      width: 1px;
      height: 13px;
      display: inline-block;
      background-color: #E9EBEE;
      position: absolute;
      top: 50%;
      right: -6px;
      transform: translateY(-50%);
    }
    &:last-of-type {
      margin-right: 0;
      &::after{
        display: none;
      }
    }
  }
}
</style>
