<template>
  <el-dialog
    v-model="isVisible"
    :width="activeWidth"
    custom-class="setting-dialog hi"
    :destroy-on-close="true"
    :top="top"
    :modal-append-to-body="modalAppendToBody"
    :append-to-body="appendToBody"
    :close-on-click-modal="false"
    @close="onCancelHandle"
  >
    <!--  标 题  -->

    <template #title>
      <div v-if="title" class="dialog-title__label">{{ title }}</div>
      <div class="dialog-title__label">
        <slot name="titleCustom" />
      </div>
    </template>

    <!--  弹出层的内容不需要滚动条  -->
    <div v-show="bodyNoScroll"><slot name="bodyNoScroll" /></div>

    <!--  弹出层的内容  -->
    <el-scrollbar
      v-show="!bodyNoScroll"
      ref="dialogScroll"
      class="dialog-scrollBar"
      :style="{ height: height }"
    >
      <slot name="body" />
    </el-scrollbar>

    <!--  底 部  -->
    <template v-if="hasFooter" #footer>
      <div class="dialog-footer">
        <template v-if="isCustomBtn.length > 0">
          <template v-for="(val, i) in isCustomBtn">
            <el-button
              v-if="!val.IsHide"
              :key="i"
              :size="val.Size"
              :laoding="val.Loading"
              :disabled="val.Disabled"
              :type="val.Type"
              @click="onBtnHandle(val)"
            >{{ val.Name }}</el-button>
          </template>
        </template>
        <template v-else>
          <el-button
            size="small"
            :type="cancelType || 'default'"
            :plain="cancelPlain"
            @click="onCancelHandle"
          >{{ cancelText }}</el-button>
          <el-button
            v-if="showConfirm"
            size="small"
            :plain="confirmPlain"
            :loading="isLoading"
            :disabled="isDisabled"
            :type="confirmType || 'primary'"
            @click="onConfirmHandle"
          >{{ confirmText }}</el-button>
          <slot name="hasOtherBtn" />
        </template>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import resize from './mixins/resize'

export default {
  name: 'DialogMy',
  components: {},
  mixins: [resize],
  props: {
    title: {
      // 弹出框的标题 （默认百分之50）
      type: String,
      default: ''
    },
    width: {
      // 弹出框的宽度 （默认百分之50）
      type: String,
      default: '50%'
    },
    height: {
      // 弹出框的高度 （默认65vh）
      type: String,
      default: '65vh'
    },
    visible: {
      // 是否显示 Dialog
      type: Boolean,
      default: false
    },
    showConfirm: {
      // 是否显示 确认按钮
      type: Boolean,
      default: true
    },
    cancelPlain: {
      type: Boolean,
      default: false
    },
    confirmPlain: {
      type: Boolean,
      default: false
    },
    modalAppendToBody: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    bodyNoScroll: {
      // 自定义内容
      type: Boolean,
      default: false
    },
    top: {
      // 距离顶部的距离
      type: String,
      default: '15vh'
    },
    cancelType: {
      // 取消按钮的样式
      type: String,
      default: ''
    },
    confirmType: {
      // 确认按钮的样式
      type: String,
      default: ''
    },
    hasFooter: {
      // 是否显示 有底部操作按钮
      type: Boolean,
      default: true
    },
    isCustomBtn: {
      // 是否自定义按钮
      type: Array,
      default() {
        return []
        // { Name: '确定', Loading: false,IsHide: false, Type: 'primary', Disabled: false, Size: 'medium', btnType: 'confirm',  Fun: 'functionName'}
      }
    },
    minWidth: {
      // 最小宽度
      type: Number,
      default: 800
    },
    cancelText: {
      // 取消文案
      type: String,
      default: '取 消'
    },
    confirmText: {
      // 确认文案
      type: String,
      default: '确 定'
    },
    isLoading: {
      // 确认是否在执行中
      type: Boolean,
      default: false
    },
    isDisabled: {
      // 是否禁用
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeWidth: this.width,
      isVisible: this.visible
    }
  },
  computed: {},
  watch: {
    visible: {
      handler(visible) {
        this.isVisible = visible
        this.activeWidth = this.width
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 按钮事件绑定
    onBtnHandle(val) {
      const { Fun } = val
      this.$parent[Fun] ? this.$parent[Fun].call(this, val) : ''
    },

    // 确认
    onConfirmHandle() {
      this.$emit('onConfirmHandle')
    },

    // 取消操作
    onCancelHandle() {
      this.$emit('onCancelHandle')
    }
  }
}
</script>

<style lang="scss">
.setting-dialog {
  max-width: 1100px;
  .el-dialog__body {
    padding: 20px;
    .dialog-scrollBar {
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
  }
  .el-dialog__footer {
    padding-top: 0;
  }
  .el-dialog__header {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #e9ebee;
  }
}
.el-dialog__headerbtn {
  top: 10px !important;
}
.el-dialog__headerbtn .el-dialog__close {
  font-size: 16px !important;
  &:hover {
    color: #507afe !important;
  }
}
</style>
