<template>
  <div class="key-container clearfix">
    <div class="title">系统三高一关键
      <el-tooltip content="通过云运维平台监控系统的运行情况，评估系统的三高（高稳定、高安全、高性能）以及关键应用的健康状态。" placement="right-start" effect="light">
        <span class="iconfont icon-tishi-wenhao" />
      </el-tooltip>
    </div>
    <div v-if="clickDate && !flagCatch" v-loading="chartLoading" element-loading-text="数据加载中...">
      <div class="clearfix">
        <div class="bar-container">
          <div class="chart-title">系统三高一关键</div>
          <div id="systemKeyPie" ref="refKeyPie" style="width: 100%;height: 210px;float:left;" />
        </div>
        <div class="bar-container bar-chart">
          <div class="chart-title">系统三高一关键状态分布</div>
          <div id="systemKeyBar" ref="refKeyBar" style="width: 100%;height: 210px;" />
        </div>
      </div>
      <div class="analysis">
        <div class="analysis-title">分析建议</div>
        <div id="contentKey" class="content content-anylysis" />
      </div>
    </div>
    <Empty v-if="flagCatch" description="暂无数据" />
  </div>

</template>
<script>
import { Empty } from '@/components'
import resize from '@/views/components/charts/mixins/resize'
import { pieOption, barOption, utilsDestroyedChart } from '@/views/components/charts/options'
import { markRaw } from 'vue'
import { utilsGoToApp } from '@/utils/gotoWeb.js'
export default {
  components: { Empty },
  mixins: [resize],
  props: {
    dataChartKey: {
      type: Object,
      default: function() {
        return {}
      }
    },
    clickDate: { // 点击跳转日期
      type: String,
      default: ''
    },
    flagCatch: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    chartLoading: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      systemKeyPie: null,
      systemKeyBar: null
    }
  },
  watch: {
    dataChartKey() {
      this.chartSet1()
      this.chartSet2()
    }
  },
  mounted() {
    if (this.clickDate && !flagCatch) {
      this.chartSet1()
      this.chartSet2()
    }
  },
  beforeUnmount() {
    utilsDestroyedChart(this, 'systemKeyPie')
    utilsDestroyedChart(this, 'systemKeyBar')
  },
  methods: {
    clearChart() {
      this.qualityChart = null
      this.systemApplyChart = null
    },
    chartSet1() {
      const vm = this
      const { aiSuggestion } = this.dataChartKey
      const { value, value2 } = this.dataChartKey.summary
      document.getElementById('contentKey').innerHTML = aiSuggestion
      const seriesData = [value, value2]
      const dataTitle = ['通过项数', '异常项数']
      // 1.饼图
      // this.systemKeyPie = markRaw(this.$echarts.init(document.getElementById('systemKeyPie')))
      this.systemKeyPie = markRaw(this.$echarts.init(this.$refs.refKeyPie))
      this.systemKeyPie.setOption(pieOption({ title: '系统三高一关键', seriesData, dataTitle }))
      this.systemKeyPie.off('click')
      this.systemKeyPie.on('click', () => utilsGoToApp(vm, '三高一关键', vm.clickDate))
    },
    chartSet2() {
      // 2.柱状图
      const vm = this
      const { details } = this.dataChartKey
      const dataTitleBar = ['通过项', '未通过项']
      const xData = []
      const yData = []
      const yData2 = []
      details.forEach(item => {
        const { title, value, value2 } = item
        xData.push(title)
        yData.push(value)
        yData2.push(value2)
      })
      // this.systemKeyBar = markRaw(this.$echarts.init(document.getElementById('systemKeyBar')))
      this.systemKeyBar = markRaw(this.$echarts.init(this.$refs.refKeyBar))
      this.systemKeyBar.setOption(barOption({ title: '系统三高一关键状态分布', dataTitle: dataTitleBar, xData, yData, yData2 }))
      this.systemKeyBar.off('click')
      this.systemKeyBar.on('click', () => utilsGoToApp(vm, '三高一关键', vm.clickDate))
    }
  }

}
</script>
