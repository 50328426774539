<template>
    <div class="downloading">文件准备中，请稍后...</div>
</template>

<script>
    export default {
        name: "downloading",
        components: {},
        props: {},
        data() {
            return {}
        },
        watch: {},
        computed: {},
        created() {
        },
        mounted() {
        },
        methods: {}
    }
</script>

<style scoped lang='scss'>

</style>
