<template>
  <div class="empty-comp">
    <!--    <img v-if="!slotImg" :class="{'admin': isAdmin}" :src="image || (isAdmin ? adminEmpty : empty)" alt="暂无数据">-->
    <span v-if="!slotImg" class="admin" alt="暂无数据" />
    <slot v-else name="slotImg" />
    <div v-if="!hasSlot && hasDescription" class="empty-comp_label">{{ description || '暂无数据' }}</div>
    <div v-else class="empty-comp_label">
      <slot name="description" />
    </div>
  </div>
</template>

<script>
// import empty from '../../assets/empty/admin_empty.png'
// import adminEmpty from '../../assets/empty/empty_bg.svg'
export default {
  name: 'Empty',
  components: {},
  props: {
    description: { // 暂无数据的描述
      type: String,
      default() {
        return ''
      }
    },
    hasDescription: { // 是否有描述
      type: Boolean,
      default: true
    },
    slotImg: { // 自定义图片
      type: Boolean,
      default: false
    },
    image: { // 暂无数据的图片
      type: String,
      default() {
        return null
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    hasSlot: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // empty, adminEmpty
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped lang='scss'>

  .empty-comp {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    .admin{
      width: 100px;
      height: 100px;
      display: inline-block;
      background: url("https://myom-release.oss-cn-hangzhou.aliyuncs.com/mysm/resource/workbench/empty_bg.svg") no-repeat center top / contain;
    }
    &_label{
      color: #909399;
      margin-top: 5px;
    }
  }
</style>
