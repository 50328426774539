export const BASE_URL = {
  LOGIN: 'identityserver', // 登陆
  WEB: 'mysm', // 前台
  ADMIN: 'mysmadmin', // 后台
  PATCH: 'productpatch', // 补丁管理
  DELIVER: 'document', // 运维交付物
  METRICS: 'api/metrics', // 日志
  WSOCKET: 'mysmws', // 运维交付物
  PLAN: 'maintenanceplan', // 维护计划
  TICKET: 'ticket', // 工单
  INSP: 'appinspection', // 应用巡检
  DATAINS: 'datainspection', // 数据巡检
  USERBEHAV: 'api/cancerwebapp',
  RISK: 'riskwarning', // 预警风控
  BACKUP: 'cloudbackup', // 云灾备工具
  SCREEN: 'screen' // 服务大屏
}
