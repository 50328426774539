<template>
  <div class="health-container clearfix">
    <div class="title">应用健康水平
      <el-tooltip content="通过对系统三高一关键，应用质量，故障率以及CXO的业务触达率综合评估，用于判断系统某日应用健康状态。" placement="right-start" effect="light">
        <span class="iconfont icon-tishi-wenhao" />
      </el-tooltip>
      <el-tooltip
        content="提升关键用户访问移动报表及大屏的活跃度，提升系统各模块应用的活跃度，有助于快速提升应用健康水平分值喔"
        placement="right-start"
        effect="light"
      >
        <span class="iconfont icon-jilu" />
      </el-tooltip>
    </div>
    <div
      v-if="!levelCatch&&clickDate"
      v-loading="levelLoading"
      class="level-left"
      element-loading-text="数据加载中..."
    >
      <div class="quality-bg" />
      <div id="quality" ref="refQuality" style="width: 260px;height: 260px;" />
      <div class="quality-auxi">
        <span class="avg-value">行业均值<span>{{ initRate(dataAppHealth.industryAvgValue,0,1,'分') }}</span></span>
        <span class="avg-user">打败<span>{{ utilsNumStr(dataAppHealth.overPercent,'%',true,2) }}</span>行业用户</span> </div>
    </div>

    <div
      v-if="levelCatch "
      class="level-left"
    >
      <Empty
        description="暂无数据"
      />
    </div>
    <div v-if="!applyCatch && clickDate" v-loading="applyLoading" class="level-right" element-loading-text="数据加载中...">
      <div class="title">应用质量</div>
      <div id="systemApply" ref="refApply" style="width: 100%;height:216px;" class="apply-system" />
      <div class="legend">
        <div><span style="background:#69b4f8" />健康水平</div>
        <div><span style="background:#91e9c7" />行业均值</div>
      </div>
    </div>
    <div
      v-if="applyCatch "
      class="level-right"
    >
      <Empty
        description="暂无数据"
      />
    </div>
  </div>
</template>

<script>
import { Empty } from '@/components'
import { utilsNumStr, utilsNum, utilsAll, initRate } from '@/utils/value.js'
import resize from '@/views/components/charts/mixins/resize'
import { qualityChartOption, systemChartOption, utilsDestroyedChart } from '@/views/components/charts/options'
import { markRaw } from 'vue'
// import { utilsGoToApp } from '@/utils/gotoWeb.js'
export default {
  components: { Empty },
  mixins: [resize],
  props: {
    dataAppHealth: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dataDimensionScore: {
      type: Object,
      default: () => {
        return {}
      }
    },
    clickDate: { // 点击跳转日期
      type: String,
      default: ''
    },
    levelCatch: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    applyCatch: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    applyLoading: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    levelLoading: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      qualityChart: null,
      systemApplyChart: null,
      utilsNumStr,
      utilsNum,
      utilsAll,
      initRate
    }
  },
  watch: {
    dataAppHealth() {
      this.chartSet1()
    },
    dataDimensionScore() {
      this.chartSet2()
    }
  },
  mounted() {
    if (this.clickDate) {
      if (!this.levelCatch) {
        this.chartSet1()
      }
      if (!this.applyCatch) {
        this.chartSet2()
      }
    }
  },
  beforeUnmount() {
    utilsDestroyedChart(this, 'qualityChart')
    utilsDestroyedChart(this, 'systemApplyChart')
  },
  methods: {
    clearChart() {
      this.qualityChart = null
      this.systemApplyChart = null
    },
    chartSet1() {
      this.clearChart()
      const chart = this.$echarts
      // 1.行业平均值
      const systemScore = initRate(this.dataAppHealth.systemScore, 0, 1, '')
      // this.qualityChart = markRaw(chart.init(document.getElementById('quality')))
      this.qualityChart = markRaw(chart.init(this.$refs.refQuality))
      this.qualityChart.setOption(qualityChartOption({ systemScore }))
      // this.qualityChart.on('click', () => utilsGoToApp(this, '应用质量'))
    },
    chartSet2() {
      const chart = this.$echarts
      // 2.系统应用质量
      // if (!this.dataDimensionScore.tenantScore) return
      // const { tenantScore, avgScore } = this.dataDimensionScore
      //
      // if (!tenantScore.healthResult) return

      // TODO
      // console.log('this.dataDimensionScore', this.dataDimensionScore.tenantScore.healthResult)
      const tenantScore = this.dataDimensionScore.tenantScore
      const avgScore = this.dataDimensionScore.avgScore
      const { healthResult, mauCxoRate, faultRate, scoreAll } = tenantScore
      const healthResultAvg = avgScore.healthResult
      const mauCxoRateAvg = avgScore.mauCxoRate
      const faultRateAvg = avgScore.faultRate
      const scoreAllAvg = avgScore.scoreAll
      const valueSystem = [utilsNum(healthResult, false, 2), utilsNum(mauCxoRate, true, 2), utilsNum(scoreAll, false, 2), utilsNum(faultRate, true, 2)]
      const valueAvg = [utilsNum(healthResultAvg, false, 2), utilsNum(mauCxoRateAvg, true, 2), utilsNum(scoreAllAvg, false, 2), utilsNum(faultRateAvg, true, 2)]

      // this.systemApplyChart = markRaw(chart.init(document.getElementById('systemApply')))
      this.systemApplyChart = markRaw(chart.init(this.$refs.refApply))
      this.systemApplyChart.setOption(systemChartOption({ valueSystem, valueAvg }))
      // this.systemApplyChart.on('click', () => utilsGoToApp(this, '应用质量'))
    }
  }
}
</script>
<style scoped lang="scss">
.icon-tishi-wenhao,
.icon-jilu {
    display: inline-block;
    margin-left: 4px;
    position: relative;
    font-size: 14px;
    top: 1px;
    cursor: pointer;
    font-weight: normal;
    }
    .icon-jilu{
      float: right;
    }
.level-left,
.level-right{
    position: relative;
    width: 50%;
    float: left;
    height: 226px;
    .title{
      text-align:left;
      color: #333;
      font-size: 14px;
      position: relative;
      font-weight: normal !important;
      // top: 48px;
    }
    .quality-bg{
      position: absolute;
      top: 56px;
      left: 45px;
      right: 0;
      bottom: 74px;
      width: 184px;
      border-radius: 500px 500px 0 0;
      box-shadow: -3px 4px 36px 0px rgb(0 0 0 / 7%);
    }
    #quality{
       position: relative;
       top: 16px;
    }
    #systemApply{
      position: relative;
      top: -12px;
    }
    .legend{
      position: absolute;
      right:20px;
      font-size: 12px;
      color:rgb(189, 189, 189);
      bottom: 1px;
      div{
        margin-bottom: 2px;
      }
      span{
        display: inline-block;
        margin-right: 10px;
        position:relative;
        top: -4px;
        width: 16px;
        height: 2px;
      }
    }
    .quality-auxi{
      position:absolute;
      bottom: 26px;
      color: rgba(0,0,0,0.38);
      width: 260px;
      text-align: center;
    }
    .avg-value,
    .avg-user{
      span{
        display: inline-block;
        margin: 0 4px;
      }
    }
    .avg-user{
      margin-left: 10px;
    }
}
.level-left{
    margin-left: 60px;
    width: calc(50% - 60px);
}
.level-right{
  margin-left: -20px;
}
@media(min-width: 1441px) and (max-width: 1600px){
  .level-left{
    margin-left: 30px;
    width: calc(50% - 30px);
}
.level-right{
  margin-left: -20px;
}
}
@media (max-width: 1440px){
  .level-left{
    margin-left:10px;
    width: calc(50% - 10px);
}
.level-right{
  margin-left: -10px;
}
}
</style>
