import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes'
import store from '../store/index'
import NProgress from 'nprogress'
import { ElMessage, ElMessageBox } from 'element-plus'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

function setStatistic(module, view) {
  var hm = document.createElement('script')
  hm.src = `${window.location.protocol}//${window.location.host}/v20/api/cancerwebapp/frontend/wlog.js?m1=web&m2=${module}&m3=${view}`
  hm.id = 'statisticCustomer360SystemId'
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(hm, s)
  // let src = `${window.location.protocol}//${window.location.host}/v20/api/${isAdmin}/frontend/${plat}log/${module}/${view}.js`;
  // console.log('------src:', src)
}

router.beforeEach(async(to, from, next) => {
  document.title = '客户360'
  // 系统统计
  if (to.meta.module && to.meta.view) {
    const { module, view } = to.meta
    setStatistic(module, view)
  }
  if (to.name === '360d' && to.query.date && to.query.date.length >= 10) {
    await store.dispatch('user/setUrlParams', {
      date: to.query.date,
      path: to.path
    })
    delete to.query.date
    next({ ...to, replace: true })
  } else {
    next()
  }
})

import Cookies from 'js-cookie'
function getIsConfirmToShowStop(){
  ElMessageBox.confirm("\n        <div class=\"stop-title\">\u505C\u670D\u63D0\u9192</div>\n        <div class=\"stop-tips\">\u57FA\u4E8E\u53CC\u65B9\u670D\u52A1\u5408\u4F5C\u7EA6\u5B9A\uFF0C\u7531\u4E8E\u8D35\u516C\u53F8\u672A\u80FD\u53CA\u65F6\u5B8C\u6210\u5E74\u5EA6\u8FD0\u7EF4\u670D\u52A1\u5408\u540C\u7684\u7B7E\u8BA2\uFF0C\u6211\u53F8\u5DF2\u6682\u505C\u5BF9\u8D35\u516C\u53F8\u4F7F\u7528\u7684\u660E\u6E90ERP\u8F6F\u4EF6\u63D0\u4F9B\u4EFB\u4F55\u5F62\u5F0F\u7684\u6280\u672F\u652F\u6301\u670D\u52A1\uFF0C\u5982\u679C\u56E0\u6B64\u7ED9\u8D35\u516C\u53F8\u9020\u6210\u7684\u4E0D\u4FBF\uFF0C\u656C\u8BF7\u8C05\u89E3\uFF01\u53EF\u901A\u8FC7\u4EE5\u4E0B\u4E24\u79CD\u65B9\u5F0F\uFF0C\u6D3D\u8C08\u540E\u7EED\u670D\u52A1\u7B7E\u8BA2\u4E8B\u5B9C\uFF1A</div>\n        <div class=\"stop-tips\">1\u3001\u8054\u7CFB\u5F53\u5730\u660E\u6E90\u5206\u516C\u53F8\u4E0E\u5BA2\u6237\u6210\u529F\u7ECF\u7406\u6D3D\u8C08</div>\n        <div class=\"stop-tips\">2\u3001\u81F4\u7535\u660E\u6E90\u5B98\u65B9\u7535\u8BDD\uFF1A<span class=\"stop-phone\">400-602-7009</span> \u54A8\u8BE2</div>\n      ", '', {
  type: 'info',
  customClass: 'stop-service-content-new',
  iconClass: 'el-icon-warning-outline',
  showCancelButton: false,
  confirmButtonText: '我知道了',
  dangerouslyUseHTMLString: true,
  closeOnClickModal: false
});
}

var num = 0;
router.afterEach(() => {
  NProgress.done()
  const IsTf = Cookies.get('IsTf');  
  const element = document.querySelector('.stop-service-content-new');
  if(element)
  {
    num++
  }
  if(IsTf === "True" && (!element || num >0))
  {
    getIsConfirmToShowStop()
  }
})




export default router
