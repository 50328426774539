export function navRoutes(Layout) {
  return [
    {
      path: '/',
      component: Layout,
      redirect: '/360d',
      name: '360d',
      meta: { title: '360概览', icon: 'icon-zuhucanshu', noFun: true },
      children: [
        {
          path: '/360d',
          name: '360d',
          component: () => import('@/views/general/index'),
          meta: {
            title: '360概览',
            roles: ['admin'],
            noFun: true,
            module: 'general',
            view: 'index'
          }
        }
      ]
    },
    {
      path: '/health',
      component: Layout,
      redirect: '/list',
      name: 'list',
      meta: { title: '异常列表', icon: 'icon-biaodandingzhimoban', noFun: true },
      children: [
        {
          path: '/list',
          name: 'list',
          component: () => import('@/views/list/index'),
          meta: {
            title: '异常列表',
            roles: ['admin'],
            noFun: true,
            module: 'abnormity',
            view: 'index'
          }
        }
      ]
    }
  ]
}
