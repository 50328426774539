const TokenKey = 'LoginInfo'
import Cookies from 'js-cookie'

export function getToken(name) {
  return localStorage.getItem(name || TokenKey)
}

export function setToken(token, name) {
  return localStorage.setItem(name || TokenKey, token)
}

export function removeToken(name) {
  return localStorage.removeItem(name || TokenKey)
}

// 清除所有cookie函数
function clearAllCookie() {
  // const keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  const keys = document.cookie.match(/[^ =;]+(?=)/g)
  if (keys) {
    for (let i = keys.length; i--;) { document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString() }
  }
}
export function removeCookie(name) {
  if (name) {
    return Cookies.remove(name)
  } else {
    return clearAllCookie()
  }
}
