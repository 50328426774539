import request from './index'
import { BASE_URL } from '@/utils/constants'

// 消息读取（点击显示已读状态）
export function readMessage(appCode) {
  return request({
    url: `${BASE_URL.WEB}/frontend/message/set-msg-read.svc?appCode=${appCode}`,
    method: 'PUT'
  })
}

// 是否有未读消息
export function getHasNotRead() {
  return request({
    url: `${BASE_URL.WEB}/frontend/message/get-all-unread.svc`,
    method: 'GET'
  })
}
