
const state = {
  userInfo: {
    tenantId: '',
    tenantName: '',
    userId: '',
    userName: '',
    userRole: '',
    userIcon: '',
    domainUserGuid: '',
    userCode: ''
  }
}

const mutations = {
  // 用户信息
  SET_USER_INFO: (status, userInfo) => {
    status.userInfo = userInfo
    // if (userInfo === {}) status.userInfoStr = ''
    // else { status.userInfoStr = encodeURIComponent(JSON.stringify(userInfo)) }
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

