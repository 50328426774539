<template>
  <div class="person-info-page">
    <section class="service-items clearfix">
      <section class="user-info clearfix">
        <el-avatar
          v-if="$store.state.userInfo.userInfo.userIcon"
          class="avatar-img"
          :alt="
            Object.keys($store.state.userInfo.userInfo).length
              ? $store.state.userInfo.userInfo.userName
                ? $store.state.userInfo.userInfo.userName
                : ''
              : ''
          "
          :src="
            Object.keys($store.state.userInfo.userInfo).length &&
              $store.state.userInfo.userInfo.userIcon
              ? $store.state.userInfo.userInfo.userIcon
              : userIcon
          "
          :size="72"
        />
        <span v-else class="avatar-img-text">{{
          $store.state.userInfo.userInfo
            ? subStringFun($store.state.userInfo.userInfo.userName || 'MY')
            : 'MY'
        }}</span>
        <div class="user-info-details">
          <div
            :class="[
              'welcome-info',
              $store.state.userInfo.userInfo.userName
                ? $store.state.userInfo.userInfo.userName
                : ' no-data',
            ]"
          >
            {{
              $store.state.userInfo.userInfo.userName
                ? getTimeState + '，' + $store.state.userInfo.userInfo.userName
                : '----'
            }}
          </div>
          <div
            :class="[
              'user-name',
              $store.state.userInfo.userInfo.tenantName
                ? $store.state.userInfo.userInfo.tenantName
                : ' no-data',
            ]"
          >
            {{ $store.state.userInfo.userInfo.tenantName || '----' }}
          </div>
        </div>
      </section>
      <section class="panel-content clearfix">
        <PanelItems :panel-data="panelData" :text-align="'right'" />
      </section>
    </section>
  </div>
</template>
<script>
import PanelItems from './panelItems'
import userIcon from '@/assets/login/touxiang.png'
import { subStringFun } from '@/utils/index.js'
import {
  apiGetBannerInfo // 获取系统信息
} from '@/api/app.js'
import { ref } from 'vue'

export default {
  name: 'ProductPanel',
  components: { PanelItems },
  setup() {
    const panelData = ref({})
    const getTimeState = () => {
      // 获取当前时间
      const timeNow = new Date()
      // 获取当前小时
      const hours = timeNow.getHours()
      // 设置默认文字
      let state = ''
      // 判断当前时间段
      if (hours >= 0 && hours <= 3) {
        state = '凌晨好'
      } else if (hours > 3 && hours <= 11) {
        state = '早上好'
      } else if (hours > 11 && hours <= 13) {
        state = '中午好'
      } else if (hours > 13 && hours <= 17) {
        state = '下午好'
      } else if (hours > 17 && hours <= 21) {
        state = '晚上好'
      } else if (hours > 21 && hours <= 24) {
        state = '夜深了'
      }
      return `${state}`
    }

    // 获取系统信息
    const getBannerInfo = () => {
      apiGetBannerInfo()
        .then((res) => {
          panelData.value = res
        })
        .catch(() => {})
    }
    return {
      getTimeState: getTimeState(),
      subStringFun,
      userIcon,
      panelData,
      getBannerInfo
    }
  },

  created() {
    this.getBannerInfo()
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.scss';
.person-info-page {
  background: #fff;
}
section.service-items {
  padding: $padding + 12px $padding $padding + 12px $padding;
  position: relative;
  .user-info {
    // display: inline-block;
    float: left;
    height: 100%;
    width: 350px;
    position: relative;
    // &::before {
    //     content: '';
    //     display: inline-block;
    //     width: 170px;
    //     height: 136px;
    //     // background: url('~@/assets/index/deliver_bg.png') no-repeat 0 0;
    //     position: absolute;
    //     right: 0;
    //     top: -30px;
    //     z-index: 1;
    // }
  }
}

.panel-content {
  float: left;
  width: calc(100% - 356px);
  height: 100%;
  min-height: auto;
  // position: absolute;
  // right: $padding;
  // top: 50%;
  // @include transformMixin(translateY(-50%));
  .service-panel-items {
    padding-top: $padding * 2;
  }
}
.avatar-img {
  background-color: rgba(209, 229, 255, 1);
  font-size: 48px;
  color: rgba(18, 96, 206, 1);
  float: left;
  margin-right: $margin + 10px;
}
.avatar-img-text {
  background-color: #d1e5ff;
  font-size: 48px;
  color: #1260ce;
  float: left;
  margin-right: 30px;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  font-size: 22px;
  line-height: 72px;
  text-align: center;
}
.user-info-details {
  float: left;
  width: 246px;
}

.welcome-info {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  padding: 2px 0 0 0;
  min-height: 46px;
  &.no-data {
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
  }
}
.user-name {
  margin-top: 7px;
  font-weight: 400;
  font-size: 14px;
  color: $fontColorSmall;
  &.no-data {
    color: rgba(0, 0, 0, 0.25);
  }
}
@media (max-width: 1440px) {
  section.service-items .user-info,
  .panel-content {
    width: 100%;
    float: none;
  }
  .panel-content {
    margin-top: 20px;
  }
}
</style>
