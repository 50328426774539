<template>
  <div class="menu-wrap">
    <div v-for="(menu,key) in menuList" :key="'menu_'+key" :class="active===menu.id?'active':''" @click="handleClickMenu($event, menu)">
      {{ menu.name }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    menuList: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      active: 0
    }
  },
  methods: {
    handleClickMenu($event, menu) {
      this.active = menu.id
      // if (this.$route.path.indexOf('/health/resolve') > -1) {
      //   // 查看并处理：治理中心（昨日异常项，历史未解决异常项，历史已解决异常项）
      //   this.$store.dispatch('appLog/appMLog', { params: { m1: '健康中心', m2: menu.name === '昨日异常项' ? '治理中心' : menu.name }})
      // }
      this.$emit('clickMenu', menu)
    }
  }
}
</script>
